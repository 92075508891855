
import configureStore from '../../store';
import {useHistory} from 'react-router-dom';

const store = configureStore();

export const customErrMessages = {
  500: {
    message: 'Something went wrong! Please try again.',
    severity: 'error',
  },
  501: {
    message: 'We couldn\'t process your request! Please try again.',
    severity: 'error',
  },
  502: {
    message:
      'The server encountered a temporary error' +
      ', and could not complete your request.',
    severity: 'error',
  },
  504: {
    message: 'The server could not respond! Please try again.',
    severity: 'error',
  },
  400: {
    message: 'We ran into a problem! Please try again.',
    severity: 'error',
  },
  401: {
    message: 'Unauthorized access',
    severity: 'warning',
  },
  403: {
    message: 'You don\'t have authorization to perform this action.',
    severity: 'error',
  },
  404: {
    message: 'Page not found!',
    severity: 'error',
  },
  405: {
    message: 'The requested method is not allowed.',
    severity: 'error',
  },
  406: {
    message: 'We ran into a problem! Please try again.',
    severity: 'error',
  },
  409: {
    // no message since we have to show the error-message sent from server
    severity: 'error'
  },
  412: {
    message: 'We weren\'t able to upload the file! Please try again.',
    severity: 'error',
  },
  415: {
    message:
      'Please try a different type of media file. We do not support this file type.',
    severity: 'error',
  },
};

const redirection = (url, history) => { // useHistory hook needs to be passed from the main jsx as second prop
  if (history) history.push(url); // removed window.loc.href to avoid security concerns
};

const GetMessage = (error) => {
  const Unauthorized = () =>{
    if (status === 401) {
      // return redirection(`/auth?redirect=${window.location.pathname}`);
      // return <Redirect 
      //   to={{ pathname: '/auth', search: 
      // `redirect=${window.location.pathname}` }}/>; 
    }
  };
  const status = error.response ? error.response.status : null;
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return status ? customErrMessages[status] && 
    customErrMessages[status].message : error && error&&error.message;
  }
};

const GetSeverity = (error) => {
  const status = error.response ? error.response.status : null;
  return status ? customErrMessages[status]?.severity : 'error';
};

const SuccessAlert = (success, head) => {
  store.dispatch({
    type: 'ALERT_CONFIG',
    payload: {message: success, head: head, severity: 'success'},
  });
};

  
const ErrorAlert = (error) => {
  store.dispatch({
    type: 'ALERT_CONFIG',
    payload: {message: GetMessage(error), severity: GetSeverity(error)},
  });
};
  
const ErrorAlertAndNavigate = (error, url, history) => {
  setTimeout(() => {
    store.dispatch({
      type: 'ALERT_CONFIG',
      payload: {message: GetMessage(error), severity: GetSeverity(error)},
    });
  }, 500);
  return redirection(url, history);
};
  
const CustomErrorAlert = (msg, severity) => {
  store.dispatch({
    type: 'ALERT_CONFIG',
    payload: {message: msg, severity: severity?severity:'error'}
  });
};
  
const CustomErrorAlertAndNavigate = (msg, url, severity, history) => {
  setTimeout(() => {
    store.dispatch({
      type: 'ALERT_CONFIG',
      payload: {message: msg, severity: severity?severity:'error'},
    });
  }, 600);
  return redirection(url, history);
};

const CustomWarningAlert = (msg) => {
  setTimeout(() => {
    store.dispatch({
      type: 'ALERT_CONFIG',
      payload: {message: msg, severity: 'warning'},
    });
  }, 600);
};
  
const CustomWarningAlertAndNavigate = (msg, url, history) => {
  setTimeout(() => {
    store.dispatch({
      type: 'ALERT_CONFIG',
      payload: {message: msg, severity: 'warning'},
    });
  }, 600);
  return redirection(url, history);
};


const SuccessAlertAndNavigate = (success, url, history) => {
  store.dispatch({
    type: 'ALERT_CONFIG',
    payload: {message: success, severity: 'success'},
  });
  return redirection(url, history);
};


const useAlert = {
  ErrorAlert,
  SuccessAlert,
  GetMessage,
  GetSeverity,
  ErrorAlertAndNavigate,
  CustomErrorAlert,
  CustomErrorAlertAndNavigate,
  CustomWarningAlert,
  CustomWarningAlertAndNavigate,
  SuccessAlertAndNavigate
};

export default useAlert;
