import styled from 'styled-components';
import * as theme from './../../config/theme';
import footerBanner from './../../assets/images/footer_bg.svg';
import {cssVar} from '../../utils/utils';

const primaryFont = theme.default.fonts.primary;
const secondaryFont = theme.default.fonts.secondary;

export const StyledContactUsFooterContainer = styled.div`
  flex-grow: 0;
  padding: 6.25rem 0 3rem 0;
  object-fit: contain;
  background: url(${footerBanner}) no-repeat;
  background-size: cover;
`;

export const StyledNewsFooterContainer = styled.div`
  flex-grow: 0;
  margin-top: 6.25rem;
  object-fit: contain;
  background-color: #21232b;
`;

export const StyledLinksFooterContainer = styled.div`
  padding: 3rem 0 3rem 0;
  object-fit: contain;
  background-color: #21232b;
  z-index: 1;
`;

export const StyledWhiteBg = styled.div`
  padding: 2.8125rem 1rem;
  border-radius: 0.3125rem;
  background-color: #ffffff;
`;

export const StyledDateContainer = styled.div`
  height: 2.1875rem;
  padding: 0.375rem 1.1875rem 0.3125rem;
  border: solid 0 #ffffff;
  background: ${()=> cssVar('--tmo-magenta')};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;
  font-weight: bold;
  font-size: 1rem;
`;

export const StyledNewsContainer = styled.div`
  padding: 4.37rem 6.25rem 0;
  max-width: 67.5rem;
`;
export const StyledNewsHeader = styled.div`
  font-family: ${secondaryFont};
  color: white;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3.125rem;
`;
export const StyledNewsTitle = styled.div`
  font-family: ${secondaryFont};
  font-size: 1.75rem;
  line-height: 1.14;
  text-align: left;
  color: #000000;
  margin-bottom: 0.625rem;
  max-width: 80%;
`;

export const StyledNewsDesc = styled.div`
  opacity: 0.6;
  font-family: ${primaryFont};
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: #000000;
  margin-bottom: 1.375rem;
  max-width:70%;
`;

export const StyledReadMore = styled.div`
  display: flex;
  align-items: center;
  font-family: ${primaryFont};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.71;
  text-align: center;
  color: ${()=> cssVar('--tmo-magenta')};
  opacity: 0.9;
   &:hover {
     color: #ba0060;
     text-decoration: underline;
   }
`;

export const StyledTextAreaContainer = styled.div`
  width: 49%;
`;

export const StyledContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 1.25rem 6.25rem;
`;
export const StyledLinksFooter = styled.div`
  flex-grow: 0;
  margin: 1rem 0 0 0;
  background-color: #21232b;
`;

export const StyledContactUsHeader = styled.div`
  flex-grow: 0;
  margin: 0 0.039063rem 0.9375rem 0.625rem;
  font-family: ${secondaryFont};
  font-size: 2.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

export const StyledContactUsSubHeader = styled.div`
  width: 37rem;
  flex-grow: 0;
  margin: 0 0.625rem 2.5rem 0.625rem;
  opacity: 0.6;
  font-family: ${primaryFont};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
`;
export const StyledTextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-right: 1.25rem;
  width: 49%;
  & .MuiInputBase-input {
    color: #fff !important;
}
`;
export const StyledCharLeftContainer = styled.div`
margin-left: 0.8rem;
position: absolute;
bottom: -1.3rem;
  font-family: ${primaryFont};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.01125rem;
  color: rgba(255, 255, 255, 0.5);
`;

export const StyledCopyrightContainer = styled.div`
  font-family: ${primaryFont};
  font-size: 0.875rem;
  line-height: 1.64;
  text-align: left;
  color: #ffffff;
  margin-top: 1.25rem;
`;

export const StyledLinks = styled.div`
  margin: 0 0 0 1.75rem;
  font-family: ${primaryFont};
  font-size: 0.875rem;
  line-height: 1.64;
  text-align: left;
  color: #ffffff;
  cursor: pointer;

  &:hover{
    color: ${()=> cssVar('--tmo-magenta')};
  }
`;

export const StyledTextArea = styled.textarea`
  flex-grow: 0;
  margin: 0rem 0rem 0rem 1.5rem;
  padding: 0.625rem;
  background-color: #21232b;
  color: white;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  text-transform: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  column-count: initial !important;
  font: 400 0.83333125rem ${primaryFont};
  border-width: 0.0625rem;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
`;

export const StyledButtonContainer = styled.div`
  margin-top: -1.375rem;
`;

export const CaptchaContainer = styled.div`
 margin-top: 2rem;
 position: relative;
`;
