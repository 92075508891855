import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Text from '../../shared/text';
import Close from '../../../assets/images/icon close.png';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import {makeStyles} from '@material-ui/core/styles';
import themes from '../../../config/theme';
import Button from '../../shared/button';
import {cssVar} from '../../../utils/utils';

const Heading = styled(Text)`
display: block;
text-align: center;
width: 32rem;
`;

const StyledName = styled.span`
font-family: ${themes.fonts.primary};
font-weight: bold;
`;

const StyledDescription = styled(Text)`
display: block;
text-align: center;
font-size: ${themes.texts.font18px};
font-weight: ${themes.weight.medium};
line-height: 1.33;
letter-spacing: normal;
color: rgba(0, 0, 0, 0.6);
`;

const DialogContent = styled.div`
    padding: 2rem 3rem 1.5rem;
    max-width: 40.625rem;
    .wrapped {
      word-break: break-word;
    white-space: -webkit-nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    }
`;

const Closeicon = styled.div`
  display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: -2.8rem;
    position: absolute;
    right: 4rem;
    top: 1rem;
  `;

const CloseIconContainer = styled.div`
  display: flex;
      padding: 0.7rem;
  
  :hover {
        border-radius: 50%;
        background: #F5F5F5;
      }
  
  `;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
     margin: 3rem 0 1.5rem 0;
 `;

const Loadwrap = styled.div`
 display: flex;
 justify-content: center;
 height: 1rem;
 margin-top: -1.2rem;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      // minHeight: '35vh',
      width: '43rem',
      maxHeight: 'none',
      borderRadius: '0'
    },
    '& .MuiInputBase-input': {
      'fontFamily': `${themes.fonts.primary}`,
      'fontSize': `${themes.texts.normalText}`,
      'fontWeight': '500',
      'color': '#000',
      'padding': '0',
      '&:disabled': {
        // cursor: 'not-allowed',
      },
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      'font-size': '0.875rem',
      'marginBottom': '0.625rem',
    },
    '& .WAMuiChipInput-inputRoot-43.WAMuiChipInput-outlined-46': {
      paddingTop: '0 !important',
    },
    '& .MuiChip-label': {
      fontFamily: `${themes.fonts.primary}`,
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    '& .WAMuiChipInput-chip-57': {
      borderRadius: '0',
      background: '#f2f4f8',
      margin: '0.5rem 0.5rem 0.5rem 0',
    },
    '& .MuiOutlinedInput-root': {
      'borderRadius': '0',
    },
    '& .MuiInputLabel-outlined': {
      color: '#000 !important',
      opacity: '0.3'
    },
  }
}));

const impersonationDialogs = {
  startImpersonation: {
    heading({itemToImpersonate}) {
      return (
        <>
          You will be switching and impersonating
          <StyledName>
            {' '+itemToImpersonate?.name}
          </StyledName>
        </>);
    },
    description() {
      return (
        <>
          This action will take you to the selected user&#39;s impersonation view mode.
          Please confirm leaving this page and view portal as the user.
          You will be able to exit the view anytime and return to your dashboard.
        </>);
    }
  },
  stopImpersonation: {
    heading() {
      return (
        <>
          Confirm exiting this view
        </>);
    },
    description() {
      return (
        <>
          You will be redirected to your user Access Control once you exit this view.
        </>);
    }
  },
  stopImpersonationAccess: {
    heading({itemToImpersonate}) {
      return (
        <>
          You will be stopping {itemToImpersonate?.name} impersonation by 
          {' '+ itemToImpersonate?.impersonators?.reduce((combinedName, currentValue) => 
            combinedName + 
                    ((combinedName?.length && currentValue.name) ?
                      ', '+currentValue.name :
                      (currentValue.name) ? 
                        currentValue?.name : 
                        ''),
          '')}.
        </>);
    },
    description() {
      return (
        <>
          This action will stop the users impersonation. 
          The user will be notified and exited from the impersonation view. 
          Please confirm stopping the users ongoing action.
        </>);
    }
  }
};

const ImpersonationDialog = (props) => {
  const {openImpersonateModal, onClose, handleImpersonate, 
    impersonationStatus='startImpersonation', modalLoader=false} = props;
  const classes = useStyles();
   
  const DialogHeading = impersonationDialogs?.[impersonationStatus]?.heading;
  const DialogDescription = impersonationDialogs?.[impersonationStatus]?.description;

  return (
    <Dialog className={classes.root} style={{zIndex: '10001'}}
      onClose={onClose} open={openImpersonateModal} maxWidth={false}>
      <DialogContent>
        <Closeicon onClick={onClose}>
          <CloseIconContainer>
            <img src={Close} alt='NA'/>
          </CloseIconContainer>
        </Closeicon>
        <Heading color='fifth' fontSize='subHeading' family='secondary' margin='0.5rem 1rem'>
          <DialogHeading {...props}/>
        </Heading>
        <StyledDescription color='fifth' fontSize='font18px' margin='0.3rem'>
          <DialogDescription {...props} />
        </StyledDescription>
        <ButtonContainer>
          <Button onClick={onClose} hoverBgColor='rgba(226, 0, 116, 0.05)'
            width='12.25rem'
            margin='0 1rem 0 0' border={'0.0625rem solid ' + cssVar('--tmo-magenta')} 
            bgColor='#fff' color={cssVar('--tmo-magenta')}
            padding='0.8rem 3.25rem'
            cursor={(modalLoader)? 'not-allowed' : 'pointer'}
            opacity={(modalLoader)? '0.3' : '1'}
            disabled={modalLoader}>
            No, Cancel
          </Button>
          <Button width='13rem' hoverBgColor='#ba0060' padding='0.8rem 3.25rem'
            onClick={handleImpersonate}
            cursor={(modalLoader)? 'not-allowed' : 'pointer'}
            opacity={(modalLoader)? '0.3' : '1'}
            disabled={modalLoader}>
            {(modalLoader)?
              <Loadwrap>
                <Loader type="ThreeDots" color="#fff" height={40} width={40} />
              </Loadwrap>:
              'Yes, Confirm'}
          </Button>
        </ButtonContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ImpersonationDialog;
