import React, {useEffect, useState} from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Grow from '@material-ui/core/Grow';

import Paper from '@material-ui/core/Paper';

import Popper from '@material-ui/core/Popper';

import MenuItem from '@material-ui/core/MenuItem';

import MenuList from '@material-ui/core/MenuList';

import {makeStyles} from '@material-ui/core/styles';

import action from '../../../assets/images/action.svg';

import styled from 'styled-components';
import {StyledAPIButton} from '../style';

import {StyledButton} from '../../../style';
import {useHistory} from 'react-router-dom';
import Alerts from '../../utils/userAlert';
import {encodeURL} from '../../utils/sanitizeUrl';
import userConfig from '../../../reducer/userConfig';
import {openInNewTab} from '../../shared/utils/openInNewTab';
import {cssVar} from '../../../utils/utils';

const {SuccessAlert, ErrorAlert} = Alerts;

const useStyles = makeStyles((theme) => ({

  root: {

    display: 'flex',
    margin: '0 1.5rem 0 1.565rem'
  },

  paper: {

    marginRight: theme.spacing(2),

  },
  active: {
    color: `${cssVar('--tmo-magenta')} !important`
  },

}));

const Action = styled.img`



 

`;

const MenuOpen = styled.div`
z-index: 1;
.buttonStyle {
  :focus-visible {
    outline: none;
  }
}
& .MuiMenuItem-root{
  font-family: TeleGroteskNext;
  padding-right: 2rem;
  font-size:0.875rem;
  color: #000;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
   :hover {
    background-color: rgba(209, 222, 240 ,0.3);
   }
}

& .MuiPaper-rounded{
    border-radius: 0;
}
`;

const Roles = styled.div`
display: flex;
max-width: 10rem;
flex-wrap: wrap;
`;

const StyledTag = styled.div`
// height: 1rem;
margin: 0 0.2rem 0.2rem 0;
padding: 0.1rem 0.5rem;
border: solid 0 #ffffff;
color: #ffffff;
border-radius: 0.125rem;
background-color: ${()=> cssVar('--tmo-magenta')};
font-weight: bold;
font-size: 0.6875rem;
// position: absolute;
`;

const ApiRef = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history= useHistory();
  const {location: {pathname}} = history;
  const [selectedEnv, setSelected] = useState('');
  const list = [];
  if (props.userConfig&&props.userConfig.role&&!props.userConfig.role.includes('trusted_partner')) {
    list.push({
      name: 'DEVELOPMENT',
      value: 'dev'
    }, {
      name: 'PRODUCTION',
      value: 'prod'
    }, {
      name: 'PARTNER',
      value: 'trusted-partner'
    });
    if (localStorage.getItem('id')&&JSON.parse(localStorage.getItem('id'))&&JSON.parse(localStorage.getItem('id')).my_partners) {
      list.push({
        name: 'MY PARTNERS',
        value: 'my_partners'
      });
    }
  }
  

  const anchorRef = React.useRef(null);


  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, action) => {
    setOpen(false);
  };


  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();

      setOpen(false);
    }
  }

  useEffect(() => {
    history.listen((location) => {
      localStorage.getItem('reference') && setSelected(JSON.parse(localStorage.getItem('reference')).value);
    });
  }, [history]);
  
  // return focus to the button when we transitioned from !open -> open

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }


    prevOpen.current = open;
  }, [open]);
  

  return (

    <MenuOpen className={classes.root}
    
      onMouseOver={()=>{
        setOpen(true);
      }}
      onMouseLeave={()=>{
        setOpen(false);
      }}
    >

      <div>

        <StyledAPIButton 
          ref={anchorRef}
          // style={{padding: '0 1.5rem'}}
          aria-controls={open ? 'menu-list-grow' : undefined}

          aria-haspopup="true"
          className={props.activePage === 'reference' ? classes.active : ''} 

          onClick={(e)=>{
            if (!localStorage.getItem('reference')) {
              if (props.userConfig&&props.userConfig.role&&props.userConfig.role.includes('trusted_partner')) {
                props.pushToPage('reference/trusted_partner');
              } else if (props.userConfig&&props.userConfig.role&&!props.userConfig.role.includes('trusted_partner')) {
                setSelected(list[0].value);
                localStorage.setItem('reference', JSON.stringify(list[0]));
                props.pushToPage(`reference/${list[0].value}`);
              }
            } else {
              if (e.ctrlKey) {
                const refLink = JSON.parse(localStorage.getItem('reference')).value;
                const createPath = `reference/${refLink}`;
                const sanitizedURL= encodeURL(createPath);
                openInNewTab(sanitizedURL, true);
              } else {
                if (props.userConfig&&props.userConfig.role&&props.userConfig.role.includes('trusted_partner')) {
                  props.pushToPage('reference/trusted_partner');
                } else if (props.userConfig&&props.userConfig.role&&!props.userConfig.role.includes('trusted_partner')) {
                  setSelected(JSON.parse(localStorage.getItem('reference')).value);
                  props.pushToPage(`reference/${JSON.parse(localStorage.getItem('reference')).value}`);
                }
              }
              props.setActivePage('reference');
            }
          }}>
API REF {JSON.parse(localStorage.getItem('reference')) ? '- ' + JSON.parse(localStorage.getItem('reference')).name : ''}
        </StyledAPIButton>

        <Popper 
          style={{zIndex: 1, marginTop: '1rem'}}
          open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='bottom'>

          {({TransitionProps, placement}) => (

            <Grow

              {...TransitionProps}

              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}

            >

              <Paper>
                <ClickAwayListener onClickAway={handleClose}>

                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}
                  >
                    {
                      list.map((v, i)=><MenuItem style={{display: 'flex',
                        paddingRight: '3rem', paddingLeft: '1rem', 
                        color: selectedEnv===v.value ? cssVar('--tmo-magenta') : '#000'}} 
                      key={i} onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                        props.setActivePage('reference');
                        history.push(`/reference/${v.value}`);
                        localStorage.setItem('reference', JSON.stringify(v));
                        setSelected(v.value);
                      }}>
                        {v.name}
                      </MenuItem>)
                    }

                  </MenuList>

                </ClickAwayListener>

              </Paper>

            </Grow>

          )}

        </Popper>

      </div>
    </MenuOpen>

  );
};

export default ApiRef;

