/* eslint-disable complexity */
import React, {useEffect, useState} from 'react';
import {TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Button from '../shared/button';
import styled from 'styled-components';
import {ThemeProvider} from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import Text from '../shared/text';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import themes from '../../config/theme';
import './index.css';
import {cssVar} from '../../utils/utils';

export const useStyles = makeStyles((theme) => ({
  root: {
    
    '& .MuiAutocomplete-option': {
      '&:hover': {  
        color: `${themes.colors.secondary}`,
        backgroundColor: `${cssVar('--tmo-magenta')}`
      }
    },
    '& .MuiOutlinedInput-inputMultiline': {
      // margin: '0.5rem 0',
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: '13rem',
    },
    '& .MuiAutocomplete-popper': {
      padding: '4rem 0.5rem',
    },
    '& .MuiInputBase-input': {
      'color': '#000',
      'fontFamily': `${themes.fonts.primary}`,
      'fontSize': `${themes.texts.normalText}`,
      'fontWeight': '500',
      'padding': '1.15625rem 0.8125rem !important',
      '&:disabled': {
        // cursor: 'not-allowed',
      },
    },
    
    '& .MuiAutocomplete-root': {
      width: 'calc(100% - 0.9375rem)',
    },
    '& .MuiInputLabel-asterisk': {
      color: `${themes.colors.error}`,
    },
    '& .MuiSelect-nativeInput': {
      color: `${themes.colors.black}`,
      fontSize: `${themes.texts.normalText}`,
      opacity: 0.7,
    },
    '& .MuiTextField-root': {
      // 'margin': theme.spacing(1, 1, 1, 1),
      'width': '100%',
      '&.description': {
        '&>div': {
          height: '9.5rem',
          display: 'flex',
          alignItems: 'baseline',
        },
      },
      '& .MuiSvgIcon-root': {
        fill: `${cssVar('--tmo-magenta')}`,
      },
      '& .MuiOutlinedInput-root': {
        // '&.Mui-focused': {
        //   '& .MuiInputBase-input': {
        //     fontWeight: 'bold'
        //   }
        // },
        'padding': 0,
        'color': `${themes.colors.black}`,
        // '&.Mui-focused': {
        //   color: '#e20074',
        // },
        '& fieldset': {
          borderRadius: '0',
          borderColor: '#ccc !important'
        },
        '&.Mui-focused fieldset': {
          border: 'solid 0.0625rem #000000 !important',
        },
      },
      '& .MuiSelect-icon': {
        color: `${themes.colors.third}`,
      },
    },
    '& .MuiFormLabel-root': {
      'color': `${themes.colors.black} !important`,
      'fontFamily': `${themes.fonts.primary}`,
      'fontSize': themes.texts.normalText,
      'fontWeight': themes.weight.formLabel,
      // paddingLeft: '0.8rem !important',

      '&.Mui-focused': {
        color: `${themes.colors.black} !important`,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '1.15625rem 0.8125rem !important',
    },
    '& .Mui-disabled:hover fieldset': {
      border: '0.125rem solid #ccc !important',
    }
  }
}));

const FieldWrapper = styled.div`
  margin: 1.2rem 1rem;
  display: flex;
  position: ${(props) => props.position};
  width:${(props) => props.width ? props.width : ''};
`;
const FieldWrapperdes=styled.div`
  margin: 1.2rem 1rem 1.2rem 0.2rem;
  display: flex;
  position: ${(props) => props.position};
  width: '100%';
  
`;
const FieldWrappersub = styled.div`
  margin: 1rem 1rem 0.5rem auto;
  display: flex;
  justify-content: flex-end;
  position: ${(props) => props.position};
`;
export const MainDiv= styled.div`
 display: flex;
`;
export const SubDiv= styled.div`
 width: 50%;
`;
export const Loadwrap = styled.div`
  ${'' /* height: 85vh;
  width: 100%; */}
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Form(props) {
  const classes = useStyles();
  const [status, setStatus] = React.useState(true);
  const [nameStatus, setNameStatus] = React.useState(true);
  const [descripStatus, setDescripStatus] = React.useState(true);
  const {loggedInUser: {user_id: loggedInUserEmail, name: loggedInUserName}} = props;

  useEffect(() => {
    if (loggedInUserEmail )props.onclicktab({target: {name: 'email', value: loggedInUserEmail}}, 'email');
  }, [loggedInUserEmail]);

  useEffect(() => {
    if (loggedInUserName) props.onclicktab([loggedInUserName, loggedInUserEmail], 'name');
  }, [props.allValues.descript]);

  const blurevent = (e) => {
    if (e.target?.value?.trim().length> 0 || e.length?.trim() >0) {
      typeof e === 'object' ?
        setStatus(isEmail(e.target.value.trim())) :
        e ?
          setStatus(isEmail(e.trim())) :
          setStatus(true);
    } else {
      setStatus(true);
    }
  };

  const blureventDesc = (e) => {
    if (e.target?.value?.trim().length> 0 || e.length?.trim() >0) {
      typeof e === 'object' ?
        setDescripStatus(
          isLength(e.target.value.trim() + '', {min: 1, max: 180})
        ) :
        e ?
          setDescripStatus(isLength(e.trim() + '', {min: 1, max: 180})) :
          setDescripStatus(true);
    } else {
      setDescripStatus(true);
    }
  };
  const blureventName = (e) => {
    if (e.target?.value?.trim().length> 0 || e.length?.trim() >0) {
      typeof e === 'object' ?
        setNameStatus(
          isLength(e.target.value.trim() + '', {min: 1, max: 50})
        ) :
        e ?
          setNameStatus(isLength(e.trim() + '', {min: 1, max: 50})) :
          setNameStatus(true);
    } else {
      setNameStatus(true);
    }
  };

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" id="myForm" onSubmit={(e)=>{
        e.preventDefault();
      }}>
        <MainDiv>
          <SubDiv>
            <FieldWrapper position="relative">
              {/* <ThemeProvider> */}
              <TextField
                style={{width: '100%',
                  pointerEvents: loggedInUserName ? 'none' : '',
                  opacity: loggedInUserName ? '0.5' : '1'
                }}
                className="form-name"
                value={loggedInUserName ? loggedInUserName.trim() : props.allValues.fname}
                disabled={loggedInUserName ? true : false}
                // required
                InputLabelProps={{
                  shrink: true,
                }}
                id="outlined-full-width"
                label="Name"
                placeholder="Name"
                name="fname"
                variant="outlined"
                onBlur={blureventName}
                onChange={(e) => {
                  blureventName(e);
                  e.target.value = loggedInUserName ?
                    loggedInUserName :
                    e.target.value <= 50 ?
                      e.target.value :
                      e.target.value.slice(0, 50);
                  props.onclicktab(e, 'name');
                }}
              />
              {/* </ThemeProvider> */}
              {!nameStatus ? (
                <Text
                  position="absolute"
                  top="3.7rem"
                  left="0rem"
                  color="error"
                  fontSize="font12px"
                  // padding="0 0 0 1.6rem"
                >
                  Name is mandatory with max. 50 characters
                </Text>
              ) : (
                ''
              )}
            </FieldWrapper>
            <FieldWrapper position="relative">
              {/* <ThemeProvider> */}
              <TextField
                className="form-email"
                style={{width: '100%', marginTop: '1rem',
                  pointerEvents: loggedInUserEmail ? 'none' : '',
                  opacity: loggedInUserEmail ? '0.5' : '1'
                }}
                value={loggedInUserEmail ? loggedInUserEmail : props.allValues.user_id}
                // required
                disabled={loggedInUserEmail ? true : false}
                id="outlined-full-width"
                label={loggedInUserEmail&&isEmail(loggedInUserEmail) ? 'Email' : 'User ID'}
                placeholder="name@company.com"
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
                name="email"
                variant="outlined"
                onChange={(e) => {
                  e.target.value = loggedInUserEmail ?
                    loggedInUserEmail :
                    e.target.value;
                  blurevent(e);
                  props.onclicktab(e, 'email');
                }}
                onBlur={blurevent}
              />
              {/* </ThemeProvider> */}
              {!status ? (
                <Text
                  position="absolute"
                  top="4.7rem"
                  left="0.3rem"
                  color="error"
                  fontSize="font12px"
                  // padding="0 0 0 1.6rem"
                >
                  Email is not valid
                </Text>
              ) : (
                ''
              )}
            </FieldWrapper>
          </SubDiv>
          <SubDiv>
            <FieldWrapperdes position="relative">
              {/* <ThemeProvider> */}
              <TextField
                // required
                style={{width: '100%', pointerEvents: props.isTestUser ? 'none' : '',
                  opacity: props.isTestUser ? '0.5' : '1'}}
                className="description form-description"
                id="outlined-full-width"
                label="Message"
                placeholder="Briefly tell us about your needs and how we can help you."
                InputLabelProps={{
                  shrink: true,
                }}
                value= {props.allValues.descript}
                disabled={props.isTestUser ? true : false}
                name="descript"
                variant="outlined"
                minRows={6}
                multiline
                onBlur={blureventDesc}
                onChange={(e) => {
                  e.persist();
                  blureventDesc(e);
                  e.target.value =
                      e.target.value < 400 ?
                        e.target.value :
                        e.target.value.slice(0, 180);
                  props.onclicktab(e, 'description');
                }}
                // onChange={handleChangeDescription}
              />
              {/* </ThemeProvider> */}
              {!props.isTestUser ? !descripStatus ? (
                <Text
                  position="absolute"
                  top="9.5rem"
                  left="0rem"
                  color="error"
                  fontSize="font12px"
                  // padding="0 0 0 1.6rem"
                >
                  Description is mandatory with max. 180 characters
                </Text>
              ) : (
                ''
              ) : <Text
                position="absolute"
                bottom="-1.3rem"
                left="0rem"
                color="error"
                fontSize="font12px"
              // padding="0 0 0 1.6rem"
              >
              You don&apos;t have permission to perform this action
              </Text>}
            </FieldWrapperdes>
          </SubDiv>
        </MainDiv>
        <FieldWrappersub>
          <ThemeProvider>
            <Button
              padding='0.8rem 2.25rem'
              className={ props.status &&
                status &&
                descripStatus &&
                !props.products[0]?.error && !props.isTestUser?
                'contact-support-btn-gtm' :
                '' }
              disabled={
                props.status &&
                  status &&
                  descripStatus &&
                  !props.products[0]?.error && !props.isTestUser ?
                  false :
                  true
              }
              hoverBgColor = {props.status &&
                status &&
                descripStatus &&
                !props.products[0]?.error && !props.isTestUser &&'#ba0060'}
              // event={
              //   props.status &&
              //     status &&
              //     descripStatus &&
              //     !props.products[0]?.error ? '' : 
              //     'none'}
              opacity='1'
              bgColor={
                props.status &&
                  status &&
                  descripStatus &&
                  !props.products[0]?.error && !props.isTestUser ?
                  `${cssVar('--tmo-magenta')}` :
                  '#9b9b9b'}
              cursor={
                props.status &&
                      status &&
                      descripStatus &&
                      !props.products[0]?.error && !props.isTestUser ?
                  'pointer' :
                  'not-allowed'}
              onClick={props.onclick}
              family="primary"
              fontSize='font16px'
              weight='bold'
              width='8rem'
            >
              {props.load ? <Loadwrap>
                <Loader type="ThreeDots" color="#fff" height={15} width={40} />
              </Loadwrap>: <div>Send</div>}
            </Button>
          </ThemeProvider>
        </FieldWrappersub>
        {/* )} */}
      </form>
    </>
  );
}
export default Form;
