import {axiosPrivate} from '../interceptors';
import getBaseURL from './getBaseURL';

const baseURl = getBaseURL();

export const getAllUserGroups = () => {
  const url = '/devportal-admin/v3/user-groups';
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const createUserGroup = data => {
  const url = '/devportal-admin/v3/user-groups';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

export const updateUserGroup = data => {
  const url = '/devportal-admin/v3/user-groups';
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};

export const deleteUserGroup = groupID => {
  const url = `/devportal-admin/v3/user-groups/${encodeURIComponent(groupID)}`;
  return axiosPrivate.request({url: url, method: 'DELETE'});
};

export const getAllGroupLinkedItems = (groupID) => {
  const url = `/devportal-admin/v3/user-groups/get-all-user-group-items/${encodeURIComponent(groupID)}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};
