/* eslint-disable react/no-deprecated*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/global.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ThemeProvider} from 'styled-components';
import './create-nonce';
import {Provider} from 'react-redux';
import theme from './config/theme';
import configureStore from './store';
import {MuiThemeProvider} from '@material-ui/core';
import MuiTheme from './config/mui-theme';
import GlobalStyles from './config/globalStyles';

const store = configureStore();

// update createRoot logic to work with Node 18.
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={MuiTheme}>
        <GlobalStyles />
        <App />
      </MuiThemeProvider>
    </ThemeProvider>
  </Provider>
);
 
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
