/* eslint-disable max-len */
import React from 'react';
import {Grid} from '@material-ui/core';
import {GroupUsers, SectionUsers} from '../utils/ManageUserStyles';
import MultipleSelectionDropdown from '../../shared/MultipleSelectionDropdown';
import Button from '../../shared/button';
import Text from '../../shared/text';
import CommonTooltip from '../../shared/CommonTooltip';

function CustomGroup(props) {
  const {
    closeIcon, userGroupList, setSelectedCustomGroup, isAddingDisabled, handleRemoveGroup, User, Avatar, approvedCustomGroup, handleAddCustomGroup, getSliced, onClearMultiSelect, childCompRef, BUloader
  } = props;

  return (
    <>
      <GroupUsers>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={9}>
            <MultipleSelectionDropdown
              allList={userGroupList}
              // isEdit={isEdit}
              onClearMultiSelect={() => onClearMultiSelect()}
              setSelectedList={setSelectedCustomGroup}
              dropdownHeading="Select Custom Group"
              // isRequired={}
              ref={childCompRef}
              approvedCustomGroup={approvedCustomGroup}
              placeholder="Select Custom Group"
              BUloader={BUloader}
            />
          </Grid>
          <Grid item xs={2}>
            <Button 
              width= '7rem'
              height= '2.6rem'
              display= 'flex'
              style={{justifyContent: 'center', alignItems: 'center'}}
              hoverBgColor='#ba0060'
              onClick={() => {
                handleAddCustomGroup();
              }}
              disabled={isAddingDisabled}
              opacity={isAddingDisabled ? '0.5' : '1'}
            >
              <div style={{alignItems: 'center'}}>Add</div>
            </Button>
          </Grid>
        </Grid>
        <div>
          <Text color='#000' opacity='0.8' fontSize='formLabel' margin='2rem 0 0.3rem 0' weight='bold' family='secondary'>
            CUSTOM GROUP ({approvedCustomGroup.length})
          </Text>
        </div>
        <SectionUsers className='scrollbox'>
          {approvedCustomGroup.length>0 && approvedCustomGroup.map((v, i)=><User key={i}>
            <Avatar>
              {(v?.userGroup?.name?.[0])?.toUpperCase()??'CG'}
            </Avatar>
            <Text display='block' color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0 1rem 1rem' width= '70%'
              whiteSpace='nowrap' style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
            >
              <CommonTooltip
                arrow placement = "top" 
                title={v?.userGroup?.name?.length > 30 ? v?.userGroup?.name : ''}
              >
                {getSliced(v?.userGroup?.name, 30)}
              </CommonTooltip>
            </Text>
            {/* <Text display='block' color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0' width= '17%'
              whiteSpace='nowrap' style={{overflow: 'hidden', textOverflow: 'ellipsis', borderRight: '0.0625rem solid #c7c8d1'}}>Internal User</Text> */}
            <Text color='#000' opacity='0.8' fontSize='formLabel' margin='1rem  0 1rem 2rem' width= '12%' 
              style={{}}
            >Viewer</Text>
            <Button bgColor='transparent' width= '1rem' display='flex' color='#000' opacity='0.8' style={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
              <img
                onClick={()=>{
                  handleRemoveGroup(v);
                }}
                src={closeIcon}
                alt="close-icon"
                className=""
              />
            </Button>
          </User>)}
        </SectionUsers>
      </GroupUsers>
    </>
  );
}

export default CustomGroup;
