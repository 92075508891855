/* eslint-disable*/
import { ClickAwayListener } from '@material-ui/core';
import _, { isEmpty } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import styled from 'styled-components';
import docIcon from '../../../assets/images/globalsearch/docIcon.svg';
import docIconActive from '../../../assets/images/globalsearch/docIconPink.svg';
import sdkIcon from '../../../assets/images/globalsearch/sdkIcon.svg';
import sdkIconActive from '../../../assets/images/globalsearch/sdkIconPink.svg';
import apiIcon from '../../../assets/images/globalsearch/apiIcon.svg';
import apiIconActive from '../../../assets/images/globalsearch/apiIconPink.svg';
import loader from '../../../assets/images/globalsearch/Spin.gif';
import closeIcon from '../../../assets/images/globalsearch/group-3.svg';
import recentSearchIcon from '../../../assets/images/globalsearch/recentIcon.svg';
import headerSearch from '../../../assets/images/globalsearch/header-search-icon.svg';
import WhiteIcon from '../../../assets/images/globalsearch/white-search-icon.svg';
import {getGlobalSearchResults} from '../../../api/services/documents';
import Alerts from '../../utils/userAlert';
import {cssVar} from '../../../utils/utils';
const {SuccessAlert, ErrorAlert, CustomErrorAlert} = Alerts;
const SearchWrap = styled.div`

.site-search {
  position: relative;
  margin: 0 1.5rem;
}

.site-search-box {
  display: flex;
  height: 100%;
  transition: all 0.5s ease;
  width: 1.5rem;
  position: absolute;
  right: 0rem;
  top: 0;
  z-index: 3;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.site-search-box > input.search {
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  font-family: "TeleGroteskNext";
  color: #323c47;
  cursor: pointer;
  width: 0%;
  padding: 0;
  font-weight: 500;
}

.site-search-expanded .site-search-box > input.search {
  background-color: #fff;
  cursor: text;
  padding: 1.35rem 0 1.35rem 2.5rem;
  padding-left: 3.6rem;
  padding-right: 2rem;
  height: 1.6rem;
  font-size: 1em;
  line-height: 1.29;
  font-family: "TeleGroteskNext";
  color: #474a68;
  border-top-left-radius: .3125rem;
  border-top-right-radius: .3125rem;
  width: 100%;
}

.site-search-expanded .site-search-box > input.search ::placeholder {
  // color: #9B9DB0;
  opacity: 1; /* Firefox */
}
.site-search-expanded .site-search-box > input.search :-ms-input-placeholder { /* Internet Explorer 10-11 */
  // color: #9B9DB0;
 }
 
 .site-search-expanded .site-search-box > input.search ::-ms-input-placeholder { /* Microsoft Edge */
  // color: #9B9DB0;
 }

.site-search-box .site-search-box-icon,
.site-search-expanded .site-search-box:hover .site-search-box-icon,
.site-search-nav-icon {
  content: url(${headerSearch});
  background-repeat: no-repeat;
  opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
}



.site-search-box-icon {
  transition: right 0.2s ease-in-out;
  position: absolute;
  color: #8084aa;
  left: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.site-search-expanded .site-search-box-icon {
  position: absolute;
  right: 1.2rem;
  display: block;
  display: flex;
}

.site-search-box-dropdown {
  display: none;
  opacity: 0;
  position: absolute;
  border-radius: 0rem 0rem .3125rem .3125rem;
  height: 0rem;
  transition: all 10s ease !important;
  transition-delay: 20s !important;
  padding: 0;
}
.site-search-expanded {
  background: #fff;
}
.site-search-expanded .site-search-box {
  width: 37.5rem;
  background: #fff;
  box-shadow: 0 1.25rem 1.875rem 0 rgba(144,157,193,0.25);
  :focus{
    border:none;
    background: #fff;
  box-shadow: 0 1.25rem 1.875rem 0 rgba(144,157,193,0.25);
  }
}

.site-search-expanded .site-search-box-dropdown {
  display: block;
  opacity: 1;
  top: 2.3rem;
  background-color: #fff;
  height: auto;
  min-height: 6.25rem;
  box-sizing: content-box;
  padding-bottom: 1rem;
  padding-top: 0.875rem;
  overflow-y: auto;
  box-shadow: 0 1.25rem 1.875rem 0 rgba(144,157,193,0.25)
}
.site-search-expanded .site-search-box-dropdown ul {
  padding: 0rem;
  list-style: none;
}
.site-search-expanded .site-search-box-dropdown ul li {
  height: auto !important;
}
.site-search-expanded .search::-webkit-input-placeholder, .site-search-expanded .search:-ms-input-placeholder {
  // color: #ddd;
}
.site-search-expanded .search::placeholder {
  // opacity: 0.3;
}
.site-search-box-dropdown li:hover{
  background-color: #f7f8fa !important;
}
.site-search-box-dropdown a > img {
  padding-right: .9375rem;
  outline: unset;
  margin: unset;
  opacity: 0.5;
}

/* // For Bounce Loader */
.justify-content-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
textarea:focus, input:focus{
  outline: none;
}
.no-res-text {
  color: #000;
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top:50%;
  white-space: nowrap;
  color: rgb(119,119,119);
  text-transform: capitalize;
  font-weight: 600;
}
#overlay {
  transition: all 0.5s ease;
  position: fixed;
  /* // display: none; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}

.site-search-box-icon img, .cross-icon img, .site-search-expanded .site-search-box input  {
  height: 100%;
}

.cross-icon img, .site-search-box-icon img, .site-search-expanded .cross-icon img, .site-search-expanded .border-bottom-search, .site-search-expanded .search, .site-search-expanded .site-search-box-dropdown {
  width: 100%;
}

.cross-icon img {
  transition: all 0.5s ease;
}
.cross-icon img:hover {
  transform: rotate(180deg);
}

.site-search-box-dropdown > li:last-child {
  padding-bottom: 0.625rem !important;
}
.site-search-box-dropdown li:last-child:hover {
  border-bottom-left-radius: .3125rem;
  border-bottom-right-radius: .3125rem;
}
.site-search-expanded .cross-icon {
  width: 0.875rem;
  margin-right: 1.7rem;
  cursor: pointer;
}

.site-search-expanded .searchIcon-div {
    background-color: #fff;
    padding: 0.4rem 0.25rem 0.25rem 0;
    cursor: pointer;
    border-top-right-radius: .3125rem;
    margin-left: -0.3rem;
}
.site-search-expanded .site-search-box-dropdown .docType, .site-search-box-dropdown .recentSearch .bold {
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: .0625rem;
  opacity: 0.6;
  text-transform: uppercase;
}
.site-search-expanded .textEllipses, .searchSuggestion.nowrap-ellipsis {
  width: 86%;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.7rem 0 0.5rem 0;
  text-overflow: ellipsis;
  padding-left: 0.625rem;
  font-size: 0.875rem;
  // text-transform: capitalize;
}
.site-search-expanded .site-search-box-dropdown .magentaText {
  font-size: 0.6875rem;
  cursor: pointer;
}
.site-search-expanded .docWrap {
  padding-top: 0.3125rem;
}
.site-search-expanded .docImg {
  height: 0.875rem;
}

.site-search-expanded .search-loader, .site-search-expanded .search-noData {
  height: -webkit-fill-available;
  font-family: "TeleGroteskNext";
  font-size: 1em;
  color: rgb(71, 74, 104);
  margin-top: 2.5rem;
}
.site-search-expanded .search-loader img {
  width: 2rem;
  height: 2rem;
}
.crossLoader img {
  width: 1.5rem;
  height: 1.5rem;
}
.crossLoader {
  padding: 0.54rem 1rem;
  background-color: #fff;
  margin-left: -0.25rem;
  border-top-right-radius: .3125rem;
}
.docWrap .searchSuggestion:hover {
  color: ${()=> cssVar('--tmo-magenta')};
  
}
.searchSuggestion, .searchSuggestion:hover {
  color: #474a68;
}

.site-search-expanded .border-bottom-search {
  height: .0625rem;
  background-color: #eee;
}
.site-search-expanded .close-icon {
  height: 1.5rem;
  margin: 0.15rem;
  opacity: 0.6;
  top: 0;
}
.site-search-expanded .paddingLR {
  padding: 0 1rem;
}
.site-search-expanded .paddingLeft, .site-search-expanded .recentSearch .searchSuggestion , .recentSearch .bold{
  padding-left : 1.125rem;
}
.site-search-box input::placeholder {
  // color: rgba(255, 255, 255, 0.6);
  font-size: 1em;
  opacity: 0.5;
  font-family: "TeleGroteskNext";
  font-weight: 500;
}
.docWrap .searchSuggestion .Active {
  display: none;
}
.docWrap .searchSuggestion:hover .NotActive {
  display: none;
}
.docWrap .searchSuggestion:hover .Active{
  display: block;
}
.site-search-box hr {
  width: 94%;
  border: 0;
  border-top: 0.0625rem solid rgba(0,0,0,.1);
}
.search-underline {
  position: absolute;
  width: 87%;
  opacity: 0.8;
  top: 1.3rem;
  height: .0625rem;
  background-color: #474a68;
}
.site-search-expanded .magentaText:hover {
  text-decoration: underline;
}
.site-search-expanded .close-icon:hover {
  opacity: 1;
}
.recentSearch .bold {
  font-weight: 600;
}

.site-search-box:not(.radius-fix) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.site-search-box .radiusFix {
  border-bottom-left-radius: .3125rem;
  border-bottom-right-radius: .3125rem;
}

.recentSearch .searchSuggestion {
  display: block;
}
.site-search-box-dropdown {
  max-height: 21.25rem;
}
.site-search-box:hover input::placeholder {
  // color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
}
.site-search-box:hover .site-search-box-icon svg path {
  transition: all 0.3s ease;
  fill: #fff;
  opacity: 0.8;
}
.site-search-expanded .site-search-box:hover input::placeholder, .site-search-expanded .search::placeholder, .site-search-expanded .site-search-box-icon, .search::-webkit-input-placeholder, .search:-ms-input-placeholder, .search::placeholder {
  // color: #8084aa;
}
.searchSuggestion.transition.nowrap-ellipsis {
  width: 100%;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
}

.site-search-box:hover .site-search-box-icon {
  background-image: url(${WhiteIcon});
  opacity: 0.8;
  background-repeat: no-repeat;
}

.search::-webkit-input-placeholder, .search:-ms-input-placeholder, .search::placeholder, .site-search-expanded .search::placeholder, .site-search-box input {
  font-size: 1rem;
}
`;

const Search = (props) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const history = useHistory();
  const overflow = data => {
  document.getElementsByTagName('body')[0].style.overflow = data;
};
  const {
    pushToPage,
    setActivePage
  } = props;
  const [docData, setDocData] = useState([]);
  const [sdkData, setSdkData] = useState([]);
  const [APIData, setAPIData] = useState({});
  const [showLoad, setShowLoad] = useState(false);
  const [count, setCount] = useState({});
  const searchInputRef = useRef();

  // txtSearch is the query what we have in input box
  const [txtSearch, setTxtSearch] = useState('');

  const [txtLimitError, setTxtLimitError] = useState(false);
  const searchInp = typeof document != 'undefined'
    && document.getElementsByClassName('search');
  const expandEle = typeof document != 'undefined'
    && document.getElementsByClassName('site-search-expanded');
  let searchStr;
  const { localStorage } = window;
  const handleKeyDown = (event, action) => {
    if (event.key === "Enter" || event.key === " ") {
      action(event); // Pass the event object to the action
    }
  };
  const apiEnv = {'dev':'Development', 'prod':'Production','my_partners':'My Partners','trusted-partner':'Partner'}
  const handleChangeSearch = (e) => {
    overflow('hidden');
    searchStr = e.target.value;
    setTxtSearch(searchStr);
    if ((searchStr.length > 150)) {
      setTxtLimitError(true);
    } else {
      setTxtLimitError(false);
    }
    getData(searchStr);
  };

  const clearSearch = () => {
    setTxtSearch('');
    setTxtLimitError(false);
    getData('');
    if (typeof searchInp != 'undefined' && searchInp[0] != 'undefined') {
      searchInp[0].focus();
    }
    searchInputRef?.current && searchInputRef?.current.focus();
  };

  const addClass = event => {

    const targetElement = event.target;
    
    if (typeof expandEle != 'undefined' && typeof expandEle[0] == 'undefined') {
      overflow('hidden');
      event.target.ontouchend = e => {
        e.preventDefault();
        e.pointerEvents = 'none';
        const bodyEl = document.getElementsByTagName('body')[0];
        if (bodyEl) {
          bodyEl.style.pointerEvents = 'none';
        }
      };
      let element = document.getElementsByClassName('site-search');
      element[0].classList.add('site-search-expanded');
      document.getElementById('overlay') &&
        document.getElementById('overlay').style &&
        (document.getElementById('overlay').style.visibility = 'visible') &&
        (document.getElementById('overlay').style.opacity = 1);
      document
        .getElementsByTagName('body')[0]
        .addEventListener('onmousewheel', e => {
          e.preventDefault();
          e.stopPropagation();
          return false;
        });
      searchInp && searchInp[0] && searchInp[0].focus();
      if (searchInp && searchInp[0] && searchInp[0].value) {
        getData();
      }
    }
    if (searchInputRef.current && typeof searchInputRef.current.focus === 'function') {
      searchInputRef.current.focus();
    }
   
  };

  // on click of overlay
  const handleClickAway = url => {
    if (typeof expandEle != 'undefined' && typeof expandEle[0] != 'undefined') {
      // on click of showmore or click enter
      let element = expandEle;
      if (element && element.length > 0) {
        element[0].classList.remove('site-search-expanded');
        document.getElementById('overlay') &&
          document.getElementById('overlay').style &&
          (document.getElementById('overlay').style.visibility = 'hidden') &&
          (document.getElementById('overlay').style.opacity = 0);
      }
      overflow('auto');
      setTxtSearch('');
      setTxtLimitError(false);
      if (searchInp && searchInp[0]) {
        searchInp[0].value = '';
        searchInp[0].blur();
      }
    }
    if (searchInputRef.current && typeof searchInputRef.current.blur === 'function') {
      searchInputRef.current.blur();
    }
  };

  const getDataFun = (searchTxt = '') => {
    const search = encodeURIComponent(searchTxt.trim());
    let getAllDocs = getGlobalSearchResults(search);
    return Promise.resolve(getAllDocs);
  };

  const getData = (searchTxt) => {
    setShowLoad(true);
    getDataFun(searchTxt)
      .then(result => {
        let count = result.data.count;
        result = result.data.list;
        setDocData(result.documents);
        setSdkData(result.sdk)
        setAPIData(result.api)
        setCount(count);
        setShowLoad(false);
      })
      .catch(e => {
        CustomErrorAlert(e.response&&e.response.data.message)
        setShowLoad(false);
      });
  };

  // Setting recent search query in localStorage

  const setRecentSearch = (fn, txt) => {
    let trimTxt = (fn && fn === 'setSearchQuery') ? txt.trim() : txtSearch.trim();
    let localStorageArr;
    if (localStorage.getItem('recentSearch') === null) {
      localStorageArr = [];
    } else {
      localStorageArr = [...(JSON.parse(localStorage.getItem('recentSearch'))
        && JSON.parse(localStorage.getItem('recentSearch')))];
    }
    if (trimTxt != '') {
      if (!localStorageArr.includes(trimTxt)) {
        if (localStorageArr.length === 10) {
          localStorageArr.shift();
          localStorageArr = [...localStorageArr, trimTxt];
        } else {
          localStorageArr = [...localStorageArr, trimTxt];
        }
      } else {
        let index = localStorageArr.indexOf(trimTxt);
        localStorageArr.splice(index, 1);
        localStorageArr = [...localStorageArr, trimTxt];
      }
    }
    if (localStorageArr.length) {
      localStorage.setItem('recentSearch', JSON.stringify(localStorageArr));
    }
  };

  const showMore = (docPath) => {
    setRecentSearch();
    // docPath.includes('api-reference')?setActivePage('api-reference'):setActivePage(docPath);
    // pushToPage(docPath+`?search=${txtSearch}`)
    // history.go()
    // handleClickAway('showmore');
    handleClickAway();
    setActivePage('')
    history.push(`/search-results/${encodeURIComponent(txtSearch)}?title=${docPath}`);
  };

  // navigate to particulat detail page
  const detailPage = (item,classification,env='') => {
    handleClickAway();
    if (classification === 'documents' || classification === 'sdk') {
      setRecentSearch();
      setActivePage(classification)
      history.push(`/${classification}/${item._id}?name=${item.title.replace(/\s+/g, '-').toLowerCase()}`)
    } else if (classification === 'api'){
      setActivePage('reference')
      if (env.includes('trusted-partner')) {
        history.push(`/reference/${env}`);
      } else {
        history.push(`/reference/${env}/${item.name.replaceAll(' ', '_')}`)
      }
    } 
  };

  const onKeyPressFn = (e, paste) => {
    searchInp && searchInp[0] && searchInp[0].focus();
    if (paste === 'paste') {
      setTimeout(() => {
        setTxtSearch(searchInp && searchInp[0].value);
      }, 50);
    }
    if (e.which === 13) {
      if(searchInp && searchInp[0].value) {
        showMore('all');
      } 
      else{
        history.push(`/search-results/`);
        handleClickAway();
      } 
        
    } 
    
    if (paste === 'icon-click') {
      if (!(searchInp && searchInp[0] && searchInp[0].value.length > 150)) {

        setRecentSearch();
        // setTab('all');
        setTxtLimitError(false);
        //navigate('/search?tab=all');
        handleClickAway('enterClick');
      }
    }
  };

  // Select search query by clicking on recent search options
  const setSearchQuery = query => {
    if (query.length > 150) {
      setTxtLimitError(true);
    } else {
      setTxtSearch(query);
      setTxtLimitError(false);
    }
    setRecentSearch('setSearchQuery', query);
    if (searchInp && searchInp[0]) {
      searchInp[0].focus();
    }
    getData(query);
  };
  const env = 'dev'
  const env2 = 'prod'
  const env3 = 'my_partners'
  const env4 = 'trusted-partner'
  useEffect(() => {
    return () => { };
  }, [props, txtSearch]);

  const parseRecentSearch = JSON.parse(localStorage.getItem('recentSearch')) || [];

  return (
    <SearchWrap>
    <div onClickCapture={addClass} className="nav-type-icon site-search" tabIndex="0" onKeyDown={(e) => handleKeyDown(e, addClass)} role="searchbox">
      <img className="site-search-nav-icon" alt="search-icon" />
      <ClickAwayListener onClickAway={handleClickAway}>
          <div className="site-search-box">
            <div onClick={(e) => onKeyPressFn(e)} className="site-search-box-icon"></div>
            <DebounceInput
              maxLength="151"
              debounceTimeout={300}
              ref={searchInputRef}
              className={`${((localStorage != undefined && !isEmpty(parseRecentSearch)) || txtSearch.trim().length != 0) ? 'search' : 'radiusFix search'}`}
              type="search"
              placeholder="Search"
              title={txtSearch.length > 65 ? txtSearch:''}
              value={txtSearch}
              onPaste={(e) => onKeyPressFn(e, 'paste')}
              // onFocus={(e) => onKeyPressFn(e)}
              onKeyPress={(e) => onKeyPressFn(e)}
              onChange={e => handleChangeSearch(e)}
            />
            
            {txtSearch != '' &&
              <button className="searchIcon-div" onClick={clearSearch} onKeyDown={clearSearch}>
                <img
                  src={closeIcon}
                  alt="close-icon"
                  className="close-icon transition"
                />
              </button>
            }
            {((localStorage != undefined && !isEmpty(parseRecentSearch)) || txtSearch.trim().length != 0) &&
              <div className="site-search-box-dropdown transition">
                {!showLoad ? <>
                  {txtLimitError === true && <div className="textvalidation justify-content-center">limit exceeded of max 150 characters</div>}
                  {(localStorage != undefined && isEmpty(parseRecentSearch)) || ((localStorage != undefined && parseRecentSearch != null && parseRecentSearch != 'undefined' && parseRecentSearch.length === 0) && txtSearch.length === 0) || ((localStorage != undefined && parseRecentSearch != null && parseRecentSearch != 'undefined' && parseRecentSearch.length > 0) && txtSearch.length > 0) ? <>
                    {docData&&docData.length > 0 && <>
                      <div>
                        <div className="d-flex justify-content-between align-items-center paddingLR ">
                          <div className="docType">DOCUMENTS</div>
                          {count.documents > 3 &&
                            <div onClick={() => showMore('documents')} className="magentaText">{`Show all (${count.documents})`}</div>}
                        </div>
                        <div className="docWrap">
                          {docData&&docData.length > 0 && docData.map((ele, i) => {
                            if (i < 3) {
                              return (
                                <div onClick={() => detailPage(ele,'documents')} className="s-doc searchSuggestion transition d-flex align-items-center cursor-pointer">
                                  <div className="d-flex align-items-center paddingLeft">
                                    <img className="docImg NotActive"
                                      src={docIcon}
                                      alt="blog icon" />
                                    <img className="docImg Active"
                                      src={docIconActive}
                                      alt="blog icon" />
                                  </div>
                                  <div className="textEllipses" title={ele.summary ? `Description: ${ele.summary}`:''}>{ele.title}</div>
                                </div>
                              );
                            }
                          })}</div>
                      </div>
                    </>}
                    {sdkData && sdkData.length > 0 && <><hr></hr>
                      <div>
                        <div className="d-flex justify-content-between align-items-center paddingLR ">
                          <div className="docType">SDKS</div>
                          {count.sdk > 3 &&
                            <div onClick={() => { showMore('sdk'); }} className="magentaText">{`Show all (${count.sdk})`}</div>}
                        </div>
                        <div className="docWrap">
                          {sdkData && sdkData.length > 0 && sdkData.map((ele, i) => {
                            if (i < 3) {
                              return (
                                <div onClick={() => detailPage(ele,'sdk')} className="s-blogs searchSuggestion transition d-flex align-items-center cursor-pointer">
                                  <div className="d-flex align-items-center paddingLeft">
                                    <img className="docImg NotActive" src={sdkIcon} alt="blog icon" />
                                    <img className="docImg Active" src={sdkIconActive} alt="blog icon" />
                                  </div>
                                  <div className="textEllipses" title={ele.summary ? `Description: ${ele.summary}`:''}>{ele.title}</div>
                                </div>);
                            }
                          })}
                        </div>
                      </div>
                      
                    </>}
                    {   
                        APIData[env] && APIData[env].length > 0 && <><hr></hr>
                          <div>
                            <div className="d-flex justify-content-between align-items-center paddingLR ">
                              <div className="docType">APIS - {apiEnv[env]}</div>
                              {count.api[env] > 3 &&
                                <div onClick={() => { showMore(`reference/${env}`); }} className="magentaText">{`Show all (${count.api[env]})`}</div>}
                            </div>
                            <div className="docWrap">
                              {APIData[env] && APIData[env].length > 0 && APIData[env].map((ele, i) => {
                                if (i < 3) {
                                  return (
                                    <div onClick={() => {
                                      localStorage.setItem('reference', JSON.stringify({name: "DEVELOPMENT", value: "dev"}))
                                      detailPage(ele,'api',env)
                                     
                                      }} className="s-blogs searchSuggestion transition d-flex align-items-center cursor-pointer">
                                      <div className="d-flex align-items-center paddingLeft">
                                        <img className="docImg NotActive" src={apiIcon} alt="blog icon" />
                                        <img className="docImg Active" src={apiIconActive} alt="blog icon" />
                                      </div>
                                      <div className="textEllipses" title={ele.name ? `Title: ${ele.name}`:''}>{ele.name}</div>
                                    </div>);
                                }
                              })}
                            </div>
                          </div>
                        </>
                    }
                    {
                        APIData[env2] && APIData[env2].length > 0 && <><hr></hr>
                          <div>
                          
                            <div className="d-flex justify-content-between align-items-center paddingLR ">
                              <div className="docType">APIS - {apiEnv[env2]}</div>
                              {count.api[env2] > 3 &&
                                <div onClick={() => { showMore(`reference/${env2}`); }} className="magentaText">{`Show all (${count.api[env2]})`}</div>}
                            </div>
                            <div className="docWrap">
                              {APIData[env2] && APIData[env2].length > 0 && APIData[env2].map((ele, i) => {
                                if (i < 3) {
                                  return (
                                    <div onClick={() => {
                                      localStorage.setItem('reference', JSON.stringify({name: "PRODUCTION", value: "prod"}))
                                      detailPage(ele,'api',env2)
                                      }} className="s-blogs searchSuggestion transition d-flex align-items-center cursor-pointer">
                                      <div className="d-flex align-items-center paddingLeft">
                                        <img className="docImg NotActive" src={apiIcon} alt="blog icon" />
                                        <img className="docImg Active" src={apiIconActive} alt="blog icon" />
                                      </div>
                                      <div className="textEllipses" title={ele.name ? `Title: ${ele.name}`:''}>{ele.name}</div>
                                    </div>);
                                }
                              })}
                            </div>
                          </div>
                        </>
                    }
                    {
                        APIData[env3] && APIData[env3].length > 0 && <><hr></hr>
                          <div>
                          
                            <div className="d-flex justify-content-between align-items-center paddingLR ">
                              <div className="docType">APIS - {apiEnv[env3]}</div>
                              {count.api[env3] > 3 &&
                                <div onClick={() => { showMore(`reference/${env3}`); }} className="magentaText">{`Show all (${count.api[env3]})`}</div>}
                            </div>
                            <div className="docWrap">
                              {APIData[env3] && APIData[env3].length > 0 && APIData[env3].map((ele, i) => {
                                if (i < 3) {
                                  return (
                                    <div onClick={() => {
                                      localStorage.setItem('reference', JSON.stringify({name: "MY PARTNERS", value: "my_partners"}))
                                      detailPage(ele,'api',env3)
                                      }} className="s-blogs searchSuggestion transition d-flex align-items-center cursor-pointer">
                                      <div className="d-flex align-items-center paddingLeft">
                                        <img className="docImg NotActive" src={apiIcon} alt="blog icon" />
                                        <img className="docImg Active" src={apiIconActive} alt="blog icon" />
                                      </div>
                                      <div className="textEllipses" title={ele.name ? `Title: ${ele.name}`:''}>{ele.name}</div>
                                    </div>);
                                }
                              })}
                            </div>
                          </div>
                        </>
                    }
                    {
                        APIData[env4] && APIData[env4].length > 0 && <><hr></hr>
                          <div>
                          
                            <div className="d-flex justify-content-between align-items-center paddingLR ">
                              <div className="docType">APIS - {apiEnv[env4]}</div>
                              {count.api[env4] > 3 &&
                                <div onClick={() => { showMore(`reference/${env4}`); }} className="magentaText">{`Show all (${count.api[env4]})`}</div>}
                            </div>
                            <div className="docWrap">
                              {APIData[env4] && APIData[env4].length > 0 && APIData[env4].map((ele, i) => {
                                if (i < 3) {
                                  return (
                                    <div onClick={() => {
                                      localStorage.setItem('reference', JSON.stringify({name: "PARTNER", value: "trusted-partner"}))
                                      detailPage(ele,'api',env4)
                                      }} className="s-blogs searchSuggestion transition d-flex align-items-center cursor-pointer">
                                      <div className="d-flex align-items-center paddingLeft">
                                        <img className="docImg NotActive" src={apiIcon} alt="blog icon" />
                                        <img className="docImg Active" src={apiIconActive} alt="blog icon" />
                                      </div>
                                      <div className="textEllipses">{ele.name}</div>
                                    </div>);
                                }
                              })}
                            </div>
                          </div>
                        </>
                    }
                    
                  </> :
                    <div className="recentSearch">
                      <div className="bold">{`Recent ${localStorage != undefined && parseRecentSearch != null && parseRecentSearch.length > 1 ? 'Searches' : 'Search'}`}</div>
                      <div className=" docWrap cursor-pointer bold">
                        {localStorage != undefined && parseRecentSearch != null && parseRecentSearch.length > 0 && _.reverse(parseRecentSearch).map((query, index) => {
                          return (
                          <div className='d-flex'>
                            <img src={recentSearchIcon}/>
                            <div key={index} title={query.length > 60 ? query:''} onClick={() => setSearchQuery(query)} className="searchSuggestion transition nowrap-ellipsis">
                            {query}
                          </div>
                          </div>);
                        }
                        )}
                      </div>
                    </div>
                    
                  }
                </> : <div className="d-flex align-items-center justify-content-center search-loader"><img src={loader} alt="loader" /></div>}
                {/* Condition check for no result found, checking for loader, text in input filed and docs data */}
                {!showLoad && typeof searchInp != 'undefined' && searchInp[0] && searchInp[0].value && docData&&docData.length < 1 && sdkData&&sdkData.length < 1 &&APIData[env]  && APIData[env].length<1 &&APIData[env2]&&APIData[env2].length<1 &&APIData[env3]&&APIData[env3].length<1 &&APIData[env4]&&APIData[env4].length<1 &&
                  
                  <div className="d-flex align-items-center justify-content-center search-noData">No Results Found. Please Try With A Different Keyword.</div>
                }
              </div>}
          </div>
        </ClickAwayListener>
      </div>
    </SearchWrap>
  );
};

export default Search;