import React, {useState, useEffect, useRef} from 'react';
import {SectionUsers} from '../utils/ManageUserStyles';
import GroupView from './GroupView';
import Text from '../../shared/text';

const GroupUsersViewComponent = ({
  groupUsers, setGroupUsers, GroupWrapper, User, StyledDropDown, dropdownArrowMagenta, Avatar, getSliced, closeIcon
}) => {
  const [modifiedGroupUsers, setModifiedGroupUsers] = useState([]);
  const sectionRef = useRef();
  const groupUsersCount = useRef(0);

  const isSameDomain = (element, domainName) => {
    if (element.domain === domainName) {
      return true;
    } else {
      return false;
    }
  };

  const modifyGroupUsers = (groups) => {
    const modifiedArr = [];
    groups.forEach((group, groupIndex)=>{
      if (!group.toBeRemoved) {
        const indexedGroup = {...group, index: groupIndex, children: []};
        if (indexedGroup.domain) {
          const checkingDomain = indexedGroup.domain;
          const groupIndexWithSameDomain = modifiedArr.findIndex((element)=>isSameDomain(element, checkingDomain));
          if (groupIndexWithSameDomain !== -1) {
            if (modifiedArr[groupIndexWithSameDomain].buName && !indexedGroup.buName) {
              modifiedArr.push(indexedGroup);
            } else {
              const modifingParentGroup = modifiedArr.splice(groupIndexWithSameDomain, 1)[0];
              modifingParentGroup.children.unshift(indexedGroup);
              modifiedArr.push(modifingParentGroup);
            }
          } else {
            if (indexedGroup.buName) {
              modifiedArr.push({...indexedGroup, children: [indexedGroup]});
            } else { 
              modifiedArr.push(indexedGroup);
            }
          }
        } else {
          modifiedArr.push(indexedGroup);
        }
      }
    });
    setModifiedGroupUsers(modifiedArr.reverse());
  };

  useEffect(()=>{
    modifyGroupUsers(groupUsers);
    const groupUsersLength = groupUsers.length;
    if (sectionRef.current && groupUsersLength > groupUsersCount.current) {
      groupUsersCount.current = groupUsersLength;
      sectionRef.current.scrollTo({top: 0, behavior: 'smooth'});
    } else {
      groupUsersCount.current = groupUsersLength;
    }
  }, [groupUsers]);

  const removeGroup = (groupToBeRemoved) => {
    const bothDomainAndBU = (groupToBeRemoved?.buName?._id && groupToBeRemoved?.domain);
    let domainsList = [];
    if (bothDomainAndBU) {
      domainsList = groupUsers.filter((grp)=> grp.domain === groupToBeRemoved.domain);
    }
    const listWithoutGroup = groupUsers.filter((group, index)=>{
      if (bothDomainAndBU && group.domain === groupToBeRemoved.domain && group.toBeRemoved) {
        if (domainsList.length === 2) {
          group.toBeRemoved = false;
        }
      }
      return index !== groupToBeRemoved.index;
    });
    setGroupUsers(listWithoutGroup);
  };

  return (
    <>
      <Text color='#000' opacity='0.8' fontSize='formLabel' margin='2rem 0 0.5rem 0' weight='bold' family='secondary'>
        GROUP USERS ({modifiedGroupUsers.length})
      </Text>
      <SectionUsers ref={sectionRef}>
        {modifiedGroupUsers.length>0 && modifiedGroupUsers.map((modifiedGroup, index)=>
          <GroupView 
            key={index}
            modifiedGroup={modifiedGroup}
            removeGroup={removeGroup}
            GroupWrapper={GroupWrapper}
            User={User}
            StyledDropDown={StyledDropDown}
            dropdownArrowMagenta={dropdownArrowMagenta}
            Avatar={Avatar}
            getSliced={getSliced}
            closeIcon={closeIcon}
          />)}
      </SectionUsers>
    </>
  );
};

export default GroupUsersViewComponent;
