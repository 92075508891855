/* eslint-disable max-len */
import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CommonTooltip from '../../shared/CommonTooltip';
import Dropdown2 from '../components/dropdown2';

const SelectionListComponent = ({loader, dropDownList, selectedItem, setSelectedItem, isDisabled, itemLabel, label, placeholder, closeIcon,
  Arrow, customStyle, disabledMessage})=>{
  const [searchVal, setSearchVal] = useState(selectedItem?.[itemLabel] || '');
  const [openList, setOpenList] = useState(false);
  const isPointerOnList = useRef();
  const textFieldRef = useRef();

  const useStyles = makeStyles((theme) => ({
    groupInput: {
      '& .MuiInputBase-input': {
        'cursor': isDisabled?'not-allowed !important':'pointer !important',
      },
    }
  }
  ));

  const classes = useStyles();

  useEffect(()=>{
    if (textFieldRef.current) {
      const input = textFieldRef.current.querySelectorAll('input')[0];
      textFieldRef.current = input;
    }
  }, []);

  useEffect(()=>{
    if (typeof(selectedItem) === 'object') {
      setSearchVal(selectedItem[itemLabel]);
    } else {
      setSearchVal(selectedItem);
    }
  }, [selectedItem]);

  useEffect(()=>{
    if (searchVal === '' && (selectedItem !== '' || (itemLabel && selectedItem[itemLabel] !== ''))) {
      if (typeof(selectedItem) === 'object') {
        setSelectedItem({[itemLabel]: ''});
      } else {
        setSelectedItem('');
      }
    }
  }, [searchVal]);

  const onSelect = (item) => {
    setSelectedItem(item);
    setSearchVal(item.name);
    setOpenList(false);
    isPointerOnList.current = false;
    textFieldRef.current.blur();
  };

  const filterDropDownList = () =>{
    if (dropDownList.length) {
      return dropDownList.filter((item) => {
        if (itemLabel) {
          return item[itemLabel].toLowerCase().includes(searchVal.toLowerCase());
        } else {
          return item.toLowerCase().includes(searchVal.toLowerCase());
        }
      });
    } else {
      return [];
    }
  };

  return (  
    <div style={customStyle?.rootStyle ? customStyle?.rootStyle : {position: 'relative', width: '21rem'}} className={classes.groupInput} 
      onMouseUp={()=>
        textFieldRef.current.focus()}
      onMouseOver={()=>{
        isPointerOnList.current = true;
      }}
      onMouseLeave={()=> isPointerOnList.current = false}>
      <CommonTooltip 
        arrow placement='bottom' 
        title={isDisabled? disabledMessage ? disabledMessage : 'Internal Domain cannot have Business Unit': ''}>
        <TextField
          ref={textFieldRef}
          style={{width: '95%', opacity: isDisabled? '0.3' : '1'}}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{shrink: true}}
          disabled={isDisabled}
          value={searchVal}
          variant="outlined"
          onChange = {(e)=>{
            setSearchVal(e.target.value);
            setOpenList(true); // to show on every type
          }}
          onClick={()=>{
            if (!openList && !isDisabled) {
              setOpenList(true); // to show on focus
            }
          }}
          onBlur={()=>{
            if (!isPointerOnList.current) {
              setOpenList(false);
            }
            if (itemLabel && selectedItem[itemLabel] && searchVal !== selectedItem[itemLabel]) {
              setSearchVal(selectedItem[itemLabel]);
            } else if (selectedItem && typeof(selectedItem) === 'string' && searchVal !== selectedItem) {
              setSearchVal(selectedItem);
            }
          }}
        />
      </CommonTooltip>
      {(searchVal && searchVal.length) && <div style={customStyle?.closeIconStyle ? customStyle?.closeIconStyle : {position: 'absolute', top: '0.6rem', right: '2.6rem'}}>
        <CommonTooltip arrow placement='top' title="Clear Search" >
          <img style ={{cursor: 'pointer', opacity: '0.6'}} 
            src={closeIcon} alt='X' onClick={()=>{
              if (!isDisabled) {
                setSearchVal('');
              }
            }}/>
        </CommonTooltip>
      </div>}
      <img 
        style={
          customStyle?.downArroyStyle ? {...customStyle?.downArroyStyle, transform: openList ? 'rotate(180deg)' : ''} : 
            {position: 'absolute', top: '0.6rem', right: '1.5rem', cursor: 'pointer', transform: openList ? 'rotate(180deg)' : ''}
        } 
        src={Arrow} alt='NA' onMouseUp={()=>{
          setOpenList(!openList);
        }}/>
      {openList && <Dropdown2 dropdownProperty={customStyle?.dropdownProperty} userType='group' onSelect={onSelect} itemLabel={itemLabel}
        list={(searchVal && searchVal.length && !(selectedItem === searchVal || selectedItem[itemLabel] === searchVal))?filterDropDownList():dropDownList} setOpenList={setOpenList} loader={loader}/>}
    </div>
  );
};

export default SelectionListComponent;
