/*
 Store with initial state
*/
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer/rootReducer';
import Helper from './components/signIn/helper';
const {checkToken} = Helper;
const initUser = checkToken();
const middleWare = [thunk];

/* const devTools =
 process.env.NODE_ENV === 'development' ?
   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
 window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() :
   null;
 */
const storeCreate = createStore(
  rootReducer,
  {userConfig: initUser},
  compose(applyMiddleware(...middleWare)),
);

export default function configureStore() {
  return storeCreate;
}
