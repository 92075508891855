import styled from 'styled-components';

const Loader = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  display: flex;
  justify-content: center;
  text-align: ${props => (props.textAlign ? props.textAlign : '')};
  align-items: center;
`;

export default Loader;
