/*
  Combine all reducesrs to index reducer
*/
import {combineReducers} from 'redux';
import userConfig from './userConfig';
import documentConfig from './documentConfig';
import footerConfig from './footerConfig';
import alertConfig from './alertConfig';
import loaderConfig from './loader';
import globalConfig from './globalConfig';
import featureFlag from './featureFlag';
import impersonationLoaderConfig from './impersonationLoader';
import vpnPrompt from './vpnPrompt';

export default combineReducers({
  userConfig,
  documentConfig,
  footerConfig,
  alertConfig,
  loaderConfig,
  globalConfig,
  featureFlag,
  impersonationLoaderConfig,
  vpnPrompt
});
