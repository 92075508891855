export default (state = {}, action) => {
  switch (action.type) {
  case 'DOCUMENT_CONFIG':

    return {...action.payload};

  default:

    return state;
  }
};

  
