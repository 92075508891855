export default (state={loader: false, user: false, message: ''}, action) => {
  switch (action.type) {
  case 'IMPERSONATION_LOADER_CONFIG':
    
  
    return action.payload;
    
  
  default:
    
  
    return state;
  }
};
