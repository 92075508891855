

export default (state = {}, action) => {
  switch (action.type) {
  case 'ALERT_CONFIG':

    return {...action.payload};

  default:

    return state;
  }
};

