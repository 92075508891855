import styled from 'styled-components';
import theme from '../../../config/theme';
import {cssVar} from '../../../utils/utils';
/* eslint-disable max-len */

export const Heading = styled.div`
.wrapped {
 word-break: break-all;
 white-space: -webkit-nowrap;
 display: -webkit-box;
 -webkit-line-clamp: 1;
 -webkit-box-orient: vertical;
 overflow: hidden;
}
 `;

export const CloseIcon = styled.div`
display: flex;
justify-content: flex-end;
cursor: pointer;
margin-right: -1rem;
position: absolute;
right: 2rem;
`;

export const CloseIconContainer = styled.div`
display: flex;
cursor: pointer;
padding: 0.7rem;
position: absolute;
right: 0.25rem;
top: 0.25rem;
:hover {
  border-radius: 50%;
  background: #F5F5F5;
}
`;

export const SearchEmail = styled.div`
display: flex;
margin: 2rem 0;
`;

export const SectionUsers = styled.div`
height: 28vh;
overflow: auto;
padding: 0 0.5rem 0 0;
 
::-webkit-scrollbar {
  width: 0.3125rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
`;

export const InputContainer = styled.div`
position: relative;
width: 100%;
`;

export const GroupRoleAndRemoveWrapper = styled.div`
display: flex;
justify-content: flex-end; 
width: 35%;
`;

export const EmailInput = styled.input`
border: 0.0625rem solid #9b9b9b;
height: 2.3rem;
width: 100%;
border-radius: 0;
outline: none;
padding: 0 2rem 0 1rem;
font-size: 1rem;
font-weight: 500;
box-sizing: border-box;
font-family: ${theme.fonts.primary};
::placeholder: {
  font-family: ${theme.fonts.primary};
};
:focus {
  border: 0.0625rem solid ${cssVar('--tmo-magenta')};
}
`;

export const IndividualUsers = styled.div`
.scrollbox {
  background: /* Shadow covers */
  linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
  radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2),rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background: /* Shadow covers */
  linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
  radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 2.5rem, 100% 2.5rem, 100% 0.625rem, 100% 0.625rem;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}
`;

export const GroupUsers = styled.div`
margin-top: 2rem;
`;

export const ButtonsContainer = styled.div`
display: flex;
justify-content: flex-end;
padding-top: 1rem;
`;
