import {axiosPrivate, axiosPublic} from '../interceptors';
import reactConfig from '../../config/reactConfig';
import getBaseURL from './getBaseURL';

const baseURl = getBaseURL();

export const createLogAPI = data => {
  const url = '/devportal/v3/log';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

const serialize = (obj) =>{
  const str = [];
  // eslint-disable-next-line guard-for-in
  for (const p in obj) {
    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
  }
  return str.join('&');
};

// export const getLogAPI = query => {
//   const url = `/devportal/v3/log?${query?serialize(query):''}`;
//   return axiosPrivate.request({url: url, method: 'GET'});
// };

export const getLogAPI = data => {
  const url = '/devportal-admin/v3/log/search';
  return axiosPrivate.request({url: url, method: 'POST', data: data});
};

export const getEmailData = data => {
  const url = '/devportal-admin/v3/email/search';
  return axiosPrivate.request({url: url, method: 'POST', data: data});
};
