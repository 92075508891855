import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {connect} from 'react-redux';

import {DocumentConfig} from '../../actions/documentConfigAction';
import Grow from '@material-ui/core/Grow';
import {deleteDocuments, updateDocuments} from '../../api/services/documents';
import Paper from '@material-ui/core/Paper';

import Popper from '@material-ui/core/Popper';

import MenuItem from '@material-ui/core/MenuItem';

import MenuList from '@material-ui/core/MenuList';

import {makeStyles} from '@material-ui/core/styles';

import Arrow from '../../assets/images/grey_down_arrow.svg';

import styled from 'styled-components';
import {cssVar} from '../../utils/utils';

const useStyles = makeStyles((theme) => ({

  root: {

    display: 'flex',

  },

  paper: {

    marginRight: theme.spacing(2),

  },

}));

const Action = styled.img`



 

`;

const MenuOpen = styled.div`
cursor: pointer;
& .MuiMenuItem-root{
  padding-right: 2rem;
  font-family: TeleGroteskNext;
  font-weight: bold;
  color: #4b4d61;
   :hover {
    background-color: rgba(209,222,240,0.5);
   }
}

& .MuiPaper-elevation1{
  min-width: 7rem;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.15) !important;
}
`;

const RoleDropdown = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(props.value.role);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };


  const handleClose = (event, action) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e, value) => {
    if (value==='Remove') {
      if (props.userType==='group') {
        props.setGroupUsers([...(props.groupUsers).filter(v=>v!== props.value)]);
      } else {
        props.setIndividualUsers([...(props.individualUsers).filter(v=>v!== props.value)]);
      }
    } else {
      if (props.userType==='individual') {
        // setSelected(value);
        const arr = props.individualUsers;
        arr[props.index].role = value;
        props.setIndividualUsers([...arr]);
      }
    }
    handleClose();
  };

  // return focus to the button when we transitioned from !open -> open

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }


    prevOpen.current = open;
  }, [open]);

  const selectColor = (v) => {
    const user = props.individualUsers ? 'individualUsers' : 'groupUsers';
    if (props[user][props.index]?.role=== v) {
      return true;
    } else {
      return false;
    }
  };

  return (

    <MenuOpen className={classes.root}>

      <div>
        <div style={{display: 'flex', color: '#4b4d61'}}
          ref={anchorRef}

          aria-controls={open ? 'menu-list-grow' : undefined}
          onClick={handleToggle}
          aria-haspopup="true"
        >
          <div>
            {props.value.role && props.value.role.charAt(0).toUpperCase() + props.value.role.slice(1)}
          </div>
          <img style={{marginLeft: '0.5rem', transform: open ? 'rotate(180deg)' : ''}} src={Arrow} alt='NA' />
        </div>
     

        <Popper style={{zIndex: '2'}}
          open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='bottom-end'>

          {({TransitionProps, placement}) => (

            <Grow

              {...TransitionProps}

              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}

            >

              <Paper>
                <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">

                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    {props.roles.map((v, i)=>
                      <MenuItem key={i} onClick={(event)=>handleChange(event, v)}
                        style={{color: selectColor(v)? cssVar('--tmo-magenta') : ''}}
                      >{v.charAt(0).toUpperCase() + v.slice(1)}
                      </MenuItem>)
                    }

                  </MenuList>

                </ClickAwayListener>

              </Paper>

            </Grow>

          )}

        </Popper>

      </div>
    </MenuOpen>

  );
};
const mapStateToProps = ({userConfig, documentConfig}) => ({userConfig, documentConfig});

const mapDispatchToProps = dispatch => ({
  changeDocumentConfig: data => dispatch(DocumentConfig(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleDropdown);

