/* eslint-disable max-len */
import React, {useState, useEffect, useRef} from 'react';
import {TextareaAutosize, TextField} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import {StyledMaxWidthSection} from '../../../style';
import {StyledDiv} from '../../../style';
import * as theme from '../../../config/theme';
import Button from '../../shared/button';
import {connect} from 'react-redux';
import {
  StyledContactUsHeader,
  StyledTextAreaContainer,
  StyledCharLeftContainer,
  StyledContactUsContainer,
  StyledContactUsSubHeader,
  StyledTextFieldContainer,
  CaptchaContainer
} from '../style';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import Text from '../../shared/text';
import {submitContact} from '../../../api/services/users';
import Loader from 'react-loader-spinner';
import {Loadwrap} from '../../contactUs/form';
import Alerts from '../../utils/userAlert';
import './contact_us.css';
import {cssVar} from '../../../utils/utils';
const {SuccessAlert, ErrorAlert, CustomErrorAlert} = Alerts;

const ContactUsFooter = (props) => {
  const {
    userConfig,
    envConfig
  } = props;
  const recaptchaRef = useRef();
  const [recaptcha, setRecaptcha] = useState(null);
  const [status, setStatus] = useState(true);
  const [nameStatus, setNameStatus] = useState(true);
  const [descripStatus, setDescripStatus] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [loader, setLoader] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [maxWidth, setMaxWidth] = useState(theme.default.maxWidth);
  const [allValues, setAllValues] = useState({
    fname: '',
    user_id: '',
    descript: '',
  });
  const [isTestUser, setIsTestUser] = useState(false);

  const blurevent = (e) => {
    if (e.target?.value?.trim().length> 0 || e.length?.trim() >0) {
      typeof e === 'object' ?
        setStatus(isEmail(e.target.value.trim())) :
        e ?
          setStatus(isEmail(e.trim())) :
          setStatus(true);
    } else {
      setStatus(true);
    }
  };

  const blureventDesc = (e) => {
    if (e.target?.value?.trim().length> 0 || e.length?.trim() >0) {
      typeof e === 'object' ?
        setDescripStatus(
          isLength(e.target.value.trim() + '', {min: 1, max: 180})
        ) :
        e ?
          setDescripStatus(isLength(e.trim() + '', {min: 1, max: 180})) :
          setDescripStatus(true);
    } else {
      setDescripStatus(true);
    }
  };
  const blureventName = (e) => {
    if (e.target?.value?.trim().length> 0 || e.length?.trim() >0) {
      typeof e === 'object' ?
        setNameStatus(
          isLength(e.target.value.trim() + '', {min: 1, max: 50})
        ) :
        e ?
          setNameStatus(isLength(e.trim() + '', {min: 1, max: 50})) :
          setNameStatus(true);
    } else {
      setNameStatus(true);
    }
  };

  useEffect(() => {
    if ('user_id' in userConfig) {
      const nameArr = userConfig.name.split(',');
      
      setLoggedInUser({user_id: userConfig.user_id, name: nameArr[1] ? ( nameArr[1]+' '+nameArr[0]) :nameArr[0]});
      setAllValues({...allValues, 'fname': nameArr[1] ? ( nameArr[1]+' '+nameArr[0]) :nameArr[0], 'user_id': userConfig.user_id});
      if (userConfig.test) {
        setIsTestUser(true);
      } else {
        setIsTestUser(userConfig.role?.length===1 && userConfig?.role.includes('trusted_partner')); 
      }
    }
  }, [formReset, userConfig]);

  const onSubmit = async () => {
    if (!descripStatus || !status || !nameStatus || (!recaptcha && !userConfig?.user_id)) {
      return;
    }
    setLoader(true);
    const payload = {
      name: allValues.fname.trimStart(),
      email: allValues.user_id.trimStart(),
      description: allValues.descript.trimStart()
    };
    submitContact(payload).then((res)=>{
      setAllValues({
        fname: '',
        user_id: '',
        descript: '',
      });
      setFormReset(!formReset);
      recaptchaRef && recaptchaRef?.current?.reset();
      setRecaptcha(null);
      SuccessAlert('Your contact request has been sent successfully.');
      setLoader(false);
    }).catch((e)=>{
      console.log(e);
      CustomErrorAlert(e.response&&e.response.data.message);
      setLoader(false);
    });
  };

  const CheckEmpty = () => {
    if (allValues.fname.trimStart().length === 0 || allValues.user_id.trimStart().length === 0 || allValues.descript.trimStart().length === 0 || (!recaptcha && !userConfig?.user_id)) {
      return true;
    } else {
      return false;
    }
  };

  const getMaxWidth = () => {
    const max = theme.default.maxWidth();
    if (max !== maxWidth) {
      setMaxWidth(max);
    }
  };

  const onChange = async (value) => {
    setRecaptcha(value);
  };

  window.addEventListener('resize', getMaxWidth);

  return (
    <StyledMaxWidthSection maxWidth={maxWidth}>
      <StyledContactUsContainer className="contact_us_container" id="contactfooter">
        <StyledContactUsHeader>Get in touch with us.</StyledContactUsHeader>
        <StyledContactUsSubHeader>
          Let us know what you think of this Developer Portal as well as any
          suggestions or requests.
        </StyledContactUsSubHeader>
        <StyledDiv className="w-100">
          <StyledDiv className="d-flex align-items-stretch justify-content-between" >
            <StyledTextFieldContainer>
              <div style={{position: 'relative'}} className='contact_us'>
                <TextField
                  label="Name"
                  variant="outlined"
                  placeholder="Enter your name"
                  className="text_field"
                  style={{opacity: loggedInUser.name ? '0.5' : '1', margin: 0}}
                  value={loggedInUser.name ? loggedInUser.name : allValues.fname}
                  disabled={loggedInUser.name ? true : false}
                  inputProps={{maxLength: 50}}
                  onChange={(e)=>{
                    setAllValues({...allValues, 'fname': e.target.value});
                    blureventName(e);
                  }}
                />
                {!nameStatus || allValues?.fname?.length===50 ? (
                  <Text
                    color="error"
                    fontSize="font12px"
                    position="absolute"
                    top="3.8rem"
                    margin="0 0 0 0.8rem"
                  // padding="0 0 0 1.6rem"
                  >
                  Name is mandatory with max. 50 characters
                  </Text>
                ) : (
                  ''
                )}
              </div>
              <div style={{position: 'relative'}} className='contact_us'>
                <TextField
                  label={'Email'}
                  variant="outlined"
                  className="text_field"
                  placeholder='Enter your email'
                  style={{opacity: loggedInUser.user_id ? '0.5' : '1', margin: 0}}
                  value={loggedInUser.user_id ? loggedInUser.user_id : allValues.user_id}
                  disabled={loggedInUser.user_id ? true : false}
                  onChange={(e)=>{
                    setAllValues({...allValues, 'user_id': e.target.value});
                    // blurevent(e);
                  }}
                  onBlur={(e)=>{
                    blurevent(e);
                  }}
                />
                {!status ? (
                  <Text
                    color="error"
                    fontSize="font12px"
                    position="absolute"
                    top="3.8rem"
                    margin="0 0 0 0.8rem"
                  // padding="0 0 0 1.6rem"
                  >
                  Email is not valid
                  </Text>
                ) : (
                  ''
                )}
              </div>
            </StyledTextFieldContainer>
            <StyledTextAreaContainer className="d-flex flex-column contact_us" style={{position: 'relative'}}>
              <TextField
                minRows={6}
                multiline
                label="Message"
                variant="outlined"
                placeholder="Type your message here"
                className="text_field"
                style={{opacity: isTestUser ? '0.5' : '1', margin: 0}}
                disabled={isTestUser ? true : false}
                value={allValues.descript}
                inputProps={{maxLength: 180}}
                onChange={(e)=>{
                  setAllValues({...allValues, 'descript': e.target.value});
                  blureventDesc(e);
                }}
              />
              {!isTestUser ? !descripStatus ? (
                <Text
                  color="error"
                  fontSize="font12px"
                  position="absolute"
                  bottom="-1.3rem"
                  margin="0"
                // padding="0 0 0 1.6rem"
                >
                  Description is mandatory with max. 180 characters
                </Text>
              ) : (
                <StyledCharLeftContainer className='char_left'>
                  <span className='white'>{(180-allValues.descript.length)} </span>
                Characters Left
                </StyledCharLeftContainer>
              ) : <Text
                color="error"
                fontSize="font12px"
                position="absolute"
                bottom="-1.3rem"
                margin="0"
                // padding="0 0 0 1.6rem"
              >
              You don&apos;t have permission to perform this action.
              </Text>}
            </StyledTextAreaContainer>
          </StyledDiv>
          {!userConfig?.user_id && <CaptchaContainer>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={envConfig['RECAPTCHA_SITE_KEY']}
              onChange={onChange}
            />
          </CaptchaContainer>}
          
          <StyledDiv className="d-flex align-items-center justify-content-end button-container" style={{margin: '1rem 0 0 0'}}>
            {/* {loader ? <Loadwrap style={{margin: '0 auto'}}> */}
            {/* </Loadwrap> : */}
            <Button 
              hoverBgColor = {(nameStatus && status && descripStatus) && !CheckEmpty() && !isTestUser &&'#ba0060'}
              disabled={
                ((nameStatus && status && descripStatus) && !CheckEmpty()) && !isTestUser ?
                  false :
                  true
              }
              // event={
              //   (nameStatus && status && descripStatus) && !CheckEmpty() ? '' : 
              //     'none'}
              opacity='1'
              bgColor={
                (nameStatus && status && descripStatus) && !CheckEmpty() && !isTestUser ?
                  cssVar('--tmo-magenta') :
                  '#9b9b9b'}
              cursor = {
                (nameStatus && status && descripStatus) && !CheckEmpty() && !isTestUser ?
                  'pointer' :
                  'not-allowed'}
              onClick={onSubmit}
              padding='0.8rem 3.25rem'
            > 
              {!loader ? <div>Send</div> : <Loader style={{display: 'flex'}} type="ThreeDots" color="#fff" height={16} width={30}/>}
            </Button>
          </StyledDiv>
        </StyledDiv>
      </StyledContactUsContainer>
    </StyledMaxWidthSection>
  );
};


const mapStateToProps = ({userConfig}) => ({
  userConfig,
});

export default connect(mapStateToProps)(ContactUsFooter);
