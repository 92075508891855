import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import ContactUsFooter from './components/contact_us_footer';
import LinksFooter from './components/links_footer';
import NewsAndUpdateFooter from './components/newsAndUpdateFooter';
import {
  StyledContactUsFooterContainer,
  StyledLinksFooterContainer,
  StyledNewsFooterContainer,
} from './style';
import {UserConfig} from '../../actions/userConfigActon';
import {FooterConfig} from '../../actions/footerConfigAction';
import {useHistory, useLocation} from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import {withRouter} from 'react-router';
import {useIsAuthenticated} from '@azure/msal-react';
import {featureFlags} from '../../config/reactConfig';

const Footer = (props) => {
  const {loaderConfig, envConfig, featureFlag}=props;
  const isLoggedIn = useIsAuthenticated();
  const [showContactUs, setShowContactUs] = useState(false);
  const [isTestUser, setIsTestUser] = useState(false);
  const {footerConfig, userConfig} = props;
  const history = useHistory();
  const location = useLocation();
  const aTitle = `${props.versionTimestamp}`;

  useEffect(() => {
    if (userConfig.user_id) {
      if (userConfig.test) {
        setIsTestUser(true);
      } else {
        setIsTestUser(userConfig.role?.length===1 && userConfig?.role.includes('trusted_partner')); 
      }
    }
  }, [userConfig]);

  useEffect(() => {
    if (history.location.pathname === '/') {
      setShowContactUs(true);
    } else {
      setShowContactUs(false);
    }
  }, [location]);
  return (
    <React.Fragment>
      <>
        {isLoggedIn && showContactUs&& !loaderConfig &&userConfig&&userConfig.user_id&&(<StyledNewsFooterContainer>
          {featureFlag[featureFlags.FF_ENABLE_NEWS_MANAGEMENT] && <NewsAndUpdateFooter />}
        </StyledNewsFooterContainer>)
        }
        {/* {isLoggedIn && showContactUs && !loaderConfig && !isTestUser && <StyledContactUsFooterContainer>
          <ContactUsFooter envConfig={envConfig}/>
        </StyledContactUsFooterContainer>} */}
      </>
      {!footerConfig.footerHide && !loaderConfig ?(<StyledLinksFooterContainer>
        <LinksFooter/>
      </StyledLinksFooterContainer>): (
        ''
      )}
      <div style={{display: 'none'}} aria-hidden='true'>
        <span className='version-text'>Version&nbsp;<b id='version-number'>{props.version} </b>deployed on&nbsp;<b>{aTitle}</b></span>
        <span id='deploy-info'>{props.deployInfo}</span>
      </div>
    </React.Fragment>
  );
}
;

const mapStateToProps = (
  {
    footerConfig,
    userConfig,
    loaderConfig,
    featureFlag
  }
) => (
  {
    footerConfig,
    userConfig,
    loaderConfig,
    featureFlag
  }
);

const mapDispatchToProps = dispatch => (
  {
    change_user_config: () => dispatch(UserConfig()),
    change_footer_config: () => dispatch(FooterConfig()),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
