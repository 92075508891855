import {axiosPrivate, axiosPublic} from '../interceptors';
import reactConfig from '../../config/reactConfig';
import getBaseURL from './getBaseURL';

const baseURl = getBaseURL();

export const getAllBUList = () => {
  const url = '/devportal-admin/v3/business-units';
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const createBU = data => {
  const url = '/devportal-admin/v3/business-units';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

export const updateBUName = data => {
  const url = '/devportal-admin/v3/business-units';
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};


export const deleteBUName = buID => {
  const url = `/devportal-admin/v3/business-units/${encodeURIComponent(buID)}`;
  return axiosPrivate.request({url: url, method: 'DELETE'});
};

export const getAllBuLinkedItems = (buID) => {
  const url = `/devportal-admin/v3/business-units/get-all-items/${encodeURIComponent(buID)}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const updateBUDocs = (buID, data) => {
  const url = `/devportal-admin/v3/business-units/update-docs/${encodeURIComponent(buID)}`;
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};

export const updateBUTeam = (buID, data) => {
  const url = `/devportal-admin/v3/business-units/update-team/${encodeURIComponent(buID)}`;
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};

export const updateBUUserGroups = (buID, data) => {
  const url = `/devportal-admin/v3/business-units/update-user-group/${encodeURIComponent(buID)}`;
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};
