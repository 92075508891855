import React, {useEffect, useState} from 'react';
import {StyledDiv, StyledLogo, StyledMaxWidthSection} from './../../../style';
import * as theme from '../../../config/theme';
import {
  StyledLinksFooter,
  StyledCopyrightContainer,
  StyledLinks,
} from '../style';
import {useHistory} from 'react-router-dom';

const LinksFooter = () => {
  const history = useHistory();
  const [maxWidth, setMaxWidth] = useState(theme.default.maxWidth);

  const getMaxWidth = () => {
    const max = theme.default.maxWidth();
    if (max !== maxWidth) {
      setMaxWidth(max);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', getMaxWidth);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', getMaxWidth);
    };
  }, [maxWidth]);

  const navigateTo = (path) => {
    history.push(path);
  };

  const handleKeyPress = (e, path) => {
    if (e.key === 'Enter') {
      navigateTo(path);
    }
  };

  return (
    <StyledMaxWidthSection maxWidth={maxWidth} role='contentinfo' aria-label='Footer Links'>
      <StyledLinksFooter className="d-flex align-items-end justify-content-between">
        <StyledDiv className="d-flex flex-column justify-content-center align-items-center">
          <StyledDiv>
            <StyledLogo alt='T-mobile developer footer image'/>
          </StyledDiv>
          <StyledCopyrightContainer>
            Copyright {new Date().getFullYear()}. All Rights Reserved
          </StyledCopyrightContainer>
        </StyledDiv>
        <StyledDiv className="d-flex align-items-center justify-content-center">
          <StyledLinks
            onClick={() => navigateTo('/data-use-policy')}
            onKeyPress={(e) => handleKeyPress(e, '/data-use-policy')}
            tabIndex={0}
            role='link'
            aria-label='Navigate to Fraud Prevent Data Use Policy'
          >
            Fraud Prevent Data Use Policy
          </StyledLinks>
          <StyledLinks
            onClick={() => navigateTo('/terms-of-use')}
            onKeyPress={(e) => handleKeyPress(e, '/terms-of-use')}
            tabIndex={0}
            role='link'
            aria-label='Navigate to Terms & Conditions'
          >
            Terms & Conditions
          </StyledLinks>
        </StyledDiv>
      </StyledLinksFooter>
    </StyledMaxWidthSection>
  );
};

export default LinksFooter;
