import axios from 'axios';
import getBaseURL from './getBaseURL';
import {isValidUrl} from '../../utils/utils';
const baseURl = getBaseURL();
const getFeatureFlagValue = async (featureFlagName, idToken) => {
  const url = baseURl + `/app-info/feature-flag/${featureFlagName}`;
  if (!isValidUrl(url)) {
    throw new Error('Invalid URL');
  }
  try {
    return await axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'x-auth-originator': idToken,
      }
    });
  } catch (err) {
    console.error(err.message);
    throw new Error('could not load feature flag data');
  }
};

const getAllFeatureFlagValues = async idToken => {
  const url = baseURl + '/app-info/all-feature-flags';
  if (!isValidUrl(url)) {
    throw new Error('Invalid URL');
  }
  try {
    return await axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'x-auth-originator': idToken,
      }
    });
  } catch (err) {
    console.error(err.message);
    throw new Error('could not laod all-feature-flag data');
  }
};

export {getFeatureFlagValue, getAllFeatureFlagValues};
