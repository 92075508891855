import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Close from '../../assets/images/icon close.png';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import themes from '../../config/theme';
import {useHistory} from 'react-router';
import Button from '../shared/button';
import Text from '../shared/text';
import {cssVar} from '../../utils/utils';

const Heading = styled(Text)`
display: block;
text-align: center;
width: 32rem;
`;

const StyledDescription = styled(Text)`
display: block;
text-align: center;
font-size: ${themes.texts.font18px};
font-weight: ${themes.weight.medium};
line-height: 1.33;
letter-spacing: normal;
color: rgba(0, 0, 0, 0.6);
`;

const DialogContent = styled.div`
    padding: 2rem 3rem 1.5rem;
    max-width: 40.625rem;
    .wrapped {
      word-break: break-word;
    white-space: -webkit-nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    }
`;

const Closeicon = styled.div`
  display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: -2.8rem;
    position: absolute;
    right: 4rem;
    top: 1rem;
  `;

const CloseIconContainer = styled.div`
  display: flex;
      padding: 0.7rem;
  
  :hover {
        border-radius: 50%;
        background: #F5F5F5;
      }
  
  `;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
     margin: 3rem 0 1.5rem 0;
 `;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      // minHeight: '35vh',
      width: '43rem',
      maxHeight: 'none',
      borderRadius: '0'
    },
    '& .MuiInputBase-input': {
      'fontFamily': `${themes.fonts.primary}`,
      'fontSize': `${themes.texts.normalText}`,
      'fontWeight': '500',
      'color': '#000',
      'padding': '0',
      '&:disabled': {
        // cursor: 'not-allowed',
      },
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      'font-size': '0.875rem',
      'marginBottom': '0.625rem',
    },
    '& .WAMuiChipInput-inputRoot-43.WAMuiChipInput-outlined-46': {
      paddingTop: '0 !important',
    },
    '& .MuiChip-label': {
      fontFamily: `${themes.fonts.primary}`,
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    '& .WAMuiChipInput-chip-57': {
      borderRadius: '0',
      background: '#f2f4f8',
      margin: '0.5rem 0.5rem 0.5rem 0',
    },
    '& .MuiOutlinedInput-root': {
      'borderRadius': '0',
    },
    '& .MuiInputLabel-outlined': {
      color: '#000 !important',
      opacity: '0.3'
    },
  }
}));

const VpnPromptModal = ({openVpnPromptModal, onClose, modalLoader=false}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Dialog className={classes.root} style={{zIndex: '10001'}}
      onClose={onClose} open={openVpnPromptModal} maxWidth={false}>
      <DialogContent>
        <Closeicon onClick={onClose}>
          <CloseIconContainer>
            <img src={Close} alt='NA'/>
          </CloseIconContainer>
        </Closeicon>
        <Heading color='fifth' fontSize='subHeading' family='secondary' margin='0.5rem 1rem'>
          Access Denied
        </Heading>
        <StyledDescription color='fifth' fontSize='font18px' margin='0.3rem'>
            Please verify your VPN connection and refresh the page.
        </StyledDescription>
        <ButtonContainer>
          <Button onClick={onClose}
            width='12.25rem'
            margin='0 1rem 0 0' border={'0.0625rem solid ' + cssVar('--tmo-magenta')} 
            bgColor='#ba0060' color='#fff'
            padding='0.8rem 3.25rem'
            cursor={(modalLoader)? 'not-allowed' : 'pointer'}
            opacity={(modalLoader)? '0.3' : '1'}
            disabled={modalLoader}>
            OK
          </Button>
        </ButtonContainer>
      </DialogContent>
    </Dialog>
  );
};

export default VpnPromptModal;
