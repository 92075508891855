

export default (state = {}, action) => {
  switch (action.type) {
  case 'VPN_ALERT_CONFIG':
  
    return {...action.payload};
  
  default:
  
    return state;
  }
};
  
  
