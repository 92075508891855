/* eslint-disable max-len */
import React from 'react';
import {GroupUsers} from '../utils/ManageUserStyles';
import GroupSelectionComponent from '../components/GroupSelectionComponent';
import GroupUsersViewComponent from '../components/GroupUsersViewComponent';

function PartnerDomains(props) {
  const {
    closeIcon, Arrow, groupUsers, setGroupUsers, groupDropdownLoader, domainList, buNameList, getSliced, Avatar, StyledDropDown, dropdownArrowMagenta, User, GroupWrapper,
  } = props;
  return (
    <>
      <GroupUsers>
        <GroupSelectionComponent 
          groupUsers={groupUsers} 
          setGroupUsers={setGroupUsers} 
          loader={groupDropdownLoader}
          domainList={domainList}
          buNameList={buNameList}
          Arrow={Arrow}
          closeIcon={closeIcon}
        />
        <GroupUsersViewComponent
          groupUsers={groupUsers}
          setGroupUsers={setGroupUsers}
          GroupWrapper={GroupWrapper}
          User={User}
          StyledDropDown={StyledDropDown}
          dropdownArrowMagenta={dropdownArrowMagenta}
          Avatar={Avatar}
          getSliced={getSliced}
          closeIcon={closeIcon}
        />
      </GroupUsers>
    </>
  );
}

export default PartnerDomains;
