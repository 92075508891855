/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import SelectionListComponent from './SelectionListComponent';
import CommonTooltip from '../../shared/CommonTooltip';
import Button from '../../shared/button';

const GroupSelectionComponent = ({groupUsers, setGroupUsers, loader, domainList, buNameList, Arrow, closeIcon}) => {
  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedBuName, setSelectedBuName] = useState({name: ''});
  const [isAddingDisabled, setIsAddingDisabled] = useState(true);
  const [isCommonBUWarning, setIsCommonBUWarning] = useState(false);

  const isInternalDomain = selectedDomain.includes('t-mobile') || selectedDomain.includes('sprint');

  const isAddDisabled = () => {
    if (isCommonBUWarning) {
      setIsCommonBUWarning(false);
    }
    if (selectedDomain === '' && selectedBuName.name === '') {
      return true;
    } else if (selectedDomain !== '' && selectedBuName.name === '') {
      const existingGroup = groupUsers.find((group)=>group.domain === selectedDomain && !group.toBeRemoved);
      if (existingGroup) {
        return true;
      } else {
        return false;
      }
    } else if (selectedDomain === '' && selectedBuName.name !== '') {
      const existingGroup = groupUsers.find((group)=>group.domain === undefined && group.buName && (group.buName === selectedBuName._id || group.buName._id === selectedBuName._id));
      const existingBU = groupUsers.find((group)=> (group?.buName === selectedBuName?._id || group?.buName?._id === selectedBuName?._id) && group.domain !== undefined);
      if (existingBU) {
        setIsCommonBUWarning(true);
      } else {
        setIsCommonBUWarning(false);
      }
      if (existingGroup) {
        return true;
      } else {
        return false;
      }
    } else {
      const existingGroup = groupUsers.find((group)=>(group.domain === selectedDomain || group.domain === undefined) && group.buName && (group.buName === selectedBuName._id || group.buName._id === selectedBuName._id));
      if (existingGroup) {
        return true;
      } else {
        return false;
      }
    }
  };
  
  useEffect(()=>{
    setIsAddingDisabled(isAddDisabled());
  }, [selectedDomain, selectedBuName, groupUsers]);

  const createGroup = () => {
    if (isCommonBUWarning) {
      setIsCommonBUWarning(false);
    }
    const newGroup = {role: 'viewer'};
    if (selectedDomain) {
      newGroup.domain = selectedDomain;
    }
    if (selectedBuName && selectedBuName._id && !isInternalDomain) {
      newGroup.buName = selectedBuName;
    }
  
    let newGroupUsers = [...groupUsers];

    if (selectedDomain && selectedBuName.name === '') {
      newGroupUsers = newGroupUsers.filter((group)=> !(group.domain === selectedDomain && group.toBeRemoved));
    } else if (selectedBuName._id && selectedDomain === '') {
      newGroupUsers = newGroupUsers.filter((group)=>!(group?.buName?._id === selectedBuName._id && group?.domain !== undefined));
    } else if (selectedDomain && selectedBuName._id) {
      let onlyDomain;
      newGroupUsers = newGroupUsers.filter(group => {
        if (group.domain === selectedDomain && group.buName === undefined) {
          onlyDomain = {...group};
        }
        return !(group.domain === selectedDomain && group.buName === undefined);
      });
      if (onlyDomain) {
        onlyDomain.toBeRemoved = true;
        newGroupUsers.push(onlyDomain);
      }
    }
    
    setGroupUsers([...newGroupUsers, newGroup]);
    setSelectedBuName({name: ''});
    setSelectedDomain('');
  };

  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
      <SelectionListComponent 
        loader={loader}
        dropDownList={domainList}
        setSelectedItem={setSelectedDomain} 
        selectedItem={selectedDomain}
        label='Select Partner Domain'
        placeholder='Select partner domain'
        closeIcon={closeIcon}
        Arrow={Arrow}
      />
      <SelectionListComponent 
        loader={loader}
        dropDownList={buNameList}
        isDisabled={isInternalDomain}
        itemLabel = 'name'
        setSelectedItem={setSelectedBuName} 
        selectedItem={selectedBuName}
        label='Select Business Unit'
        placeholder='Select business unit'
        closeIcon={closeIcon}
        Arrow={Arrow}
      />
      <CommonTooltip arrow placement='top' title={(isCommonBUWarning)? 
        'Granting access to this Business Unit would remove the existing access for Domains associated with this Business Unit': ''}>
        <Button 
          width= '7rem'
          height= '2.4rem'
          display= 'flex'
          style={{justifyContent: 'center', alignItems: 'center'}}
          hoverBgColor='#ba0060'
          onClick={createGroup}
          disabled={isAddingDisabled}
          opacity={isAddingDisabled ? '0.5' : '1'}
        >
          <div style={{alignItems: 'center'}}>Add</div>
        </Button>
      </CommonTooltip>
    </div>
  );
};

export default GroupSelectionComponent;
