import axios from 'axios';
import Alerts from '../../components/utils/userAlert';
import configureStore from '../../store';
import {ImpersonationLoaderConfig} from '../../actions/impersonationLoaderConfig';
import reactConfig from '../../config/reactConfig';

const baseUrl = reactConfig.baseUrl;

const {CustomErrorAlert} = Alerts;
const store = configureStore();

// const { REACT_APP_API_NODE_ENV } = process.env;

// const env = REACT_APP_API_NODE_ENV;


// create axios instence
const authInterceptedAPI = axios.create();

// middleware before sending any requests
authInterceptedAPI.interceptors.request.use(async config => {
  try {
    const axiosConfig = {...config};
   
    try {
      const {idToken, accessToken} = JSON.parse(localStorage.getItem('token-storage'));
      axiosConfig.headers['Authorization'] = `Bearer ${accessToken}`;
      axiosConfig.headers['X-Auth-Originator'] = idToken;
    } catch (error) {
      console.log('unable to get the token values :', error.message);
    }
    const adminBaseUrl = store.getState()?.globalConfig?.ADMIN_BASE_URL;
    if (axiosConfig.url.includes('/devportal-admin/v3/')) {
      // Requests with paths starting with '/admin' will use the admin base URL
      axiosConfig.baseURL = adminBaseUrl;
    } else {
      if (window?.location?.origin?.includes('localhost')) {
        axiosConfig.baseURL = baseUrl;
      } else {
        axiosConfig.baseURL = window?.location?.origin; 
      }
    }
    return axiosConfig;
  } catch (err) {
    console.error('Api Error:', err.message);
    return config;
  }
});

// middleware before sending any response back to axios call
authInterceptedAPI.interceptors.response.use(
  function(response) {
    if (response.data&&(response.data.token||response.data.token!==undefined)) {
      const tokenStore = JSON.parse(localStorage.getItem('token-store'));
      tokenStore['accessToken']['accessToken']=response.data.token;
      console.log('Response', response.data.token, tokenStore);
      localStorage.setItem('token-store', JSON.stringify(tokenStore));
    }

    const reduxData = store?.getState();
    if (response?.headers?.['user-id'] && reduxData?.userConfig?.user_id) {
      const responseUser_id = response?.headers?.['user-id'];
      const userData = reduxData?.userConfig;
      const impersonationLoader = reduxData?.impersonationLoaderConfig;
      if ((userData?.user_id && responseUser_id) &&
        userData?.user_id?.toLowerCase() !== responseUser_id?.toLowerCase() &&
        impersonationLoader?.loader === false) {
        if (userData?.impersonator && userData?.impersonator?.user_id === responseUser_id) {
          store.dispatch(ImpersonationLoaderConfig({loader: true, user: true, 
            message: 'Taking you back to your dashboard as your current Impersonation session has been stopped by the admin.', 
            path: '/access_control'}));
        } else {
          store.dispatch(ImpersonationLoaderConfig({loader: true, user: true, 
            message: 'Impersonation is started in your Account. Switching profile to the selected user', 
            path: '/'}));
        }
      }
    }
    return response;
  },
  function(err) {
    if (err.message === 'Network Error') {
      if (!store.getState().vpnPrompt.status) {
        store.dispatch({
          type: 'VPN_ALERT_CONFIG',
          payload: {message: err.message, status: true}, 
        });
      }
    }
    if (err && err.response && err.response.data&& err.response.data.message &&
      // eslint-disable-next-line max-len
      ['Unauthorized',
        'Unauthorized!',
        'not authorized',
        'Jwt is expired',
        'jwt expired',
        'Error: "NO Access Token provided!"',
        'No User Found with your User ID',
        'User blocked! Please Contact Admin',
        'Token Expired!',
        'Unauthorized Access!'].includes(err.response.data.message) &&
        [401, 403, 404].includes(err.response.status)) {
      CustomErrorAlert(err.response&&err.response&&err.response.data.message);
      let timeout;
      let reloadPage = false;
      if (err && err.response && err.response.data&& 
        // eslint-disable-next-line max-len
        [
          'not authorized',
          'Jwt is expired',
          'jwt expired',
          'Error: "NO Access Token provided!"',
          'Token Expired!'].includes(err.response&&err.response&&err.response.data.message)) {
        timeout='timeout';
        reloadPage = true;
      } else {
        timeout= '';
      }
      // CustomErrorAlert('Session Timed Out!');
      if (reloadPage) {
        setTimeout(()=>{
          localStorage.removeItem('id');
          window.location.reload();
        }, 2000);
      }
    }

    if (err && err.response && err.response.data && err.response.data.message &&
      ['No User found with impersonation User ID',
        'User blocked! cannot impersonate',
        'Access Denied to impersonate'
      ].includes(err.response.data.message) && [401, 403, 404].includes(err.response.status)) {
      CustomErrorAlert(err.response.data.message);
      setTimeout(()=>{
        store.dispatch(ImpersonationLoaderConfig({loader: true, user: true, message: 'Taking you back to your account', path: '/'}));
      }, 3000);
    }

    const reduxData = store?.getState();
    if (err?.response?.headers?.['user-id'] && reduxData?.userConfig?.user_id) {
      const responseUser_id = err?.response?.headers?.['user-id'];
      const userData = reduxData?.userConfig;
      const impersonationLoader = reduxData?.impersonationLoaderConfig;
      if ((userData?.user_id && responseUser_id) &&
        userData?.user_id?.toLowerCase() !== responseUser_id?.toLowerCase() &&
        impersonationLoader?.loader === false) {
        if (userData?.impersonator && userData?.impersonator?.user_id === responseUser_id) {
          store.dispatch(ImpersonationLoaderConfig({loader: true, user: true, 
            message: 'Taking you back to your dashboard as your current Impersonation session has been stopped by the admin.', 
            path: '/access_control'}));
        } else {
          store.dispatch(ImpersonationLoaderConfig({loader: true, user: true, 
            message: 'Impersonation is started in your Account. Switching profile to the selected user', path: '/'}));
        }
      }
    }

    return Promise.reject(err);
  },
);

export default authInterceptedAPI;
