
import {loginRequest} from '../../authConfig';


export const handleLogin = (loginType, instance) => {
  if (loginType === 'popup') {
    instance.loginPopup(loginRequest).catch(e => {
      console.log(e);
    });
  } else if (loginType === 'redirect') {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e);
    });
  }
};
export let oktaAuth={};
export const setOktaAuthValue = (oktaAuthValue) => {
  oktaAuth = oktaAuthValue;
};


export const SignOut = (instance) => {
  instance?.logoutRedirect();
  localStorage.clear();
};


export const SignIn = () => {
  const path = localStorage.getItem('pathname');
  if (oktaAuth.getOriginalUri()==='/' || oktaAuth.getOriginalUri()===window.location.origin) {
    oktaAuth.setOriginalUri(path?path:'/');
  }
  oktaAuth.signInWithRedirect();
  if (path) {
    localStorage.removeItem('pathname');
  }  
};

const Auth = () => {
  oktaAuth.signInWithRedirect();
};

export default Auth;
