/* eslint-disable max-len */
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import CommonTooltip from '../../shared/CommonTooltip';
import {connect} from 'react-redux';
import {cssVar} from '../../../utils/utils';

const Loadwrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
`;

const EmailsContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: ${(props) => props.dropdownProperty ? props.dropdownProperty.top : '2.5rem'};
  overflow-x: hidden;
  overflow-y: auto;
  width: 95%;
  min-height: ${(props) => props.dropdownProperty ? props.dropdownProperty.minHeight : '10rem'};
  max-height: ${(props) => props.dropdownProperty ? props.dropdownProperty.maxHeight : '15rem'};
  box-shadow: 0 0.0625rem 0.625rem 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  ::-webkit-scrollbar {
    width: 0.3125rem;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;

const EmailList = styled.ul`
padding: 1rem 0 0 0;
    margin: 0;
`;

const Items = styled.li`
display: flex;
justify-content: space-between;
padding: 0.5rem 1rem;
cursor: pointer;

:hover {
  background-color: rgba(209, 222, 240, 0.5);
}
`;

const Add = styled.div`
color: ${()=> cssVar('--tmo-magenta')};
`;

const EmailItem = styled.div`
color: #4b4d61;
overflow: hidden;
word-wrap: break-word;
`;

const NoData = styled.div`
display: flex;
    justify-content: center;
    margin: 3rem 1rem;
    text-align: center;
`;

const getSliced = (text, length) => {
  if (text && (text?.length > length)) {
    return text?.slice(0, length).concat('...');
  } else {
    return text || '';
  }
};

const Dropdown2 = ({loader, list, onSelect, itemLabel, dropdownProperty}) => {
  return (
    <EmailsContainer dropdownProperty={dropdownProperty}>
      {loader ? <Loadwrap>
        <Loader type="ThreeDots" color={cssVar('--tmo-magenta')} height={40} width={40} />
      </Loadwrap> :
        <EmailList>
          {list && list.length>0 ? list.map((item, index)=>{
            const name = (typeof(item) === 'object') ? item[itemLabel] : item;
            return (
              <Items key={index} onClick={()=>onSelect(item)}>
                <EmailItem>
                  <CommonTooltip arrow placement={(itemLabel)?'left':'right'} title={name.length > 20?name:''}>
                    {name.length > 35 ? getSliced(name, 32): name}
                  </CommonTooltip>
                </EmailItem>
              </Items>
            );
          }) : <NoData>No Results Found. Please try with a different keyword.</NoData>}
        </EmailList>}
    </EmailsContainer>
  );
};

const mapStateToProps = ({userConfig}) => ({userConfig});

export default connect(
  mapStateToProps,
)(Dropdown2);
