/* eslint-disable max-len */
import React, {useState} from 'react';
import Text from '../../shared/text';
import CommonTooltip from '../../shared/CommonTooltip';
import Button from '../../shared/button';
import {GroupRoleAndRemoveWrapper} from '../utils/ManageUserStyles';
import {cssVar} from '../../../utils/utils';

const GroupView = ({modifiedGroup, removeGroup, GroupWrapper, User, StyledDropDown, dropdownArrowMagenta, Avatar, getSliced, closeIcon, isChild=false}) => {
  const [isChildsOpen, setIsChildsOpen] = useState(true);

  const avatarText = isChild?'BU':
    (modifiedGroup?.domain)?
      modifiedGroup?.domain[0]?.toUpperCase():
      'BU';

  const groupName = isChild?
    (modifiedGroup?.buName?._id)?
      modifiedGroup?.buName?.name:
      (modifiedGroup?.domain)?
        modifiedGroup?.domain:
        '':
    (modifiedGroup?.domain)?
      modifiedGroup?.domain:
      (modifiedGroup?.buName?._id)?
        modifiedGroup?.buName?.name :
        '';

  const removeItem = (groupToBeRemoved) => {
    removeGroup(groupToBeRemoved);
  };

  return (
    <GroupWrapper 
      {...(modifiedGroup?.children?.length && isChildsOpen) && {height: ((modifiedGroup?.children?.length+1)*3.25)+'rem'}}
      {...(isChild) && {sideBorders: '0.0625rem solid #c7c8d1'}}>

      <User 
        {...(modifiedGroup?.children?.length && isChildsOpen) && {backgroundColor: '#f1f1f1'}}>

        <StyledDropDown 
          {...(!modifiedGroup?.children?.length) && {visibility: 'hidden'}}
          {...(!isChildsOpen) && {transform: 'rotate(-90deg)'}}
          src={dropdownArrowMagenta} alt='NA' onMouseUp={()=>{
            setIsChildsOpen(!isChildsOpen);
          }}/>

        <Avatar
          {...(avatarText === 'BU') && {backgroundColor: cssVar('--tmo-magenta')}}
          marginLeft={(isChild)?'4rem':'1rem'}>
          {avatarText}
        </Avatar>
        
        <Text color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0 1rem 1rem' width= {(isChild)?'calc(50% - 4.5rem)':'50%'}>
          <CommonTooltip arrow placement='right' title={groupName?.length > 20?groupName:''}>
            {getSliced(groupName, 20)}
          </CommonTooltip>
        </Text>

        <Text color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0 1rem 1rem' width= '40%' {...(!modifiedGroup?.children?.length && {borderRight: '0.0625rem solid #c7c8d1'})}>
          {(modifiedGroup?.domain?.includes('piktorlabs')) ? 'Internal User' :
            (modifiedGroup?.domain && (modifiedGroup?.domain?.includes('t-mobile') || modifiedGroup?.domain?.includes('sprint'))) ? 'Internal Group User' :
              'External Group User'
          }
        </Text>

        <GroupRoleAndRemoveWrapper>
          {(!modifiedGroup?.children?.length) ? 
            <>
              <Text color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0' width= '40%' >Viewer</Text>
              <Button bgColor='transparent' width= '1rem' display='flex' color='#000' opacity='0.8' style={{justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                <img
                  onClick={()=>{
                    removeItem(modifiedGroup);
                  }}
                  src={closeIcon}
                  alt="close-icon"
                  className=""
                />
              </Button>
            </>:
            ''}
        </GroupRoleAndRemoveWrapper>
      </User>

      {modifiedGroup.children.map((modifiedChild, index)=>
        <GroupView 
          key={index}
          modifiedGroup={modifiedChild}
          removeGroup={removeGroup}
          GroupWrapper={GroupWrapper}
          User={User}
          StyledDropDown={StyledDropDown}
          dropdownArrowMagenta={dropdownArrowMagenta}
          Avatar={Avatar}
          getSliced={getSliced}
          closeIcon={closeIcon}
          isChild={true}/>)}
    </GroupWrapper>
  );
};

export default GroupView;
