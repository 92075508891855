/* eslint-disable max-len */
import React from 'react';
import Dropdown from '../../shared/dropdown';
import Button from '../../shared/button';
import Text from '../../shared/text';
import RoleDropdown from '../../shared/role-dropdown';
import {IndividualUsers, SearchEmail, InputContainer, EmailInput, SectionUsers} from '../utils/ManageUserStyles';

function IndividualUser(props) {
  const {
    isPointerOnList, emailInputRef, setSearchVal, timerFunction, setOpenEmails, searchVal, onSearch, clearSearch, closeIcon, openEmails, individualUsers, setIndividualUsers, dropdownLoader, emails, User, Avatar, userConfig, individuals, individualsRestricted,
  } = props;
  console.log('individualUsers >>', individualUsers);
  return (
    <>
      <IndividualUsers>
        <SearchEmail>
          <InputContainer onMouseOver={()=>isPointerOnList.current = true}
            onMouseLeave={()=>isPointerOnList.current = false}>
            <EmailInput 
              ref={emailInputRef}
              onChange = {(e)=>{
                setSearchVal(e.target.value);
                timerFunction(e.target.value);
              }}
              onBlur= {(e)=>{
                if (!isPointerOnList.current) {
                  setOpenEmails(false);
                } else {
                  e.target.focus();
                }
              }}
              placeholder='Search Email ID or User ID'
              type='text'
              value={searchVal}
              onKeyUp={(e)=>{
                if (searchVal.length>2 && e.keyCode===13) {
                  onSearch(searchVal);
                  setOpenEmails(true);
                }
              }}
            />
            {searchVal !== '' &&
                <img
                  onClick={(e)=>{
                    clearSearch(e);
                  }}
                  src={closeIcon}
                  alt="close-icon"
                  className="close-icon transition"
                />
            }
            {/* <div style={{position: 'absolute', top: '0.6rem', right: '1rem', display: 'flex'}}>
                Viewer
                <img style={{marginLeft: '0.2rem'}} src={Arrow} alt='NA' />
              </div> */}
            {openEmails && <Dropdown individualUsers={individualUsers} setIndividualUsers={setIndividualUsers} 
              list={emails} setOpenEmails={setOpenEmails} userType='individual' loader={dropdownLoader}/>}
          </InputContainer>
          <Button
            margin='0 0 0 1rem'
            padding='0.65rem 2.5rem'
            disabled={searchVal.length<3}
            opacity={searchVal.length<3 ? '0.5' : '1'}
            cursor={searchVal.length<3 ? 'not-allowed' : ''}
            hoverBgColor='#ba0060'
            onClick={()=>{
              onSearch(searchVal);
              setOpenEmails(true);
              if (emailInputRef.current) {
                emailInputRef.current.focus();
              }
            }}
          >
              Search
          </Button>
        </SearchEmail>
        <Text color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0 0.5rem 0' weight='bold' family='secondary'>
            INDIVIDUAL USERS ({individualUsers?.length})
        </Text>
        <SectionUsers className='scrollbox'>
          {individualUsers?.length>0 && individualUsers.map((v, i)=><User key={i}>
            <Avatar>
              {(v?.user?.name[0])?.toUpperCase()}
            </Avatar>
            <Text display='block' color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0 1rem 1rem' width= '35%'
              whiteSpace='nowrap' style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
            >
              {v.user.name}
            </Text>
            <Text display='block' color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0' width= '45%'
              whiteSpace='nowrap' style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{v.user.user_id}</Text>
            {v.role === 'owner' ? 
              <Text 
                color='#000' opacity='0.8' fontSize='formLabel' margin='1rem 0' width= '20%' justifyContent= 'flex-end'>Owner</Text> :
              <div style={{display: 'flex', justifyContent: 'flex-end', width: '20%',
                opacity: (userConfig?.user_id.toLowerCase()===v?.user?.user_id.toLowerCase()) ? '0.5' : '1',
                pointerEvents: (userConfig?.user_id.toLowerCase()===v?.user?.user_id.toLowerCase()) ? 'none' : '',
              }}>
                <RoleDropdown
                  userType='individual'
                  individualUsers={individualUsers}
                  value={v}
                  index={i}
                  setIndividualUsers={setIndividualUsers} 
                  roles={(v.user.role.length===1 && v.user.role.includes('trusted_partner')) || 
                    (((!(/@t-mobile.com\s*$/i.test(v.user.user_id)) && !(/@sprint.com\s*$/i.test(v.user.user_id)))) && !v.user.test)? 
                    individualsRestricted : individuals}/>
              </div>
            }
                
          </User>)}
        </SectionUsers>
      </IndividualUsers>
    </>
  );
}

export default IndividualUser;
