import reactConfig from '../../config/reactConfig';

const baseURl = reactConfig.baseUrl;

const getBaseURL = () => {
  if (window?.location?.origin?.includes('localhost')) {
    return baseURl;
  } else {
    return window?.location?.origin; 
  }
};

export default getBaseURL;
