import React from 'react';
import styled from 'styled-components';
import DevPortalHeaderLogo from '../../assets/images/devportal_header_logo.svg';
import Laptop from '../../assets/images/laptop.svg';
import Desktop from '../../assets/images/desktop.svg';
import Text from '../shared/text';
 
const Wrapper = styled.div`
height: 100vh;
display: flex;
align-items: center;
`;

const HeaderLogo = styled.img`
width: 12.5rem;
    margin-bottom: 4rem;
`;
const DeviceWrapper = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
margin-top: 4rem;
@media (max-width: 26.625rem) {
    width: 80%;
  }
`;
const ShadowWrapper = styled.div`
width: 60%;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 3rem;
    @media (max-width: 48rem) {
        width: 75%;
      }
    @media (max-width: 26.625rem) {
        width: 80%;
        padding: 4rem 1rem;
      }
`;
const DeviceContainer = styled.div`
border-radius: 0.3125rem;
border: solid 0.0625rem rgba(113,117,139, 0.3);
padding: 1.5rem 3.5rem;
width: 18%;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;

@media (max-width: 26.625rem) {
    padding: 0.8rem 1.5rem;
  }
`;
const HeadingText = styled(Text)`
@media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 26.625rem) {
    font-size: 1.5rem;
  }
`;

const DeviceText = styled(Text)`
@media (max-width: 26.625rem) {
    font-size: 0.75rem;
  }
`;

const OrText = styled(Text)`
    @media (max-width: 26.625rem) {
        font-size: 0.875rem;
      }
`;

const ResponsiveScreen = () => {
  return (
    <Wrapper>
      <ShadowWrapper>
        <HeaderLogo src={DevPortalHeaderLogo} alt="logo" />
        <HeadingText
          color='#4B4D61'
          fontSize='heading'
          family='secondary'
          textAlign='center'
        >Please switch to the below device for a better experience</HeadingText>
        <DeviceWrapper>
          <DeviceContainer>
            <img src={Laptop} alt='laptop' style={{width: '100%'}}/>
            <DeviceText
              color='#4B4D61'
              family='secondary'>Laptop</DeviceText>
          </DeviceContainer>
          <OrText color='#4B4D61' alignItems="center" family='secondary'>OR</OrText>
          <DeviceContainer>
            <img src={Desktop} alt='desktop' style={{width: '100%', height: '70%'}}/>
            <DeviceText
              color='#4B4D61'
              family='secondary'>Desktop</DeviceText>
          </DeviceContainer>
        </DeviceWrapper>
      </ShadowWrapper>
    </Wrapper>
  );
};

export default ResponsiveScreen;
