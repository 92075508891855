import React, {useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {connect} from 'react-redux';

import {DocumentConfig} from '../../actions/documentConfigAction';
import Grow from '@material-ui/core/Grow';
import {deleteDocuments, updateDocuments} from '../../api/services/documents';
import Paper from '@material-ui/core/Paper';

import Popper from '@material-ui/core/Popper';

import MenuItem from '@material-ui/core/MenuItem';

import MenuList from '@material-ui/core/MenuList';

import {makeStyles} from '@material-ui/core/styles';

import action from '../../assets/images/action.svg';

import styled from 'styled-components';

import {useHistory} from 'react-router-dom';
import Dashboard from '../../assets/images/account_dropdown/admin.png';
import Profile from '../../assets/images/account_dropdown/profile.png';
import Contact from '../../assets/images/account_dropdown/contact.png';
import Logout from '../../assets/images/account_dropdown/logout.png';
import ManageUserModal from '../ManageUserModal';
import ModalDialog from './modal';
import Alerts from '../utils/userAlert';
import CommonTooltip from '../shared/CommonTooltip';

import dropdownArrowDark from '../../assets/images/dropdownArrowDark.svg';
import dropdownArrowMagenta from '../../assets/images/dropdownArrowMagenta.svg';
import {featureFlags} from '../../config/reactConfig';

const {SuccessAlert, ErrorAlert, CustomErrorAlert} = Alerts;

const useStyles = makeStyles((theme) => ({

  root: {

    display: 'flex',

  },

  paper: {

    marginRight: theme.spacing(2),

  },

}));

const Action = styled.img`



 

`;

const MenuOpen = styled.div`
z-index: 1;
.buttonStyle {
  :focus-visible {
    outline: none;
  }
}
& .MuiMenuItem-root{
  font-family: TeleGroteskNext;
  padding-right: 2rem;
  font-weight: bold;
   :hover {
    background-color: rgba(209, 222, 240 ,0.3);
   }
}
`;


const Dropdown = styled.div``;

const MenuListComposition = (props) => {
  const {
    item, title, Id, setUpdate, collaborators, documentConfig,
    changeDocumentConfig, tabname,
    userConfig, userStatus, updateList, featureFlag, globalConfig, handleSignOut} = props;
  const {role, buName} = userConfig;
  const classes = useStyles();
  let type;
  if (tabname && tabname.includes('sdk')) {
    type = 'SDK';
  } else if (tabname && tabname.includes('domain')) {
    type = 'Domain';
  } else {
    type = 'Document';
  }

  const [open, setOpen] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalObj, setModalObj] = React.useState({});
  const history = useHistory();
  const [modalLoader, setmodalLoader] = useState(false);
  const [clickAction, setClickAction] = useState(null);
  const [magentaArrow, setMagentaArrow] = useState(false);
  let accountDropdown;

  if (role) {
    if (role.includes('admin')) {
      accountDropdown = [
        {'title': 'Profile', 'img': Profile, 'route': 'profile'},
        {'title': 'Admin Dashboard', 'img': Dashboard, 'route': 'adminDashboard'},
        {'title': 'Contact Us', 'img': Contact, 'route': 'contactUs'},
        {'title': 'Log out', 'img': Logout, 'action': handleSignOut}
      ];
    } else if (role.includes('trusted_partner_manager')) {
      if ((featureFlag[featureFlags.FF_ENABLE_PARTNER_PAGE] &&
        buName?.find(bu => globalConfig.PARTNERS_BU_NAMES.split(',').find((pBUName) => pBUName === bu.name))) ||
        (featureFlag[featureFlags.FF_ENABLE_NEWS_MANAGEMENT] &&
          buName?.find(bu => globalConfig.NEWS_MANAGEMENT_BU_NAME.split(',').find((pBUName) => pBUName === bu.name)))
      ) {
        accountDropdown = [
          {'title': 'Profile', 'img': Profile, 'route': 'profile'},
          {'title': 'Access Management', 'img': Dashboard, 'route': 'access_management_dashboard'},
          {'title': 'Contact Us', 'img': Contact, 'route': 'contactUs'},
          {'title': 'Log out', 'img': Logout, 'action': handleSignOut}
        ];
      } else {
        accountDropdown = [
          {'title': 'Profile', 'img': Profile, 'route': 'profile'},
          {'title': 'Access control', 'img': Dashboard, 'route': 'access_control'},
          {'title': 'Contact Us', 'img': Contact, 'route': 'contactUs'},
          {'title': 'Log out', 'img': Logout, 'action': handleSignOut}
        ];
      }
    } else if (role?.includes('trusted_author') && (featureFlag[featureFlags.FF_ENABLE_NEWS_MANAGEMENT] &&
        buName?.find(bu => globalConfig.NEWS_MANAGEMENT_BU_NAME.split(',').find((pBUName) => pBUName === bu.name)))) {
      accountDropdown = [
        {'title': 'Profile', 'img': Profile, 'route': 'profile'},
        {'title': 'Access Management', 'img': Dashboard, 'route': 'access_management_dashboard'},
        {'title': 'Contact Us', 'img': Contact, 'route': 'contactUs'},
        /* for OKTA only */
        {'title': 'Log out', 'img': Logout, 'action': handleSignOut}
      ];
    } else if (role.includes('trusted_partner') && featureFlag[featureFlags.FF_ENABLE_PARTNER_PAGE] &&
      userConfig?.enterprise?.length > 0 && userConfig?.enterprise[0]?.role &&
      buName?.find(bu => globalConfig.PARTNERS_BU_NAMES.split(',').find((pBUName) => pBUName === bu.name)) &&
      globalConfig.ALL_PARTNER_PAGES?.split(',')?.includes('tpu_account_details')) {
      accountDropdown = [
        {'title': 'Account Settings', 'img': Profile, 'route': 'tpu_account_details'},
        {'title': 'Contact Us', 'img': Contact, 'route': 'contactUs'},
        {'title': 'Log out', 'img': Logout, 'action': handleSignOut}
      ];
    } else {
      accountDropdown = [
        {'title': 'Profile', 'img': Profile, 'route': 'profile'},
        {'title': 'Contact Us', 'img': Contact, 'route': 'contactUs'},
        {'title': 'Log out', 'img': Logout, 'action': handleSignOut}
      ];
    }
  }

  if (props.isTestUser) {
    accountDropdown = accountDropdown.filter(v => v.title !== 'Contact Us');
  }

  const documentCardsDropdown = [{
    'title': 'Edit',
    'status': ''
  },
  {
    'title': `${item && item.status === 'draft' ? 'Publish' : 'Unpublish'}`,
    'status': ''
  },
  {
    'title': 'Manage Users',
    'status': `${userStatus && userStatus.includes('updateCollaborator') ? '' : true}`
  },
  {
    'title': 'Remove',
    'status': `${userStatus && userStatus.includes('delete') ? '' : true}`
  }];
  const anchorRef = React.useRef(null);

  const handleUserToggle = (e) => {
    props.setUserOpen(!props.userOpen);
  };

  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const Unpublish = () => {
    setmodalLoader(true);
    documentConfig.data.map((item, i) => {
      if (item._id === Id) {
        item.status = clickAction === 'publish' ? 'published' : 'draft';
        updateDocuments(item).then((res) => {
          if (res && res.data) {
            // documentConfig.data[i]=res.data._doc;
            changeDocumentConfig(documentConfig);
            setmodalLoader(false);
            const mess = clickAction === 'publish' ? `${type} Published successfully` : `${type} Unpublished successfully`;
            SuccessAlert(mess);
            props.setUpdate && setUpdate(true);
            window.location.reload();
            //   history.push(`/${tabname}`);
          }
        }).catch(err => {
          setmodalLoader(false);
          ErrorAlert(err);
        }).finally(() => {
          closeModal();
          setmodalLoader(false);
          props.setUpdate && setUpdate(false);
        });
      }
      return null;
    });
  };

  const Delete = () => {
    setmodalLoader(true);

    const payload = item;
    payload.status = 'archived';
    updateDocuments(payload).then((res) => {
      if (res && res.data) {
        const data = documentConfig;

        const result = data && data.data.filter(item => {
          return item._id !== res.data._doc._id;
        });
        changeDocumentConfig({data: result});
        setmodalLoader(false);
        SuccessAlert(`${type} removed successfully`);
        // props.setUpdate && setUpdate(true);
        // window.location.reload();
      }
    })
      .catch(err => {
        setmodalLoader(false);
        ErrorAlert(err);
      }).finally(() => {
        closeModal();
        props.setUpdate && setUpdate(false);
      });
  };

  const handleClose = (event, action) => {
    event.preventDefault();
    if (action && action.title && action.title.toLowerCase() === 'remove') {
      setModalObj({
        'question': `Are you sure you want to remove this ${type} `,
        'description': `This will be removed from the ${type}s permanently`,
        'button': 'Yes, Remove',
        'heading': `Remove ${type} ?`
      });
      onModalOpen();
      setClickAction('delete');
    } else if (action && action.title && action.title.toLowerCase() === 'edit') {
      history.push(`/${tabname}/edit/${Id}`);
    } else if (action && action.title && action.title.toLowerCase() === 'manage users') {
      setOpenPopup(true);
    } else if (action && action.title && action.title.toLowerCase() === 'unpublish') {
      setModalObj({
        'question': `Are you sure you want to unpublish this ${type} `,
        'description': `This will not be available under ${type}s`,
        'button': 'Yes, Unpublish',
        'heading': `Unpublish ${type} ?`
      });
      onModalOpen();
      setClickAction('unpublish');
    } else if (action && action.title && action.title.toLowerCase() === 'publish') {
      setModalObj({
        'question': `Are you sure you want to publish this ${type} `,
        'description': `This would make the ${type} available to ${type}s`,
        'button': 'Yes, Publish',
        'heading': `Publish ${type} ?`
      });
      onModalOpen();
      setClickAction('publish');
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (props.accounts) {
      // props.setUserOpen(false);
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();

      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }


    prevOpen.current = props.open;
  }, [open]);

  const handleClickOpen = () => {
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  const onModalOpen = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const capitalize = (s) => {
    if (s && s.length) {
      return s[0].toUpperCase() + s.slice(1);
    }
  };


  return (

    <MenuOpen className={classes.root}

    // onMouseOver={()=>{
    //   props.accounts && setOpen(true);
    // }}
    // onMouseLeave={()=>{
    //   props.accounts && setOpen(false);
    // }}
    >

      <div>

        {props.accounts ?
        // <StyledButton
        //   className='buttonStyle'
        //   ref={anchorRef}

        //   aria-controls={open ? 'menu-list-grow' : undefined}

        //   aria-haspopup="true"

        // >
        //         My Account
        // </StyledButton> 

          <Dropdown
            ref={anchorRef}

            aria-controls={props.userOpen ? 'menu-list-grow' : undefined}

            aria-haspopup="true"
            onClick={(e) => {
              handleUserToggle();
              e.stopPropagation();
            }}
            onMouseOver={() => {
              setMagentaArrow(true);
            }}
            onMouseLeave={() => {
              setMagentaArrow(false);
            }}
          >
            <img src={magentaArrow ? dropdownArrowMagenta : dropdownArrowDark}
              style={{height: '0.6rem', marginTop: '0.4rem', transform: props.userOpen ? 'rotate(180deg)' : ''}} alt='arrow' />
          </Dropdown> :
          <Action
            style={{transform: props.rotate ? 'rotate(90deg)' : ''}}
            ref={anchorRef}

            aria-controls={open ? 'menu-list-grow' : undefined}

            aria-haspopup="true"

            onClick={(e) => {
              handleToggle();
              e.preventDefault();
            }}

            src={action}></Action>}


        <Popper
          style={{zIndex: 2}}
          open={props.userOpen || open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='bottom-end'>

          {({TransitionProps, placement}) => (

            <Grow

              {...TransitionProps}

              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}

            >

              <Paper>
                <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">

                  <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown} style={{
                    width: props.accounts ? '13.5rem' : '',
                    marginTop: props.accounts ? '2rem' : ''
                  }}
                  >
                    {
                      !props.accounts ?
                        documentCardsDropdown && documentCardsDropdown.map((v, i) => <MenuItem key={i}
                          onClick={(event) => handleClose(event, v)} disabled={v.status}
                          style={{
                            color: `${v.title === 'Remove' ? 'red' : ''}`,
                            display: v.title === 'Manage Users' && v.status ? 'none' : ''
                          }}
                        >
                          {v.title}
                        </MenuItem>) :
                        accountDropdown.map((v, i) =>
                          <CommonTooltip arrow
                            title={(userConfig?.impersonator?.name && v.title === 'Log out') ?
                              `Log out ${userConfig?.impersonator?.name}` :
                              ''}
                            placement='bottom'
                            key={i}
                          >
                            <MenuItem style={{
                              display: 'flex',
                              paddingRight: props.accounts && '2rem', paddingLeft: props.accounts && '1rem'
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              (v.title !== 'Log out') ?
                                history.push(`/${v.route}`) :
                                v.action();
                              props.setActivePage && props.setActivePage('');
                              props.setUserOpen(false);
                            }}>
                              <img src={v.img} alt={v.title + 'Icon'} style={{marginRight: '1rem'}} />
                              <div style={{position: 'relative', fontSize: '1.125rem'}}>
                                {v.title}
                              </div>
                            </MenuItem>
                          </CommonTooltip>)
                    }

                  </MenuList>

                </ClickAwayListener>

              </Paper>

            </Grow>

          )}

        </Popper>

      </div>
      {openPopup &&
        <ManageUserModal open={openPopup} onClose={handlePopupClose} item={item} setUpdate={setUpdate} updateList={updateList}
          collaborators={collaborators} title={title} userConfig={role} />}
      <ModalDialog open={openModal} onClose={closeModal} modalObj={modalObj} title={title} clickAction={clickAction}
        Unpublish={Unpublish} Delete={Delete} loader={modalLoader}
      />
    </MenuOpen>

  );
};
const mapStateToProps = ({userConfig, documentConfig, featureFlag, globalConfig}) => ({
  userConfig, documentConfig, featureFlag, globalConfig
});

const mapDispatchToProps = dispatch => ({
  changeDocumentConfig: data => dispatch(DocumentConfig(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuListComposition);
