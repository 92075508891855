import React, {useState, useEffect} from 'react';
import {
  StyledDateContainer,
  StyledWhiteBg,
  StyledNewsDesc,
  StyledReadMore,
  StyledNewsTitle,
  StyledNewsHeader,
  StyledNewsContainer,
} from '../style';
import {StyledMaxWidthSection} from '../../../style';
import styled from 'styled-components';
import * as theme from '../../../config/theme';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import './carouselstyle.css';
import GreyArrow from '../../../assets/images/arrow_white.svg';
import PinkArrow from '../../../assets/images/active_arrow.svg';
import Alerts from '../../utils/userAlert';
import rightArrow from '../../../assets/images/rightArrow.svg';
import TmoLoader from '../../TmoLoader';
import {useHistory} from 'react-router';
import {connect} from 'react-redux';
import {getPublicDocs} from '../../../api/services/documents';
import {openInNewTab} from '../../shared/utils/openInNewTab';
import {getAllNewsList} from '../../../api/services/newsManagement';
const {SuccessAlert, ErrorAlert, CustomErrorAlert} = Alerts;

const Loadwrap = styled.div`
  height: 18.75rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

const NewsAndUpdateFooter = (props) => {
  const controller = new AbortController();
  const [loader, setLoader] = useState(true);
  const [newsCall, setNewsCall] =useState(true);
  const [maxWidth, setMaxWidth] = useState(theme.default.maxWidth);
  const [data, setData] = useState([]);
  const history = useHistory();
  // const location = useLocation();

  const getSliced = (text, length) => {
    if (text && (text?.length > length)) {
      return text?.slice(0, length).concat('...');
    } else {
      return text || '';
    }
  };

  const getMaxWidth = () => {
    const max = theme.default.maxWidth();
    if (max !== maxWidth) {
      setMaxWidth(max);
    }
  };
  window.addEventListener('resize', getMaxWidth);

  const fetchNewsListFunc = async () => {
    try {
      const query = 'page=1&limit=10&view=public';
      const response = await getAllNewsList(query, controller.signal);
      setData(response.data.data);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (newsCall) {
      setNewsCall(false);
      fetchNewsListFunc();
      return () => controller.abort();
    }
  }, []);

  return (
  
    <StyledMaxWidthSection maxWidth={maxWidth}>
      <StyledNewsContainer>
        {loader || (data && data.length>0) ?
          <StyledNewsHeader>
          News & <span className="dark-hot-pink"> Updates </span>
          </StyledNewsHeader>:<></>}
        {loader&&<Loadwrap>
          <TmoLoader/>
        </Loadwrap>
        }
        {!loader&&data && data.length ? <Carousel
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          renderArrowNext={(clickHandler, hasNext, labelNext)=>
            <div className='arrowNext' onClick={clickHandler} style={{cursor: hasNext? 'pointer' : 'default'}}>
              <img className='' src={hasNext ? PinkArrow : GreyArrow} alt='NA'/></div>
          }
          renderArrowPrev={(clickHandler, hasPrev, labelNext)=>
            <div className='arrowPrev' onClick={clickHandler} style={{cursor: hasPrev ? 'pointer' : 'default'}}>
              <img className='' src={hasPrev ? PinkArrow : GreyArrow} alt='NA'/></div>
          }
        >
          {data&&data.length&&data.map((item, i) => (
            <StyledWhiteBg className="d-flex flex-column align-items-center justify-content-center" key={i}>
              <StyledDateContainer>
                {new Date(item.published_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })
                }
              </StyledDateContainer>
              <StyledNewsTitle>{getSliced(item.title, 60)}</StyledNewsTitle>
              <StyledNewsDesc>{getSliced(item.description, 150)}</StyledNewsDesc>
              <StyledReadMore>
                <span className="cursor-pointer" onClick={(e) => {
                  if (e.ctrlKey) {
                    openInNewTab(`/news/${item._id}`, true);
                  } else {
                    history.push(`/news/${item._id}`);
                  }
                }}> 
                Read More</span> <img src={rightArrow} alt='' style={{width: '0.875rem'}} />
              </StyledReadMore> 
            </StyledWhiteBg>
          ))}
        </Carousel> : <></>}
      </StyledNewsContainer>
    </StyledMaxWidthSection>
  );
};


export default NewsAndUpdateFooter;
