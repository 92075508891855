import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import ResponsiveScreen from '../ResponsiveScreen';
import Header from '../header';
import Alert from '../common/alert';
import Footer from '../footer';
import {connect} from 'react-redux';
import {getUser} from '../../api/services/users';
import {getAPIs} from '../../api/services/config';
import {UserConfig} from '../../actions/userConfigActon';
import {GlobalConfig} from '../../actions/globalConfig';
import {VpnAlertConfig} from '../../actions/vpnPromptAction';
import TmoLoader from '../TmoLoader';
import Arrowtotop from '../arrowToTop';
import styled from 'styled-components';
import useAlert from '../utils/userAlert';
import {LoaderConfig} from '../../actions/loaderConfig';
import '../../scss/custom.scss';
import {ImpersonationLoaderConfig} from '../../actions/impersonationLoaderConfig';
import {getVersion} from '../../api/services/appInfo';
import VpnPromptModal from './vpnPrompt';
import {useMsalAuthentication} from '@azure/msal-react';

const {CustomErrorAlert} = useAlert;
const Loadwrap = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7fb;
  ${'' /*  */}
`;

const ImpersonationMessage = styled.div`
font-family: Helvetica;
font-size: 16px;
font-weight: bold;
line-height: 1.5;
text-align: center;
color: #4b4d61;
max-width: 19rem;
margin-top: 1rem;
`;


const Layout = props => {
  const history = useHistory();
  const {error} = useMsalAuthentication();
  const isAuthenticated = error ? false : true;
  const [versionResult, setVersionResult] = useState({});
  const [navigateToTop, setNavigateToTop] = useState(false);
  const [resolution, setResolution] = useState(window.innerWidth);
  const {
    userConfig,
    changeUserConfig,
    changeGlobalConfig,
    changeLoaderConfig,
    changeVpnAlertConfig,
    impersonationLoaderConfig,
    changeImpersonationLoaderConfig,
    vpnPrompt,
    envConfig} = props;
  const [openVpnModal, setOpenVpnModal] = useState(false);
  // const [UserData, setUserData] = useState(userConfig);

  useEffect(() => {
    setOpenVpnModal(vpnPrompt.status || false);
  }, [vpnPrompt.status]);

  const setUser = (user, my_partners = false) => {
    localStorage.setItem('id', JSON.stringify({...user, my_partners: my_partners}));
    window.dispatchEvent(new Event('storage'));
    changeUserConfig({...user, my_partners: my_partners});
    changeImpersonationLoaderConfig({loader: false, user: false});
    // setUserData(user);
  };

  const checkUserUpdate = () => {
    const id = localStorage.getItem('id');
    if (isAuthenticated && id && (localStorage.getItem('token-storage'))) {
      getUser(JSON.parse(id).user_id).then(res => {
        const response = res?.data?.data;
        const _id = res?.data?.data?._id;
        const user = {
          name: response.name,
          user_id: response.user_id,
          role: response.role,
          id: _id,
          test: response.test ? response.test : false,
          buName: response?.buName,
          userGroup: response?.userGroup,
          enterprise: response?.enterprise
        };
        if (res?.data?.impersonator) {
          user.impersonator = res?.data?.impersonator;
        }
        const id = JSON.parse(localStorage.getItem('id'));
        if (!response.blocked) {
          const prevStateRole = id.role;
          const prevStateName = id.name;
          const prevBuName = id.buName;
          const prevUserGroup = id.userGroup;
          const prevMy_partners = id.my_partners;
          const isRoleSimilar = () => {
            if (prevStateRole.length !== response.role.length) {
              return false;
            }
            const newRole = response.role.filter(e => {
              return !prevStateRole.includes(e)
              ;
            });
            if (newRole.length > 0) {
              return false;
            } else {
              return true;
            }
          };
          if (prevStateRole.length !== response.role.length || !isRoleSimilar()) {
            getAPIs('my_partners').then(async (res) => {
              const response = await res.data.data;
              if (response.length > 0) {
                setUser(user, true);
              }
            }).catch(err => {
              setUser(user);
            });
          } else if (JSON.stringify(prevBuName) !== JSON.stringify(response?.buName)) {
            setUser(user, prevMy_partners);
          } else if (JSON.stringify(prevUserGroup) !== JSON.stringify(response?.userGroup)) {
            setUser(user, prevMy_partners);
          } else if (prevStateName !== response.name) {
            setUser(user, id.my_partners);
          }
        }
      }).catch(err => {
        console.log('checkUserUpdate error : ', err);
      });
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      window.addEventListener('scroll', handleScroll);
      // window.addEventListener('resize', updateSize);
      // if (localStorage.getItem('id')) {
      // setLoginLoader(true);
      changeGlobalConfig({
        API_SANDBOX_BU_NAME: envConfig.API_SANDBOX_BU_NAME,
        NEWS_MANAGEMENT_BU_NAME: envConfig.NEWS_MANAGEMENT_BU_NAME,
        PARTNERS_BU_NAMES: envConfig.PARTNERS_BU_NAMES,
        ADMIN_BASE_URL: envConfig.ADMIN_BASE_URL,
        DAYS_INTERVAL_TO_INACTIVE_USER: envConfig.DAYS_INTERVAL_TO_INACTIVE_USER,
        ALL_PARTNER_PAGES: envConfig.ALL_PARTNER_PAGES,
        ALL_NEWS_PAGES: envConfig.ALL_NEWS_PAGES,
        ENV: envConfig.NODE_ENV,
        API_PROVISIONING_GRAPHQL_URL: envConfig.API_PROVISIONING_GRAPHQL_URL,
        NOVA_APM_ID_LINK: envConfig.NOVA_APM_ID_LINK,
        TAAPSEC_LINK: envConfig.TAAPSEC_LINK
      });
      changeLoaderConfig(false);
      // }
      checkUserUpdate();
      window.addEventListener('storage', (e) => {
        if (e.key === 'id') {
          if (document.hidden) {
            window.location.reload();
          }
        }
      });
      getVersion().then(res => {
        setVersionResult(res.data);
      });
    };
    asyncFn();
  }, []);

  useEffect(() => {
    if (impersonationLoaderConfig?.user) {
      if (impersonationLoaderConfig?.path) {
        history.push(impersonationLoaderConfig?.path);
      }
      checkUserUpdate();
    }
  }, [impersonationLoaderConfig]);

  const handleScroll = e => {
    if (window.scrollY > 800) {
      setNavigateToTop(true);
    } else {
      setNavigateToTop(false);
    }
  };
  const updateSize = () => {
    setResolution(window.innerWidth);
  };
  /* const childrenWithProps = React.Children.map(props.children, (child, index) => {
    console.log('DATA', UserData, child);
    return React.cloneElement(child, {UserData: UserData, setUserData: setUserData});
  }); */
  return (
    <div className="d-flex flex-column  justify-content-between" style={{minHeight: '100vh'}}>
      {resolution > 768 ?
        <>
          <Alert />
          <VpnPromptModal
            onClose={() => {
              changeVpnAlertConfig({status: false});
            }}
            openVpnPromptModal={openVpnModal} />
          {(impersonationLoaderConfig?.loader) ?
            <Loadwrap>
              <TmoLoader message={impersonationLoaderConfig?.message ?
                impersonationLoaderConfig?.message : false} />
            </Loadwrap> :
            <>
              <Header envConfig={envConfig} />
              {props.children}
              <Footer envConfig={envConfig} {...versionResult} />
              {navigateToTop && <Arrowtotop navigateToTop={navigateToTop} />}
            </>}
        </> : <ResponsiveScreen />
      }
    </div>
  );
};


const mapStateToProps = ({userConfig, impersonationLoaderConfig, vpnPrompt}) => ({userConfig, impersonationLoaderConfig, vpnPrompt});
const mapDispatchToProps = dispatch => ({
  changeVpnAlertConfig: data => dispatch(VpnAlertConfig(data)),
  changeGlobalConfig: data => dispatch(GlobalConfig(data)),
  changeUserConfig: data => dispatch(UserConfig(data)),
  changeLoaderConfig: data => dispatch(LoaderConfig(data)),
  changeImpersonationLoaderConfig: data => dispatch(ImpersonationLoaderConfig(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);

// export default Layout;
