import _ from 'lodash';
import Cookies from 'universal-cookie';

const checkToken = () => {
  const id = localStorage.getItem('id');
  if (id) {
    const cookies = new Cookies();
    let envVar;
    if (window.location.origin.includes('-dev')) {
      envVar='dev';
    } else if (window.location.origin.includes('-stg')) {
      envVar='stg';
    } else {
      envVar='prd';
    }
    if (localStorage.getItem('token-storage')) {
      const token = JSON.parse(localStorage.getItem('token-storage'))?.idToken;
      cookies.set('OAUTH_TOKEN', token, {domain: '.t-mobile.com', path: '/'});
      cookies.set('DEVPORTALV3_ENV', envVar, {domain: '.t-mobile.com', path: '/'});
    } 
    const user = JSON.parse(id);
    if (user) {
      delete user['my_partners'];
      return user;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export default {
  checkToken,
};

