import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Text from '../shared/text';
import Close from '../../assets/images/icon close.png';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import themes from '../../config/theme';
import Button from '../shared/button';
import Loader from 'react-loader-spinner';
import {useHistory} from 'react-router';
import {cssVar} from '../../utils/utils';

const Loadwrap = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;
  height: 1rem;
  margin-top: -1.2rem;
`;

const DialogContent = styled.div`
    padding: 2rem 3rem 1.5rem;
    max-width: 40.625rem;
    .wrapped {
      word-break: break-word;
    white-space: -webkit-nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    }
  `;

const Heading = styled(Text)`

  `;

const Closeicon = styled.div`
  display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: -2.8rem;
    position: absolute;
    right: 4rem;
    top: 1rem;
  `;

const SubHeading = styled(Text)`
text-align: center;
    .title {
        font-weight: bold;
        font-family: 'TeleGroteskNext-Ultra';
        margin-left: 0.3rem;
    }
`;

const CloseIconContainer = styled.div`
display: flex;
    padding: 0.7rem;

:hover {
      border-radius: 50%;
      background: #F5F5F5;
    }

`;

const ButtonContainer = styled.div`
 display: flex;
 justify-content: center;
    margin: 2rem 0 1.5rem 0;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      // minHeight: '35vh',
      width: '35rem',
      maxHeight: 'none',
      borderRadius: '0'
    },
    '& .MuiInputBase-input': {
      'fontFamily': `${themes.fonts.primary}`,
      'fontSize': `${themes.texts.normalText}`,
      'fontWeight': '500',
      'color': '#000',
      'padding': '0',
      '&:disabled': {
        // cursor: 'not-allowed',
      },
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      'font-size': '0.875rem',
      'marginBottom': '0.625rem',
    },
    '& .WAMuiChipInput-inputRoot-43.WAMuiChipInput-outlined-46': {
      paddingTop: '0 !important',
    },
    '& .MuiChip-label': {
      fontFamily: `${themes.fonts.primary}`,
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    '& .WAMuiChipInput-chip-57': {
      borderRadius: '0',
      background: '#f2f4f8',
      margin: '0.5rem 0.5rem 0.5rem 0',
    },
    '& .MuiOutlinedInput-root': {
      'borderRadius': '0',
    },
    '& .MuiInputLabel-outlined': {
      color: '#000 !important',
      opacity: '0.3'
    },
  }
}));

  
function ModalDialog(props) {
  const {onClose, open, modalObj, title} = props;
  const history = useHistory();
  const classes = useStyles();
  const handleClose = () => {
    if (props.loader) {
      return;
    }
    onClose();
  };

  const handleClick = () => {
    if (props.clickAction === 'unpublish') {
      props.Unpublish();
    } else if (props.clickAction === 'delete') {
      props.Delete();
      if (props.setUpdateList) {
        props.setUpdateList(true);
      }
    } else if (props.clickAction === 'publish') {
      props.Unpublish();
    } else if (props.clickAction === 'Cancel') {
      history.push('/adminDashboard');
    } else {
      return;
    }
  };

  return (
    <Dialog className={classes.root} style={{zIndex: '10001'}}
      onClose={handleClose} open={open} maxWidth={false}>
      <DialogContent >
        <Closeicon onClick={handleClose}>
          <CloseIconContainer>
            <img src={Close} alt='NA'/>
          </CloseIconContainer>
        </Closeicon>
        <Heading color='fifth' fontSize='subHeading' family='secondary' margin='0.5rem 0 1rem 0' justifyContent='center'>
          {modalObj.heading}
        </Heading>
        <SubHeading className='wrapped' color='fifth' fontSize='font18px' justifyContent='center'>
          {modalObj.question} {title && <span className='title' title={title}>&quot;{title}&quot; </span>} ?
        </SubHeading>
        <Text color='fifth' fontSize='font18px' justifyContent='center' margin='0.3rem'>
          {modalObj.description}
        </Text>
        <ButtonContainer>
          <Button onClick={handleClose} hoverBgColor='rgba(226, 0, 116, 0.05)'
            margin='0 1rem 0 0' 
            border={'0.0625rem solid ' + cssVar('--tmo-magenta')} 
            bgColor='#fff' color={cssVar('--tmo-magenta')} 
            padding='0.8rem 3.25rem'
            opacity={(props.loader)? '0.3' : '1'}
            disabled={(props.loader)? true : false}>
              Cancel
          </Button>
          <Button width='13rem' hoverBgColor='#ba0060' padding='0.8rem 3.25rem' 
            disabled={(props.loader)? true : false} 
            opacity={(props.loader)? '0.3' : '1'}
            onClick={handleClick}>
            {props.loader ? <Loadwrap>
              <Loader type="ThreeDots" color="#fff" height={40} width={40} />
            </Loadwrap> :
              <div>{modalObj.button}</div>}
          </Button>
        </ButtonContainer>
      </DialogContent>
    </Dialog>
  );
}

export default ModalDialog;
