/* eslint-disable max-len */
import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {getSliced} from './utils/getSliced';
import CommonTooltip from './CommonTooltip';
import closeIcon from '../../assets/images/globalsearch/group-3.svg';
import Arrow from '../../assets/images/grey_down_arrow.svg';
import Loader from 'react-loader-spinner';
import {cssVar} from '../../utils/utils';

const Label = styled('label')`
  position: absolute;
  z-index: 1;
  top: -0.5625rem;
  left: 1.1875rem;
  font-size: 0.875rem;
  background: #fff;
  padding: 0 0.25rem;
`;

const Loadwrap = styled.div`
margin: auto !important;
  padding: 3rem 0 3rem 0;
  display: flex;
  position:absolute;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  z-index: 99;
  width: 34.0625rem;
  background-color: #fff;
  overflow: auto;
  max-height: 12.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
`;

const InputWrapper = styled.div`
  position: relative;
  border: 0.0625rem solid #9b9b9b;
  background-color: #fff;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  outline: none;

  & input {
    font-size: 1rem;
    height: 1.875rem;
    font-family: 'TeleGroteskNext';
    font-weight: 500;
    box-sizing: border-box;
    width: 0;
    min-width: 1.875rem;
    display: flex;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;

    ::placeholder {
      opacity: 0.5;
    }

    :disabled {
      background-color: #fff;
    }
  }
`;

const Tag = styled(({label, onDelete, hideClose, disabled, ...props}) => {
  return (
    <div {...props} onKeyDown={(e)=>{
      disabled && e.stopPropagation();
    }}>
      <span>{label}</span>
      {!hideClose && !disabled && <CloseIcon onClick={onDelete} />}
    </div>);
})`
  display: flex;
  align-items: center;
  height: 1.5625rem;
  padding: 0 0.625rem 0 0.625rem;
  line-height: 1.375rem;
  background-color: rgba(226,0,116,0.1);
  border: 0.0625rem solid #e8e8e8;
  border-radius: 0.125rem;
  box-sizing: content-box;
  outline: 0;
  margin-right: 0.625rem;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${()=> cssVar('--tmo-magenta')};
  }

  & svg {
    font-size: 1rem;
    cursor: pointer;
    padding: 0.25rem;
    margin-right: -0.5rem;
    color: ${()=> cssVar('--tmo-magenta')};
  }
`;

const Listbox = styled('ul')`
  margin: 0.125rem 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  width: 34.0625rem;
  background-color: #fff;
  overflow: auto;
  max-height: 12.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  z-index: 99;

  & li {
    padding: 0.3125rem 0.75rem;
    display: flex;
    align-items: center;
    & span {
      flex-grow: 1;
    }

    & svg {
      padding: 0;
      margin:0;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: ${()=> cssVar('--tmo-magenta')};
    }
  }

  & li[data-focus='true'] {
    background-color: rgba(209,222,240,0.5);
    cursor: pointer;
  }
`;

const useStyles = makeStyles(theme => ({
  tag: {
    backgroundColor: 'rgba(226, 0, 116, 0.1)',
    padding: '0.25rem 0.5rem 0.3125rem',
    color: cssVar('--tmo-magenta'),
    fontWeight: 500,
    fontFamily: 'TeleGroteskNext',
    fontSize: '1rem',
    borderRadius: '0.125rem'
  },
}));

const MultipleSelectionDropdown = forwardRef((props, ref) => {
  const [checked, setChecked] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    multiple: true,
    defaultValue: checked,
    options: props.allList,
    varient: 'outlined',
    disableCloseOnSelect: true,
    getOptionLabel: (option) => option?.name,
  });
  const classes = useStyles();
  
  useImperativeHandle(ref, () => ({
    clearTextfield() {
      value.splice(0, value.length);
    }
  }));

  useEffect(() => {
    if (props.isEdit) {
      setChecked([...(props?.editData || [])]);
      (props?.editData || []).forEach(i => value.push(i));
    }
  }, [props?.editData]);

  useEffect(() => {
    const tempValue = [];
    value.forEach((v, i) => {
      tempValue.unshift(v);
    });
    props.setSelectedList(tempValue);
  }, [value]);

  return (
    <>
      <NoSsr>
        <div>
          <div {...getRootProps()} style={{position: 'relative', 
            pointerEvents: (props.isDisable) && 'none', 
            opacity: (props.isDisable) && '0.6'}}>
            <Label {...getInputLabelProps()}>
              {props.dropdownHeading}{props.isRequired && (<span style={{color: `${cssVar('--tmo-magenta')}`}}>*</span>)}
            </Label>
            <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''} defaultValue={props.allList[0]?.name} 
              style={{position: 'relative'}}>
              {value?.slice(0, 3).map((option, index) => (
                <CommonTooltip
                  key={index} 
                  arrow placement = "top" 
                  title={option?.name?.length >10 ? option.name : ''}
                >
                  <Tag label={getSliced(option?.name, 10)} hideClose={(props.isDisable)} {...getTagProps({index})}/>
                </CommonTooltip>
              ))}
              {
                value?.length > 3 &&
                (
                  <CommonTooltip
                    arrow placement = "top" 
                    title={value?.slice(3)?.map(i => i.name).join(', ')}
                  >
                    <div className={classes.tag}>+ {Math.abs(value?.length - 3)}</div>
                  </CommonTooltip>
                )
              }
              <input placeholder={!value.length ? props.placeholder : ''} {...{...getInputProps(), onFocus: ()=> {
                getInputProps().onFocus();
                setShowLoader(true);
              }, onBlur: ()=>{
                getInputProps().onBlur();
                setShowLoader(false);
              }}} disabled={props.isDisable}/>
              {(value.length) ?
                <div style={{position: 'absolute', top: '0.7rem', right: '2rem'}}>
                  <CommonTooltip arrow placement='top' title="Clear Search" >
                    <img style ={{cursor: 'pointer', opacity: '0.6'}} 
                      src={closeIcon} alt='X' onClick={()=>{
                        props.onClearMultiSelect();
                      }}/>
                  </CommonTooltip>
                </div> : null}
              {(props.isDisable) ? <></> : <img onClick={() => {
                getInputProps().onMouseDown();
              }}
              src={Arrow} alt="" style={{position: 'absolute', top: '0.7rem', right: '0.5rem', cursor: 'pointer',
                transform: groupedOptions.length > 0 ? 'rotate(180deg)' : ''}} />}
            </InputWrapper>
          </div>
          {showLoader&&props.BUloader?<Loadwrap {...getListboxProps()}>
            <Loader type="ThreeDots" color={cssVar('--tmo-magenta')} height={40} width={40} />
          </Loadwrap>:
            (groupedOptions.length > 0)?
              <Listbox {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                  <li
                    key={index}
                    {...getOptionProps({option, index})}
                    className={(props.approvedCustomGroup?.find(item => item?.userGroup?.name === option?.name) ?'disabledLi':'Li')}
                    style={{display: 'flex', alignItems: 'center'}}
                  >
                    <div style={{paddingLeft: '1rem', display: 'flex', alignItems: 'start'}}>
                      <FormControlLabel control={<Checkbox checked={!![...value, ...(props.approvedCustomGroup.map(i => (i.userGroup)))]?.find(item => item?.name === option?.name) || ''} 
                        name="checkedA"/>}
                      />
                    </div>
                    <div style={{display: 'flex', alignItems: 'start', flexDirection: 'column', paddingLeft: '0rem'}}>
                      <div style={
                        {padding: '0.5rem 0 0.25rem 0', fontSize: '0.875rem', color: '#4b4d61', fontFamily: 'TeleGroteskNext', fontWeight: '800'}
                      }>
                        <CommonTooltip
                          arrow placement = "top" 
                          title={option?.name?.length >50 ? option.name : ''}
                        >
                          {getSliced(option?.name, 50)}
                        </CommonTooltip>
                      </div>
                      <div style={
                        {paddingTop: '0', 
                          fontSize: '0.875rem', 
                          color: '#4b4d61', 
                          fontFamily: 'TeleGroteskNext', 
                          fontWeight: '500',
                          paddingBottom: '0.5rem'}}>
                        <CommonTooltip
                          arrow placement = "top" 
                          title={option?.description?.length > 50 ? option.description : ''}
                        >
                          {getSliced(option?.description, 50)}
                        </CommonTooltip>
                      </div>
                    </div>
                  </li>
                ))}
              </Listbox>: null}
        </div>
      </NoSsr>
    </>
  );
});

MultipleSelectionDropdown.displayName = 'MultipleSelectionDropdown';

export default MultipleSelectionDropdown;
