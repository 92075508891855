import {createTheme} from '@material-ui/core/styles';

const MuiTheme = createTheme({
  // palette: {
  //   primary: {
  //     main: "#FFFFFF",
  //   },
  //   secondary: {
  //     main: "#FFFFFF",
  //   },
  // },
  overrides: {
    MuiOutlinedInput: {
      root: {},
      notchedOutline: {
        'outline': 'none',
        'border': '0.0625rem solid #7c8089 !important',
        '&$focused': {
          outline: 'none',
          border: 'none',
        },
      },
    },
    MuiInput: {
      root: {},
    },
    MuiTextField: {
      root: {
        
      },
    },
    MuiInputBase: {
      root: {
        color: 'white !important',
      },
    },

    MuiInputLabel: {
      root: {},
      outlined: {
        color: 'white !important',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          outline: 'none',
          color: 'white !important',
        },
      },
    },
  },
});

export default MuiTheme;
