

export default (state={}, action) => {
  switch (action.type) {
  case 'LOADER_CONFIG':
  

    return action.payload;
  

  default:
  

    return state;
  }
};

  
