import React, {Suspense, useState, useEffect} from 'react';
import Layout from './components/layout';
import {BrowserRouter as Router} from 'react-router-dom';
import Loader from './components/shared/loader';
import AppWithRouterAccess from './AppWithRouterAccess';
import styled from 'styled-components';
import './App.css';
import createActivityDetector from 'activity-detector';
import {SignOut} from './components/signIn/auth';
import Alerts from './components/utils/userAlert';
import reactConfig from './config/reactConfig';
import axios from 'axios';
import getBaseURL from './api/services/getBaseURL';
import TmoLoader from './components/TmoLoader';
import {cssVar} from './utils/utils';
import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react'; 
import {configureMsalConfig} from './authConfig';
const {SuccessAlert, ErrorAlert, CustomErrorAlert} = Alerts;
const Loadwrap = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const screenLoad = () => (
  <Loadwrap>
    <Loader type="Bars" color={cssVar('--tmo-magenta')} height={50} width={50} />
  </Loadwrap>
);

function useIdle(options, instance) {
  useEffect(() => {
    const activityDetector = createActivityDetector(options);
    activityDetector.on('idle', () => {
      if (localStorage.getItem('token-storage')) {
        CustomErrorAlert('Session Timed Out!');
        setTimeout(()=>{
          console.log('Signing out...');
          SignOut(instance);
        }, 3000);
      }
    });
    return () => activityDetector.stop();
  }, []);
}
const App = () => {
  const [envConfig, setEnvConfig] = useState({});


  useEffect(() => {
    const loadData = async () => {
      const baseURl = getBaseURL();
      const url = baseURl + '/app-config/get-config';
      const envConfigResponse = await axios.get(url, {headers:
        {
          'Accept': 'application/json',
        }});
      reactConfig.aad_vars.AAD_AUTHORITY = envConfigResponse.data.AAD_AUTHORITY;
      reactConfig.aad_vars.AAD_CLIENT_ID = envConfigResponse.data.AAD_CLIENT_ID;
      reactConfig.aad_vars.AAD_TENANT_ID = envConfigResponse.data.AAD_TENANT_ID;
      setEnvConfig(envConfigResponse.data);
    };
    loadData();
  }, []);

  const msalConfig = configureMsalConfig(envConfig.AAD_CLIENT_ID, envConfig.AAD_AUTHORITY, window.location.origin);
  /**
     * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
     * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
     */

  const msalInstance = new PublicClientApplication(msalConfig);
  
  useIdle({timeToIdle: 3600000, inactivityEvents: []}, msalInstance);
  if (Object.prototype.hasOwnProperty.call(envConfig, 'AAD_CLIENT_ID')) {
    return (
      <MsalProvider instance={msalInstance}>
        <Router>
          <Layout envConfig={envConfig}>
            <Suspense fallback={screenLoad()}>
              <AppWithRouterAccess />
            </Suspense>
          </Layout>
        </Router>
      </MsalProvider>
    );
  } else {
    return (<Loadwrap><TmoLoader/></Loadwrap>); 
  }
}
;

export default App;
