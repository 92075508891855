import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import DevPortalLogo from './assets/images/Logo_Finalized.svg';
import * as theme from './config/theme';
import {cssVar} from './utils/utils';

export const StyledMaxWidthSection = styled.section`
  max-width: ${(props) => props.maxWidth? props.maxWidth: theme.default.maxWidth}
  margin: 0 auto;
  padding: ${(props) => props.padding?props.padding:'0 3.75rem;'} ;
`;
export const StyledDiv = styled.div``;

export const StyledLogo = styled.img`
  height: 2.8125rem;
`;

StyledLogo.defaultProps = {
  src: DevPortalLogo,
};

export const StyledButton = styled.button`
  border: 0.0625rem solid;
  box-shadow: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  white-space: nowrap;
  margin:${(props) => props.margin&&props.margin};
  padding:${(props) => props.padding?props.padding:'0.8rem 2rem;'} ;
  display:${(props) => props.display};
  align-items:${(props) => props.alignItems};
  font-weight: bold;
  color: ${(props) => props.color?props.color:'#fff'};
  opacity: ${(props)=> props.opacity?props.opacity:'1'};
  visibility: visible;
  background: ${(props) => props.background?props.background: cssVar('--tmo-magenta')};
  border-color: ${(props) => props.borderColor?props.borderColor: cssVar('--tmo-magenta')};
  font-family: TeleGroteskNext;
  font-size: ${(props) => props.fontSize?props.fontSize:'1rem'} ;
  :hover {
    background: ${(props) => props.hoverBgColor?props.hoverBgColor:'#ba0060'};
    transition: all 0.3s ease-in;
  }
  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
