import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import React, {useEffect} from 'react';
import Not_Found from '../assets/images/not_found/not_found.png';

import Button from './shared/button';
import Text from './shared/text';


const NotFoundWrap = styled.div`
    width:100%;
    height: 65vh;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    color:#000;
    margin: 5rem auto; 
    padding: 5rem 1rem;  
`;
const ImageWrap= styled.div`

  display: flex;
  justify-content: center;
  align-items: center;

`;
const Image= styled.img`
  width: 80%;
  object-fit: scale-down;

`;
const TextWrap= styled(Text)`
${({theme}) => theme.writeMediaQueries(responsiveStyles.TextWrap)}
`;
const TextBox= styled.div`
   padding-top: 3rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
`;
const NotFound = () => {
  const history= useHistory();
  
  const handleClick= ()=>{
    history.push('/');
  };

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <NotFoundWrap>
      <ImageWrap>
        <Image src={Not_Found}></Image>
      </ImageWrap>
      <TextBox>
        <TextWrap family="secondary" color="#4b4d61">
          PAGE NOT FOUND
        </TextWrap>
        <Text color="#4b4d61" padding="0" textAlign='center' weight='normal'>
          We couldn’t find the page you’re looking for
        </Text>
      </TextBox>
      <Button
        weight='bold'
        margin="1rem 0 0 0"
        padding="1rem 4rem"
        onClick={handleClick}
      >
        Back to Home
      </Button>
    </NotFoundWrap>
  );
};

export default NotFound;

const responsiveStyles= {
  TextWrap: {
    desktop: `{
      font-size: 2.3rem;
    }`,
    laptop: `{
      font-size: 2rem;
    }`,
    tablet: `{
      font-size: 1.5rem;
    }`,
  },
};
