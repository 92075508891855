/* eslint-disable max-len */
import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 1rem;
    height: 100vh;
    width: 100vw;
    //overflow-y: overlay;
    scroll-behavior: smooth;
    transition: background-image 1s ease-in-out;
  }
  body {
    font-family: TeleGroteskNext, BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,"Helvetica Neue",Arial,sans-serif !important;
    font-weight: 500 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x:hidden !important;
    // overflow-y:auto !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a{
    cursor: pointer;
  }
  .fas {
    transition: all 0.3s ease;
  }
  .tabs header {
    width: 25rem;
    margin: 0 auto;
    box-shadow: none;
    background-color: transparent;
  }
  .tabs {
    width: 100%;
  }
  .tabs header > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tabs {
    
  } 
`;

export default GlobalStyles;
