import styled from 'styled-components';
import tlogo from '../assets/images/Loader/tlogo.gif';

import React from 'react';

const ImpersonationMessage = styled.div`
font-family: Helvetica;
font-size: 16px;
font-weight: bold;
line-height: 1.5;
text-align: center;
color: #4b4d61;
max-width: 19rem;
margin-top: 1rem;
`;

const TmoLoader = ({message}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column',
      alignItems: 'center'}}>
      <img style={{height: '1.875rem'}} src={tlogo} alt='loader' />
      {message && <ImpersonationMessage>
        {message}
      </ImpersonationMessage>}
    </div>

  );
};

export default TmoLoader;
