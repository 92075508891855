import {axiosPrivate, axiosPublic} from '../interceptors';
import reactConfig from '../../config/reactConfig';
import getBaseURL from './getBaseURL';

const baseURl = getBaseURL();

export const createOrUpdateConfig = data => {
  const url = '/devportal-admin/v3/config';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

export const getConfig = () => {
  const url = '/devportal/v3/config';
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const getTrustedPartnerAPI = (domain) => {
  const url = `/devportal/v3/config/trusted_partner?domain=${domain}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const getAPIs = (env) => {
  const url = `/devportal/v3/config/${env}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const getSwaggerDetail = (env, filePath, gitapicall, swaggerRepo) => {
  const gitApiCallQuery = gitapicall ? `&gitApiCall=true&swaggerRepo=${swaggerRepo}` : '';
  const url = `/devportal/v3/config/${env}/swagger?filePath=${filePath}${gitApiCallQuery}`; 
  return axiosPrivate.request({url: url, method: 'GET'});
};
