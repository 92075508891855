import {axiosPrivate, axiosPublic} from '../interceptors';
import reactConfig from '../../config/reactConfig';
import getBaseURL from './getBaseURL';

const baseURl = getBaseURL();

export const getUser = (userID, params) => {
  let paramString = '';
  if (params) {
    for (const key in params) {
      if (key && params[key]) {
        if (paramString !== '') {
          paramString += '&';
        } else {
          paramString = '?';
        }
        paramString += key + '=' + encodeURIComponent(params[key]);
      }
    }
  }
  const url = `/devportal/v3/users/${encodeURIComponent(userID)}${paramString}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const getUsers = data => {
  const url = '/devportal-admin/v3/users/search';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

export const autoOnBoardUser = data => {
  const url = '/devportal/v3/users/auto-onboard';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

export const createUser = data => {
  const url = '/devportal/v3/users';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

export const createUserAuto = data => {
  const url = '/devportal/v3/users';
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};

export const editUser = data => {
  const url = '/devportal-admin/v3/users';
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};

export const updateUserLogin = data => {
  const url = '/devportal/v3/users/login';
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};

export const deleteUser = email => {
  const url = `/devportal-admin/v3/users/${encodeURIComponent(email)}`;
  return axiosPrivate.request({url: url, method: 'DELETE'});
};

export const submitContact = data => {
  const url = '/devportal/v3/email';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

export const searchUsers = (value, buName) => {
  const buNameQuery = buName ? `&buName=${buName}` : '';
  const url = `/devportal/v3/users/search?user_id=${value}${buNameQuery}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const searchBuTrustedPartners = (value, buName, domain) => {
  const url = `/devportal/v3/users/search?user_id=${value}&buName=${buName}&domain=${domain}&role=trusted_partner`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const refineBU = data => {
  const url = '/devportal/v3/users/search?refineSchema=true';
  return axiosPrivate.request({url: url, method: 'POST'});
};
