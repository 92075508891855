/* eslint-disable max-len */
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import ErrorIcon from '../../assets/images/error-icon.svg';
import {connect} from 'react-redux';
import Text from './text';
import {cssVar} from '../../utils/utils';

const Loadwrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

const EmailsContainer = styled.div`
z-index: 1;
    position: absolute;
    top: 2.5rem;
    overflow-y: auto;
    width: 100%;
    
    max-height: 15rem;
    box-shadow: 0 0.0625rem 0.625rem 0 rgb(0 0 0 / 20%);
    background-color: #ffffff;
    ::-webkit-scrollbar {
      width: 0.3125rem;
    }
    
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    
    ::-webkit-scrollbar-thumb {
      background: #888; 
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
`;

const EmailList = styled.ul`
padding: 1rem 0 0 0;
    margin: 0;
`;

const Items = styled.li`
display: flex;
justify-content: space-between;
padding: 0.5rem 1rem;
cursor: pointer;

:hover {
  background-color: rgba(209, 222, 240, 0.5);
}
`;

const Add = styled.div`
color: ${()=> cssVar('--tmo-magenta')};
`;

const EmailItem = styled.div`
color: #4b4d61;
width: 100%;
`;

const EmailListInfoBar = styled.div`
padding: 0.75rem 1rem; 
border-top: 1px solid rgb(216, 216, 216);

`;


const NoData = styled.div`
display: flex;
align-items: center;
margin: 0 1rem 1rem 1rem;
text-align: left;
`;

const Dropdown = (props) => {
  const {userConfig} =props;

  const onAdd = (value) => {
    if (props.userType==='group') {
      const domainExists = (props.groupUsers).filter(v=>v?.domain===value);
      !domainExists.length && props.setGroupUsers([...props.groupUsers, {'domain': value, 'role': 'viewer'}]);
    } else if (props.userType==='individual') {
      const userExists = (props.individualUsers).filter(val=>value?.user_id===val?.user?.user_id);
      // following code is only for partnerAccount page to search and add existing user to partner so added boolean check
      const addedUserExist = props.partnerAccount ? (props.addedUsers).filter(val=>value?.user_id===val?.user?.user_id) : [];
      if (!userExists.length && !addedUserExist.length && (userConfig.user_id.toLowerCase()!==value.user_id.toLowerCase())) {
        const obj = {
          'role': 'viewer',
          'user': {
            'name': value.name,
            'role': value.role,
            'user_id': value.user_id,
            '_id': value._id,
            'test': value.test ? true : false,
            'buName': value.buName,
            'last_login': value.last_login
          }
        };
        if (props.partnerAccount) {
          delete obj.role;
          delete obj.user.role;
        }
        // const obj = {
        //   'user_id': (value._id),
        //   'user_name': value.name.split(',')[1] ? (value.name.split(',')[1]+' '+value.name.split(',')[0]).trimStart() : value.name,
        //   'role': 'viewer',
        //   'initial_role': value.role ? value.role : ''
        // };
        if (props.partnerAccount) {
          const addedIndividualUsers = [...props.addedUsers];
          if (obj) addedIndividualUsers.push(obj);
          props.setAddedUsers(addedIndividualUsers);
        } else {
          const individualUsers = [...props.individualUsers];
          const owner = individualUsers.shift();
          const individualsArray = [...individualUsers];
          if (owner) individualsArray.push(owner);
          if (obj) individualsArray.push(obj);
          props.setIndividualUsers(individualsArray);
        }
      }
    }
  };

  return (
    <div>
      <EmailsContainer autoFocus={true}>
        {props.loader ? <Loadwrap>
          <Loader type="ThreeDots" color={cssVar('--tmo-magenta')} height={40} width={40} />
        </Loadwrap> :
          <EmailList>
            {props.list && props.list.length>0 ? <>{props.list.map((v, i)=>
              <Items key={i}>
                {props.showNameAndId &&
                <>
                  <EmailItem>{v.name}</EmailItem>
                  {/* <EmailItem>{v._id}</EmailItem> */}
                </>
                }
                <EmailItem>{props.userType==='group' ? v : v.user_id}</EmailItem>
                <Add
                  style={{opacity: (props.userType==='group' && ((props.groupUsers).filter(val=>v===val?.domain)).length>=1) ? '0.5' :
                    ((props.partnerAccount && props.userType==='individual') && ((props.addedUsers).filter(val=>v?.user_id===val?.user?.user_id)).length>=1) ? '0.5' :
                      (props.userType==='individual' && ((props.individualUsers).filter(val=>v?.user_id===val?.user?.user_id)).length>=1) ? '0.5' : 
                        (props.userType==='individual' && (userConfig.user_id.toLowerCase()===v.user_id.toLowerCase())) ? '0.5':
                          '1'
                  }}
                  tabIndex={0}
                  onClick={()=>{
                    onAdd(v);
                  }}>{(props.userType==='group' && ((props.groupUsers).filter(val=>v===val?.domain)).length>=1) ? 'Added' :
                    ((props.partnerAccount && props.userType==='individual') && ((props.addedUsers).filter(val=>v?.user_id===val?.user?.user_id)).length>=1) ? 'Added' :
                      (props.userType==='individual' && ((props.individualUsers).filter(val=>v?.user_id===val?.user?.user_id)).length>=1) ? 'Added' : 
                        (props.userType==='individual' && (userConfig.user_id.toLowerCase()===v.user_id.toLowerCase())) ? 'Add':
                          '+Add'}</Add>
              </Items>
            )}
            <EmailListInfoBar>
              <Text
                fontSize={'0.875rem'}
                family={'primary'}
                weight={'500'}
                color = 'fifth'
              >
                You can’t invite users that don&apos;t match your partner&apos;s domain. Do another search or choose &apos;Add a user manually&apos; option. 
              </Text>
            </EmailListInfoBar>
            </> : 
              <NoData>
                <img style={{margin: '0 0.5rem 0 0'}} src={ErrorIcon} alt='error'/>
                <Text
                  fontSize={'0.875rem'}
                  family={'primary'}
                  weight={'500'}
                  color = 'fifth'
                >
                  User does not exist. Choose &apos;Add a new user&apos; option.
                </Text>
              </NoData>}
          </EmailList>}
      </EmailsContainer>    
    </div>
  );
};

const mapStateToProps = ({userConfig}) => ({userConfig});

export default connect(
  mapStateToProps,
)(Dropdown);
