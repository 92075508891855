import styled from 'styled-components';
import {alpha, makeStyles} from '@material-ui/core/styles';
// import {NavLink} from 'react-router-dom';
import * as theme from './../../config/theme';
// import DevPortalHeaderLogo from '../../assets/images/devportal_header_logo.svg';
import Text from '../shared/text';
import {cssVar} from '../../utils/utils';


export const StyledHeaderLogo = styled.img`
  width: 10.5rem;
  pointer-events: none;
`;

// StyledHeaderLogo.defaultProps = {
//   data: DevPortalHeaderLogo,
// };

const primaryFont = theme.default.fonts.primary;

export const StyledHeader = styled.div`
  font-family: ${primaryFont};
  transition: all 0.15s ease 0s;
  flex-flow: row nowrap;
  z-index: unset;
  //height: 5rem;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1001;
  width: 100vw;
  box-shadow: 0 0.125rem 0.25rem 0 #d3d6e1;
`;

export const StyledDivContainer = styled.div`
  ${'' /* padding: 1rem 0 0 0; */}
  height: 5rem;
`;

// export const StyledNavButton = styled(NavLink)`
//   font-family: ${primaryFont};
//   margin: 0 0 0 0.025rem;
//   padding: 2.0625rem 1.5rem 2.0625rem 1.5625rem;
//   background-color: #ffffff;
//   cursor: pointer;
//   font-size: 0.875rem;
//   font-weight: bold;
//   line-height: 1;
//   text-align: left;
//   color: #000000;
//   position: relative;
//   text-decoration: none;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-rendering: optimizeLegibility;
//   &.activeClass {
//     color:  #e20074;;
//     font-weight: bold;
//   }
//   & i {
//     color: #71758b;
//   }
//   &:hover {
//     color:  #e20074;;
//   }
//   &.disabled {
//     cursor: not-allowed;
//     opacity: 0.5;
//   }

//   // :hover {
//   //   color: #e20074;
//   // }
// `;

export const StyledHeaderContainer = styled.button`
display: flex;
align-Items: center;
outline: none;
border: none;
padding: 0;
:hover {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
`;

export const StyledNavButton = styled.div`
  font-family: ${primaryFont};
  // margin: 0 0 0 0.025rem;
  margin: 2.0625rem 1.5rem 2.0625rem 1.565rem;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: #000000;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  :hover {
    color: ${()=> cssVar('--tmo-magenta')};
  }
`;

export const StyledAPIButton = styled(StyledNavButton)`
  margin: 2.0625rem 0 2.0625rem 0;
`;

export const useStyles = makeStyles(theme => ({
  search: {
    'position': 'relative',
    'borderRadius': theme.shape.borderRadius,
    'backgroundColor': alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    'marginLeft': '0.5rem',
    'marginRight': '0.5rem',
    'width': '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 'auto',
    },
  },
  active: {
    color: `${cssVar('--tmo-magenta')} !important`
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#21232b',
    cursor: 'pointer'
  },
  ctaButton: {
    marginLeft: '2rem'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    color: '#4a4e57',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      'width': '8ch',
      '&:focus': {
        width: '20ch',
      },
      '&::placeholder': {
        fontFamily: primaryFont,
        fontWeight: 'bold',
        color: '#4a4e57',
        opacity: '0.5',
      },
    },
  },
}));

export const AllRoles = styled.div`
position: absolute;
    top: 4rem;
    background: #21232b;
    color: white;
    right: 3rem;
    z-index: 3;

ul {
  padding: 0 0.5rem 0 1.5rem;
    margin: 0.5rem 0;
}

li {
  font-size: 0.875rem;
}
`;

export const Roles = styled.div`
display: flex;
// max-width: 11rem;
align-items: center;
// flex-wrap: wrap;

span {
  font-size: 0.75rem;
    font-weight: bold;
    margin-left: 0.3rem;
    color: ${()=> cssVar('--tmo-magenta')};
    cursor: pointer;
}
`;

export const StyledTag = styled.div`
// height: 1rem;
margin: 0.2 0.2rem 0.2rem 0;
padding: 0.2rem 0.5rem;
border: solid 0 #ffffff;
color: #ffffff;
border-radius: 0.125rem;
background-color: ${()=> cssVar('--tmo-magenta')};
font-weight: bold;
font-size: 0.6875rem;
// position: absolute;
`;

export const AccountButton = styled.div`
  display: flex;
  padding: 1rem;
    // align-items: center;
    position: relative;
    cursor: pointer;
  :hover {
    background: rgba(209,222,240 ,0.3);
  }
`;

export const User = styled.div`
  margin: 0 0.5rem 0 1rem;
  max-width: 15rem;
`;

export const Name = styled(Text)`
  color: #21232b;
  font-weight: bold;
  margin-bottom: 0.1875rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

