/* eslint-disable max-len */
import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import Loader from 'react-loader-spinner';
import {Dialog, Grid, Tabs, Tab} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import _ from 'lodash';
import styled from 'styled-components';
import {getSliced} from '../shared/utils/getSliced';
import {searchUsers} from '../../api/services/users';
import Close from '../../assets/images/icon close.svg';
import Text from '../shared/text';
import Button from '../shared/button';
import themes from '../../config/theme';
import Alerts from '../utils/userAlert';
import {updateDocuments, getDomains} from '../../api/services/documents';
import {getAllBUList} from '../../api/services/businessUnits';
import {getAllUserGroups} from '../../api/services/userGroups';
import IndividualUser from './tabs/IndividualUser';
import PartnerDomains from './tabs/PartnerDomains';
import CustomGroup from './tabs/CustomGroup';
import closeIcon from '../../assets/images/globalsearch/group-3.svg';
import Arrow from '../../assets/images/grey_down_arrow.svg';
import dropdownArrowMagenta from '../../assets/images/dropdownArrowMagenta.svg';
import {Heading, CloseIconContainer, ButtonsContainer} from './utils/ManageUserStyles';
import {cssVar} from '../../utils/utils';

const {SuccessAlert, ErrorAlert, CustomErrorAlert} = Alerts;

const Avatar = styled.div`
width: ${(props)=> (props.marginLeft)? '3.5rem' : '2.5rem'};
height: 2.25rem;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
background-color: ${(props)=> (props.backgroundColor)? props.backgroundColor : '#53acf1'};
color: #fff;
margin-left: ${(props)=> (props.marginLeft)? props.marginLeft : ''};
`;

const StyledDropDown = styled.img`
margin-left: 1rem;
cursor: pointer;
visibility: ${(props) => (props.visibility)?props.visibility: 'visible'};
transform: ${(props)=> (props.transform)? props.transform : ''};
transition: all 0.2s ease-in-out;
`;

const GroupWrapper = styled.div`
border-top: none;
border-bottom: none;
border-left: ${(props)=> (props.sideBorders)?props.sideBorders: ''};
border-right: ${(props)=> (props.sideBorders)?props.sideBorders: ''};
height: ${(props)=> (props.height)?props.height: '3.25rem'};
overflow: hidden;
transition: all 0.3s ease-in-out;
`;

const User = styled.div`
position: relative;
display: flex;
align-items: center;
height: calc(3.25rem - 0.0625rem);
border-bottom: 0.0625rem solid #c7c8d1;
background-color: ${(props)=> (props.backgroundColor)? props.backgroundColor : ''};
:hover {
  background-color: #f1f1f1;
}
`;

const TabsContent = styled(Tabs)`
    border-top: 0.0625rem solid #c7c8d1;
    margin-top: 0;
    padding-top: 0.5rem;
    & .MuiTab-wrapper {
      align-items: flex-start;
    }
  `;

const useStyles = makeStyles(theme => ({
  manageUserModal: {
    width: '50rem',
    margin: 'auto',
    maxHeight: '85vh',
    zIndex: '1002',
    maxWidth: '50rem',
  },
  manageUserModalRoot: {
    '& .MuiInputBase-input': {
      'fontFamily': `${themes.fonts.primary}`,
      'fontSize': `${themes.texts.normal}`,
      'fontWeight': '500',
      'color': '#000',
      'padding': '0.625rem 3.125rem 0.625rem 0.8125rem',
    },
    '& .MuiOutlinedInput-root': {
      'borderRadius': '0',
    },
    '& .MuiInputLabel-outlined': {
      color: '#000 !important',
    },
  }, 
  modalContent: {
    padding: '1rem 2rem',
    overflow: 'hidden',
  }
}));

const tabData = [
  'ADD INDIVIDUAL USERS',
  'ADD PARTNER DOMAINS',
  'ADD CUSTOM GROUP'
];
let timeout;
let searchedData='';
const individuals = ['viewer', 'manager', 'Remove'];
const individualsRestricted = ['viewer', 'Remove'];
const tabsWidth = ['10.063rem', '10.375rem', '9rem'];

const objectSortBy = (a, b, label='name') => {
  if (a[label].toLowerCase() > b[label].toLowerCase()) {
    return 1;
  } else {
    return -1;
  }
};

function ManageUserModal(props) {
  const {onClose, open, collaborators, title, item, setUpdate, updateList, userConfig} = props;
  const [tabValue, setTabValue] = useState(0);
  const [emails, setEmails] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [buNameList, setBuNameList] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);
  const [openEmails, setOpenEmails] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [BUloader, setBULoader] = useState(false);
  const [groupUsers, setGroupUsers] = useState(collaborators ? 
    JSON.parse(JSON.stringify(collaborators.filter(v => { 
      if (v.domain || v.buName) {
        return true;
      } else {
        return false;
      }
    }))) : []);
  const [individualUsers, setIndividualUsers] = useState(collaborators ? 
    JSON.parse(JSON.stringify((collaborators.filter(v => Object.keys(v).includes('user'))).filter(v=>v.user))) : []);
  const [disable, setDisable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [groupDropdownLoader, setGroupDropdownLoader] = useState(true);
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [approvedCustomGroup, setApprovedCustomGroup] = useState(collaborators ? 
    JSON.parse(JSON.stringify(collaborators.filter(v => (v.userGroup)))) : []);
  const [selectedCustomGroup, setSelectedCustomGroup] = useState([]);
  const isPointerOnList = useRef();
  const emailInputRef = useRef();
  const childCompRef = useRef();
  const classes = useStyles();

  const tabData = [
    'ADD INDIVIDUAL USERS',
    'ADD PARTNER DOMAINS',
  ];
  if (userConfig?.role?.includes('admin')) {
    tabData.push('ADD CUSTOM GROUP');
  }

  console.log('collaborators >>', collaborators);

  const groupInitial = collaborators && collaborators.filter(v => { 
    if (v.domain || v.buName) {
      return true;
    } else {
      return false;
    }
  });
  let owner = individualUsers.filter(v=>v.role==='owner');
  if (!owner || owner.length===0) {
    const nameArr = item.author_id?.includes('@')?item.author_id?.split('@')[0]?.replace(/[0-9]/g, '')?.split('.'):item.author_id;
    if (nameArr.length>1) {
      owner = [{
        role: 'owner',
        user: {
          'name': item.author_id?.includes('@')?(nameArr[0]?.charAt(0)?.toUpperCase() + nameArr[0]?.slice(1))+ ' ' + (nameArr[1]?.charAt(0)?.toUpperCase() + nameArr[1]?.slice(1)):item.author_id,
          'user_id': item.author_id,
        }
      }];
    } else {
      owner = [{
        role: 'owner',
        user: {
          'name': item.author_id?.includes('@')?(nameArr[0]?.charAt(0)?.toUpperCase() + nameArr[0]?.slice(1)):item.author_id,
          'user_id': item.author_id,
        }
      }];
    }
  }
  const individualInitial = collaborators && [...owner, ...((collaborators.filter(v => Object.keys(v).includes('user'))).filter(v=>v.user))];

  const checkDifference = (arr1, arr2) => {
    const diff1 = _.differenceWith(arr1, arr2, _.isEqual);
    const diff2 = _.differenceWith(arr2, arr1, _.isEqual);
    if (diff1.length>0 || diff2.length>0) {
      return true;
    } else {
      return false;
    }
  };

  const domainListFetch = async () => {
    return await getDomains().then((res)=>res.data.domains.sort());
  };

  const initialUserGroup = collaborators?.filter(v => (v.userGroup));

  const buListFetch = async () => {
    setBULoader(true);
    return await getAllBUList().then((res)=> {
      setBULoader(false);
      return res.data.data.sort((a, b)=> objectSortBy(a, b, 'name'));
    }).catch(err => {
      console.log('Error', err);
      setBULoader(false);
    });
  };

  const fetchUserGroup = async () => {
    return await getAllUserGroups().then((res)=> res.data.data.sort((a, b)=> objectSortBy(a, b, 'name')));
  };

  useEffect(() => {
    let owner = individualUsers.filter(v=>v.role==='owner');
    if (!owner || owner.length===0) {
      const nameArr = item.author_id?.split('@')[0]?.replace(/[0-9]/g, '')?.split('.');
      if (nameArr.length>1) {
        owner = [{
          role: 'owner',
          user: {
            'name': item.author_id?.includes('@')?(nameArr[1]?.charAt(0)?.toUpperCase() + nameArr[1]?.slice(1))+ ', ' + (nameArr[0]?.charAt(0)?.toUpperCase() + nameArr[0]?.slice(1)):item.author_id,
            'user_id': item.author_id,
          }
        }];
      } else {
        owner = [{
          role: 'owner',
          user: {
            'name': item.author_id?.includes('@')?(nameArr[0]?.charAt(0)?.toUpperCase() + nameArr[0]?.slice(1)):item.author_id,
            'user_id': item.author_id,
          }
        }];
      }
    }
    setIndividualUsers([...owner, ...individualUsers.filter(v=>v.role!=='owner')]);

    setGroupDropdownLoader(true);
    Promise.allSettled([domainListFetch(), buListFetch(), fetchUserGroup()]).then((res) => {
      if (res) {
        if (res[0].status === 'fulfilled') {
          setDomainList(res[0].value);
        } else {
          setDomainList([]);
        }

        if (res[1].status === 'fulfilled') {
          setBuNameList(res[1].value);
        } else {
          setBuNameList([]);
        }

        if (res[2].status === 'fulfilled') {
          setUserGroupList(res[2].value);
        } else {
          setUserGroupList([]);
        }
      }
    }).catch(err=>{
      setDomainList([]);
      setBuNameList([]);
      setUserGroupList([]);
      ErrorAlert(err);
    }).finally(()=>{
      setGroupDropdownLoader(false);
    });

    return ()=>{
      searchedData='';
    };
  }, []);

  useEffect(() => {
    if ((checkDifference(individualUsers, individualInitial)) || (checkDifference(groupUsers, groupInitial)) || (checkDifference(approvedCustomGroup, initialUserGroup))) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [individualUsers, groupUsers, approvedCustomGroup]);

  const onClickSave = () => {
    const hasOwner = collaborators.filter(v=>v.role==='owner'&&v.user);
    const collabs = hasOwner.length ? individualUsers : individualUsers.filter(v=>v.role!=='owner');
    const updatedGroupUsers = [];
    groupUsers.forEach((group)=> {
      if (!group.toBeRemoved) { // to remove the overridden ( groups with only domain )
        if (group.buName && group.buName._id) {
          group.buName = group.buName._id;
        }
        updatedGroupUsers.push(group);
      }
    });
    const payload = {...item, collaborators: [...collabs, ...updatedGroupUsers, ...approvedCustomGroup]};
    setLoader(true);
    updateDocuments(payload).then((res) => {
      if (res&& res.data ) {
        setLoader(false);
        setUpdate(!updateList);
        SuccessAlert('Collaborators updated successfully');
      }
    }).catch(err=>{
      setLoader(false);
      console.log(err.response);
      ErrorAlert(err);
    }).finally(()=>{
      onClose();
    });
  };

  const onSearch = (val) => {
    if (searchedData!==val) {
      setDropdownLoader(true);
      searchUsers(val).then((res) => {
        searchedData = val;
        setEmails(typeof(res.data)==='object'?Object.values(res.data):res.data);
        setDropdownLoader(false);
      }).catch(e=>{
        setEmails([]);
        setDropdownLoader(false);
        console.log(e);
      });
    }
  };

  const timerFunction = (val) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (val.length>2) {
      timeout = setTimeout((()=>{
        onSearch(val);
        setOpenEmails(true);
      }), 500);
    } else {
      setOpenEmails(false);
    }
  };

  const clearSearch = (e) => {
    setSearchVal('');
    e.target?.previousElementSibling?.focus();
  };

  const handleRemoveGroup = (item) => {
    const filteredSelectedGroup = approvedCustomGroup.filter(g => g?.userGroup?._id !== item?.userGroup?._id);
    setApprovedCustomGroup([...filteredSelectedGroup]);
  };

  const handleAddCustomGroup = () => {
    const filteredSelectedCustomGroup = selectedCustomGroup.map(i => ({role: 'viewer', userGroup: i}));
    setApprovedCustomGroup([...filteredSelectedCustomGroup, ...approvedCustomGroup]);
    setSelectedCustomGroup([]);
    childCompRef.current.clearTextfield();
  };

  console.log('userConfig >>', userConfig);
  console.log('individuals >>', individuals);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        className={classes.manageUserModalRoot}
        PaperProps={{
          className: classes.manageUserModal
        }}
      >
        <Grid container direction='row' className={classes.modalContent}>
          <Grid item md={10} sm={10} xs={10}>
            <Heading>
              <Text color='primary' family='secondary' fontSize='font24px' margin='0 0 1rem 0'>
                Manage Users
              </Text>
              <Text className='wrapped' color='#4b4d61' fontSize='formLabel' margin='0 0 1rem 0' title={title}>
                <span style={{fontWeight: 'bold'}}>Document Name: </span> &nbsp; {title}
              </Text>
            </Heading>
          </Grid>
          <Grid item md={2} sm={2} xs={2}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <CloseIconContainer onClick={() => onClose()}>
                <img src={Close} alt='CloseIcon'/>
              </CloseIconContainer>
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TabsContent value={tabValue} onChange={(e, val)=>{
              setTabValue(val);
            }}
            TabIndicatorProps={{style: {
              background: cssVar('--tmo-magenta'), 
              color: cssVar('--tmo-magenta'), 
              height: '0.1875rem', 
              width: tabsWidth[tabValue]
            }}}
            aria-label="simple tabs example">
              {tabData.map((item, i)=>(
                <Tab style={{paddingLeft: '0', marginBottom: '-1rem', marginRight: '3rem'}}
                  key={i}
                  label={` ${item} `} />
              ))}
            </TabsContent>
            {tabValue === 0 &&
            <IndividualUser
              isPointerOnList={isPointerOnList}
              emailInputRef={emailInputRef}
              setSearchVal={setSearchVal}
              timerFunction={timerFunction}
              setOpenEmails={setOpenEmails}
              searchVal={searchVal}
              onSearch={onSearch}
              clearSearch={clearSearch}
              closeIcon={closeIcon}
              openEmails={openEmails}
              individualUsers={individualUsers}
              setIndividualUsers={setIndividualUsers}
              dropdownLoader={dropdownLoader}
              emails={emails}
              User={User}
              Avatar={Avatar}
              userConfig={userConfig}
              individuals={individuals}
              individualsRestricted={individualsRestricted}
            />}
            {tabValue === 1 &&
            <PartnerDomains
              closeIcon={closeIcon}
              Arrow={Arrow}
              groupUsers={groupUsers}
              setGroupUsers={setGroupUsers}
              groupDropdownLoader={groupDropdownLoader}
              domainList={domainList}
              buNameList={buNameList}
              getSliced={getSliced}
              Avatar={Avatar}
              StyledDropDown={StyledDropDown}
              dropdownArrowMagenta={dropdownArrowMagenta}
              User={User}
              GroupWrapper={GroupWrapper}
            />}
            {tabValue === 2 &&
            <CustomGroup
              closeIcon={closeIcon}
              handleAddCustomGroup={handleAddCustomGroup}
              approvedCustomGroup={approvedCustomGroup}
              setSelectedCustomGroup={setSelectedCustomGroup}
              userGroupList={userGroupList}
              isAddingDisabled={!selectedCustomGroup.length}
              Avatar={Avatar}
              User={User}
              handleRemoveGroup={handleRemoveGroup}
              getSliced={getSliced}
              childCompRef={childCompRef}
              onClearMultiSelect={() => {
                childCompRef.current.clearTextfield(); setSelectedCustomGroup([]);
              }}
              BUloader={BUloader}
            />}
            <ButtonsContainer>
              <Button margin='0 1rem 0 0' padding='0.8rem 2rem' bgColor='#9fa4ac' onClick={onClose} hoverBgColor='#606263'>
                Cancel
              </Button>

              <Button padding='0.8rem 2rem'
                width= '7rem'
                disabled= {disable}
                cursor={disable ? 'not-allowed' : ''}
                opacity={disable ? '0.5' : '1'}
                hoverBgColor='#ba0060'
                onClick={onClickSave}
              >
                {!loader ? <div>Save</div> :
                  <Loader type="ThreeDots" color="#fff" height={15} width={40} />}
              </Button>
            </ButtonsContainer>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

const mapStateToProps = ({userConfig}) => ({userConfig});

export default connect(
  mapStateToProps,
)(ManageUserModal);
